
import React from "react";
import {  OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from '../../cs-line-icons';

class ConditionalLink extends React.Component {

    get defaultLinkClass() {
        return "";
    }

    get defaultPlacement() {
        return "bottom";
    }

    get link() {
        return (
            <a role="link" href={this.props.to || "#"} style={ this.props.style } onClick={this.props.onClick} title={this.props.title} className={this.props.className || this.defaultLinkClass}><CsLineIcons icon={this.props.icon} /> {this.props.children}</a>
        );
    }

    get overlay() {
        return (
            <OverlayTrigger delay={{ show: 10, hide: 0 }} overlay={<Tooltip>{this.props.tooltip}</Tooltip>} placement={this.props.oveerlayPlacement || this.defaultPlacement}>
                {this.link}
            </OverlayTrigger>
        );
    }
    render() {

        {
            return !this.props.display ?
                <></>
                : this.props.tooltip ?
                    this.overlay
                    :
                    this.link
        }
    }
}

export default ConditionalLink;
