import {
  GET_FIELD_DATA,
  SET_LOADING
} from "../actions/types";

const initialState = {
  items: [],
  loading: false,
};

function fieldReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_FIELD_DATA:
      return {
        ...state,
        items: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default fieldReducer;


