import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

const DEFAULT_IMAGE = "iapp-Bill11";
const COLOR_ARRAY = [
    "#B4654A",
    "#C2C5BB",
    "#77B28C",
    "#157A6E",
    "#499F68"
];

export function exists(value) {
    return PropertyTypeData.filter(obj => { return obj.value === value });
}

export function label(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].label : "Other";
}

export function image(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].image : DEFAULT_IMAGE;
}

export function color(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].color : COLOR_ARRAY[0];
}


export const PropertyTypeData = [
    { value: "OT", label: "Other", image: DEFAULT_IMAGE, color: COLOR_ARRAY[0] },
    { value: "RE", label: "Real Estate", image: "iapp-Bank", color: COLOR_ARRAY[1] },
    { value: "BK", label: "Bank Account", image: "iapp-Museum", color: COLOR_ARRAY[2] },
    { value: "RA", label: "Retirement Account", image: "iapp-Museum", color: COLOR_ARRAY[2] },
    { value: "BU", label: "Business Interest", image: "iapp-iapp-Office-Building", color: COLOR_ARRAY[3] },
    { value: "PE", label: "Personal Property", image: "iapp-Museum", color: COLOR_ARRAY[0] },
    { value: "IP", label: "Intellectual Property", image: "iapp-Wallet11", color: COLOR_ARRAY[4] },
    { value: "VE", label: "Vehicle", image: "iapp-Fire-Truck", color: COLOR_ARRAY[0] },
    { value: "BO", label: "Boat", image: "iapp-Fire-Truck", color: COLOR_ARRAY[0] },
    { value: "AI", label: "Airplane", image: "iapp-Fire-Truck", color: COLOR_ARRAY[0] },
];

class PropertyTypeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={PropertyTypeData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={PropertyTypeData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default PropertyTypeSelect;
