import React from 'react';
import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { url } from '../../utils/api';

class DropzoneImages extends React.Component {


    getUploadParams = ({ file, meta }) => {
        return { url: `${url()}/files/upload/r/${this.props.referenceUuid}/c/${this.props.category}` };
        //return { url: `${url()}/files/upload` };
    };

    onChangeStatus = (fileWithMeta, status) => {
        if ((status === "done") && (this.props.onFileUpload)) {
            this.props.onFileUpload(fileWithMeta.file);
        }
    };


    get maxSize() {
        return 1048576 * 2;
    }

    render() {

        return (
            <Dropzone
                getUploadParams={this.getUploadParams}
                //PreviewComponent={DropzonePreview}
                submitButtonContent={null}
                accept="image/*,.pdf"
                submitButtonDisabled
                SubmitButtonComponent={null}
                inputWithFilesContent={null}
                maxSizeBytes={this.maxSize}
                onChangeStatus={this.onChangeStatus}
                classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                inputContent={this.props.label}
            ></Dropzone>
        );
    }
};

export default DropzoneImages;
