import React from "react";
import { deepEqual } from "../../../utils/object";
import Select from 'react-select';

export function exists(value) {
    return EducationData.filter(obj => { return obj.value === value });
}

export function label(value) {
    return EducationData.find(obj => { return obj.value === value })?.label;
}


export const EducationData = [
    { value: "0", label: "Kindergarten (or less)" },
    { value: "1", label: "First Grade" },
    { value: "2", label: "Second Grade" },
    { value: "3", label: "Third Grade" },
    { value: "4", label: "Fourth Grade" },
    { value: "5", label: "Fifth Grade" },
    { value: "6", label: "Sixth Grade" },
    { value: "7", label: "Seventh Grade" },
    { value: "8", label: "Eighth Grade" },
    { value: "9", label: "Ninth Grade" },
    { value: "10", label: "Tenth Grade" },
    { value: "11", label: "Eleventh Grade" },
    { value: "12", label: "Twelfth Grade" },
    { value: "13", label: "College (1 year)" },
    { value: "14", label: "College (2 years)" },
    { value: "AS", label: "Associates" },
    { value: "15", label: "College (3 years)" },
    { value: "BA", label: "Bacehlor's Degree" },
    { value: "+M", label: "Master's Degree" },
    { value: "+D", label: "MD Degree" },
    { value: "+P", label: "PhD Degree" },
    { value: "+J", label: "Juris Doctor's Degree" },
    { value: "+O", label: "Other Post-Grad Degree" },
];

class EducationSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={EducationData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={EducationData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default EducationSelect;
