import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import { Route, Switch } from "react-router-dom";

import Header from '../../layout/header';
import BreadcrumbList from "../../layout/breadcrumbs";
import pages from "../../constants/menu";
import breadcrumbs, { find } from "../../layout/breadcrumbs/calculate";
import Layout from "../../layout";
import Footer from "../../layout/footer";

import { get as getMarriage } from "../../actions/marriage";

import ProfileViewer from "../profile";
import MarriageViewer from "../marriage";
import ChildrenViewer from "../children";
import ChildrenEditor from "../children/sections/editor";
import PropertyViewer from "../property";
import PropertyEditor from "../property/sections/editor";
import DebtViewer from "../debt";
import DebtEditor from "../debt/sections/editor";
import AgreementEditor from "../agreement";

import DashboardViewer from "./sections";

class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            page: find(window.location.pathname, pages),
        }
    }

    loadData = async () => {
         setTimeout(async (e) => {
            await this.props.getMarriage(true);
        }, 30);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps) {

    }

    get title() {
        return this.props.page?.title?.trim().length ? this.props.page?.title : this.state.page.title;
    }


    render() {
        return (
            <>
                <Layout>
                    <Header title={`${this.props.profile?.dashboardTitleBase} | ${this.title}`} description={`${this.props.profile?.dashboardTitleBase} | ${this.state.page.description}`} />

                    {/* Title and Top Buttons Start */}
                    <div className="page-title-container">
                        <Row>
                            {/* Title Start */}
                            <Col md="7">
                                <h1 className="mb-0 pb-0 display-4">{this.title}</h1>
                                <BreadcrumbList items={breadcrumbs(window.location.pathname, pages)} />
                            </Col>
                            <Switch>
                                <Route exact path="/dashboard/profile/:target" component={ProfileViewer}></Route>
                                <Route exact path="/dashboard/marriage" component={MarriageViewer}></Route>
                                <Route exact path="/dashboard/children" component={ChildrenViewer}></Route>
                                <Route exact path="/dashboard/children/:subject" component={ChildrenEditor}></Route>
                                <Route exact path="/dashboard/property" component={PropertyViewer}></Route>
                                <Route exact path="/dashboard/property/:subject" component={PropertyEditor}></Route>
                                <Route exact path="/dashboard/debt" component={DebtViewer}></Route>
                                <Route exact path="/dashboard/debt/:subject" component={DebtEditor}></Route>
                                <Route exact path="/dashboard/agreement" component={AgreementEditor}></Route>

                                <Route exact path="/dashboard" component={DashboardViewer}></Route>
                            </Switch> 
                            {/* Title End */}
                            <Col md="5" className="float-right">
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </div>
                    {/* Title and Top Buttons End */}
                    <Footer/>
                </Layout>
            </>
        );
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    marriage: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        page: state.page,
        marriage: state.matter.marriage,
    });
};

export default connect(mapStateToProps, { getMarriage })(Dashboard);

