import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

export function exists(value) {
    return ValueCodeData.filter(obj => { return obj.value === value });
}

export function label(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].label : "None Available";
}

export const ValueCodeData = [
  { value: "NA", label: "None Available" },
  { value: "AG", label: "Agreed Value" },
  { value: "AP", label: "Previous Appraisal" },
  { value: "WA", label: "Future Appraisal" },
  { value: "MV", label: "Market Value" },
];



class ValueCodeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={ValueCodeData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={ValueCodeData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default ValueCodeSelect;
