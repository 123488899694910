import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Messages extends React.Component
{
    constructor(props) {
        super(props);
        //this.speakerUuid = "";
    }

    hasTail(array, index) {
        return (array.length <= index + 1) || (array[index+1].postedByUuid !== this.props.uuid);
    }

    isMine(uuid) {
        return uuid === this.props.auth.user.uuid;
    }

    render() {
        let __continue = true;
        return this.props.messages.map((element, index, array) => {
            if ((element.postedByUuid !== this.props.uuid) || !__continue) {
                __continue = false;
                return null;
            } else {
                return <p key={`chat_uuid_${element.uuid}`} className={`${this.isMine(element.postedByUuid) ? "margin-b_none " : ""}${this.hasTail(array, index) ? "" : "no-tail "}from-${this.isMine(element.postedByUuid) ? "me" : "them"}`}>{element.content}</p>
            }
        })   
    }
}

Messages.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps)(Messages);
