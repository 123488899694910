import React, { Suspense } from 'react';
import { connect } from "react-redux";
import queryString from "query-string";

import WizardPage from '../../../layout/Wizard';
import Header from '../../../layout/header';
import { withLoader } from "../../../layout/loader";

import { Toaster } from 'react-hot-toast';
import { purchase as getPurchase } from "../../../actions/cost";

import Payment from "./Payment";
import { deepEqual } from '../../../utils/object';

class Purchase extends React.Component {
    constructor(props) {
        super(props);
    }

    get purchaseUuid() {
        return this.props.match.params.purchase;
    }

    get returnUrl() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["return_url"];
    }

    get caption() {
        return "Pay Now.";
    }

    onChange = (e) =>
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });

    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.auth, prevProps.auth)) this.componentDidMount();
    }
  
    componentDidMount() {
        setTimeout(async () => {
            const purchase = await this.props.getPurchase(this.purchaseUuid);
           
            this.setState({
                ...this.state,
                purchase
            });

        }, 30);
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.name]: e.value
        })
    }

    render() {
        return (
            <Suspense fallback={withLoader()}>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title={`DivorceX | ${this.caption}`} description={`Pay for your DivorceX costs here`} profile={this.props.profile} />
                <WizardPage caption={this.caption} background={this.props.profile?.backgroundImage} profile={this.props.profile}>
                    <Payment purchase={this.state?.purchase} display onChange={this.onChange} events={{ onBack: () => { this.props.history.push(this.returnUrl || "/dashboard") } }} />
                </WizardPage>
            </Suspense>
        );
    }
}



const mapStateToProps = (state) => {
  return ({
    profile: state.licensee.profile
  });
}

export default connect(mapStateToProps, { getPurchase })(Purchase);