import api, { authenticated } from "../utils/api";
import { LOGOUT } from "./types";
import { send as sendAlert } from "./alert";

export const purchase = (purchaseUuid) => async (dispatch) => {
    try {
        const res = await authenticated.get(`/costs/support/p/${purchaseUuid}`);
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
}

export const pending = (purchaseUuid, intent) => async (dispatch) => {
    try {
        const res = await api.post(`/purchases/costs/pending/${purchaseUuid}`, {
            intent
        });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
}

export const get = (purchaseUuid) => async (dispatch) => {
    try {
        const res = await api.get(`/purchases/costs/${purchaseUuid}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}


export const confirm = (uuid, intent, email) => async (dispatch) => {
    try {
        const res = await authenticated.post(`/purchases/costs/confirm`, {
            uuid,
            intent,
            email
        });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
}
