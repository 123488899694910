import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

import ConditionalButton from "../../layout/conditional-button";
import Modal, { initial } from "../../layout/modal";
import { deepEqual } from "../../utils/object";
import Personal from "./sections/personal";
import Invite from "./invite";

import { get as getPerson, spouse as getSpouse, save as savePerson, Roles, lock, unlock } from "../../actions/person";
import { pack as setAlerts } from "../../actions/alert";
import { spouse as inviteSpouse, revoke as revokeInvitation, remind } from "../../actions/invite";
import { permitted, userPermissions } from "../../utils/security";
import { send as sendPageSetting } from "../../actions/page";

const Buttons = {
    Save: 0,
    Submit: 1,
    Send: 2,
    Unlock: 3,
    Revoke: 4,
    Resend: 5
}

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            person: {},
            modal: { ...initial }
        };
        
    }

    get target() {
        return this.props.match.params.target;
    }

    get role() {
        let __result = Roles.Self;

        switch (this.target) {
            case "spouse":
                __result = Roles.Spouse;
                break;
            case "me":
            default:
                __result = Roles.Self;
                break;
        }

        if ((__result === Roles.Spouse) && (!permitted(userPermissions(this.props.auth), ["INVITE_SPOUSE"]))) {
            this.props.history.push("/dashboard");
        }
        return __result;
    }

    get party() {
        switch (this.state.person.uuid) {
            case this.props.marriage.plaintiffUuid:
                return "plaintiff";
            case this.props.marriage.defendantUuid:
                return "defendant";
            default:
                return "unknown";
        }
    }

    componentDidMount = () => {
        setTimeout(async (e) => {
            this.loadData();
        }, 30);
    }
    
    postPersonUpdate = () => {
        this.setState({
            ...this.state,
            BirthPlace: this.state.BirthPlace ? { ...this.state.BirthPlace } : { country: "US", state: "XX" },
            haveSSN: Boolean(this.state.ssn),
        });
    }

    loadData = async (e) => {
        switch (this.role) {
            case Roles.Spouse:
                await this.props.getSpouse();
                break;
            case Roles.Self:
                await this.props.getPerson();
                break;
            default:
                break;
        }
        this.props.sendPageSetting({ context: `profile-${this.target}` });
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.self, this.props.self) && !deepEqual(this.props.self, this.state.person)  && this.role===Roles.Self) {
            this.setState({
                person: { ...this.props.self}
            }, this.postPersonUpdate);
        } else if (!deepEqual(oldProps.spouse, this.props.spouse) && !deepEqual(this.props.spouse, this.state.person) && this.role===Roles.Spouse) {
            this.setState({
                person: { ...this.props.spouse }
            }, this.postPersonUpdate);
        }
    }

    onChange = (value, errors) => {
        this.setState({
            ...this.state,
            person: {
                ...value
            },
            errors: errors
        });
    }

    onSaveClick = async (e) => {
        const result = await this.props.savePerson(this.role, this.state.person);
        if (result.success) {
            this.setState({
                person: {
                    ...result.person,
                    birthDate: typeof result.person.birthDate === 'string' ? new Date(result.person.birthDate) : result.person.birthDate,
                    haveSSN: Boolean(result.person.ssn)
                }
            })
        }
    }

    onSubmitClick = async (e) => {
        await this.props.savePerson(this.role, this.state.person);
        const result = await this.props.lock(this.role, this.state.person.uuid);
        if (result.success) {
            this.setState({
                person: {
                    ...result.person,
                    birthDate: typeof result.person.birthDate === 'string' ? new Date(result.person.birthDate) : result.person.birthDate,
                    haveSSN: Boolean(result.person.ssn)
                }
            })
        }
        this.props.history.push(`/dashboard/profile/${this.target}`);
    }

    onSendClick = async (e) => {
        const _data = await this.props.inviteSpouse(this.state.person.firstName, this.state.person.lastName, this.state.person.email);
        const { uuid, firstName, lastName, email } = _data;
        if (_data.uuid) {
            this.setState({
                person: {
                    uuid,
                    firstName,
                    lastName,
                    email
                }
            })
        }
    }

    onResendClick = async (e) => {
        const _data = await this.props.remind(this.state.person.Invited[0].uuid);
        if (_data.uuid) {
            this.setState({
                person: {
                    ...this.state.person,
                    Invited: [{ inviteDate: Date.now() }]
                }
            })
        }
    }

    onRevokeClick = async (e) => {
        const _data = await this.props.revokeInvitation(this.state.person.uuid);
        if (_data.success) {
            this.setState({
                person: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    uuid: null
                }
            });
        }
    }

    onOkUnlockModal = async (e) => {
        await this.props.unlock(this.role);
        this.props.history.push(`/dashboard/profile/${this.target}`);
    }


    onUnlockClick = async (e) => {
        const __buttons = this.state.modal.buttons;
        __buttons[0].onClick = this.onHideModal;
        __buttons[1].onClick = this.onOkUnlockModal;
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: true,
                title: "Confirm Unlock",
                body: `Confirm unlock of profile? If the attorney has already reviewed your information, this action may incur a cost equivalent to a drafting credit. Click ok to continue or cancel to return to the previous screen.`
            }
        });
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    onHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: false
            }
        })
    }

    display = (button) => {
        switch (button) {
            case Buttons.Submit:
            case Buttons.Save:
                return (!Boolean(this.props.marriage[`${this.party}LockDate`])) && this.role===Roles.Self;
            case Buttons.Unlock:
                return Boolean(this.props.marriage[`${this.party}LockDate`]);
            case Buttons.Send:
                return (this.role === Roles.Spouse) && (!this.state.person.uuid) && (!Boolean(this.props.marriage[`${this.party}LockDate`]));
            case Buttons.Resend:
            case Buttons.Revoke:
                return (this.role === Roles.Spouse) && (this.state.person.uuid) && (!Boolean(this.props.marriage[`${this.party}LockDate`]));
            default:
                return false;

        }
    }

    enable = (button) => {
        let compareDate = new Date();
        let date = compareDate.setDate(compareDate.getDate() - 1);
        if (this.state.person?.Invited && this.state.person?.Invited.length) {
            compareDate = new Date(this.state.person?.Invited[0].inviteDate) || compareDate;
        }
        
        switch (button) {
            case Buttons.Submit:
                return this.state.errors?.length === 0;
            case Buttons.Send:
                return this.state.errors?.length === 0;
            case Buttons.Resend:
                return compareDate < date;
            default:
                return false;
        }
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="5" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="I'm still working on this" display={this.display(Buttons.Save)} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Have the lawyer review" display={this.display(Buttons.Submit)} enabled={this.enable(Buttons.Submit)} onEnabledClick={this.onSubmitClick} onDisabledClick={this.onShowErrors} icon="lock-on">Submit</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" tooltip="Unlock to make additional changes" display={this.display(Buttons.Unlock)} enabled={true} icon="lock-off" onEnabledClick={this.onUnlockClick}>Unlock</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Send an Invitation to Your Spouse" display={this.display(Buttons.Send)} enabled={this.enable(Buttons.Send)} icon="send" onEnabledClick={this.onSendClick} onDisabledClick={this.onShowErrors}>Send</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Resend an Invitation to Your Spouse" display={this.display(Buttons.Resend)} enabled={this.enable(Buttons.Resend)} icon="send" onEnabledClick={this.onResendClick}>Resend</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" tooltip="Revoke this Invitation" display={this.display(Buttons.Revoke)} enabled={true} icon="close-circle" onEnabledClick={this.onRevokeClick} >Revoke</ConditionalButton>
                </Col>
                <Col md="12">
                    <Form name="frm" action="post" onSubmit={this.onSubmit} >
                        <Row>
                            <Col>
                                <Card body className="mb-5">
                                    {(this.target === "spouse" && !this.props.self.spouseUuid) ?
                                        <Invite {...this.state.person} onChange={this.onChange} locked={ this.display(Buttons.Unlock) } />
                                        :
                                        <Personal {...this.state.person} onChange={this.onChange} locked={ this.display(Buttons.Unlock) } />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                {/* Static Backdrop Start */}
                <Modal show={this.state.modal.show} title={this.state.modal.title} body={this.state.modal.body} buttons={this.state.modal.buttons} />
            </>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    self: PropTypes.object,
    spouse: PropTypes.object, 
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        self: state.matter.self,
        spouse: state.matter.spouse,
        marriage: state.matter.marriage,
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, { getPerson, getSpouse, savePerson, lock, unlock, setAlerts, inviteSpouse, revokeInvitation, remind, sendPageSetting })(Profile);
