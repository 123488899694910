import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Split from "react-split";

import { deepEqual } from "../../utils/object";
import { isGuid } from "../../utils/string";
import { get as getAgreement} from "../../actions/contract";
import { get as getMarriage } from "../../actions/marriage";
import { pack as setAlerts } from "../../actions/alert";
import { send as sendPageSetting } from "../../actions/page";
import Chat from "./chat";
import Layout from "./layout";
import '../../css/split.css';

class Agreement extends React.Component
{
    constructor(props) {
        
        super(props);
        this.state = {
            contract: {},
            case: {},
            index: -1
        }
    }

    get uuid() {
        return this.state.contract?.uuid;
    }

    get marriageUuid() {
        return this.state.case?.uuid;
    }

    loadData = async () => {
        Promise.all(
            [
                this.props.getMarriage(false, true),
                this.props.getAgreement("content")
            ]).then(async ([__data, __contract]) => {
                this.setState({
                    contract: __contract,
                    case: __data,
                    index: -1
                })
            });
        this.props.sendPageSetting({ context: `agreement-editor` });
        
    }

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    }

    componentDidMount = () => {
        this.timeout = setTimeout(() => {
            this.loadData();
        }, 30);
    }

    componentDidUpdate = (old) => {
        if (old.changes != this.props.changes) {
            this.setState({
                ...this.state,
                changes: this.props.changes
            });
        } else {
            if (!deepEqual(old, this.props)) this.componentDidMount();
        } 
    }

    onProvisionFocus = (index) => {
        this.setState({
            ...this.state,
            index
        })
    }

    onGetDisplay = (uuid) => {
        if (uuid === this.state.case.Plaintiff.uuid) {
            return `${this.state.case.Plaintiff.firstName} ${this.state.case.Plaintiff.lastName}`;
        } else if (uuid === this.state.case.Defendant.uuid) {
            return `${this.state.case.Defendant.firstName} ${this.state.case.Defendant.lastName}`;
        } else {
            return "Counsel";
        }

    }

    render() {
        if ((!isGuid(this.uuid)) || (!isGuid(this.marriageUuid))) {
            return <></>
        }
        if (this.state?.case?.editorEnabled) {
            return (
            
                <>
                    <Split className="split" sizes={[20, 80]} expandToMin >
                        <div><Chat contractUuid={this.uuid} marriageUuid={this.marriageUuid} plaintiff={this.state.case.Plaintiff} defendant={this.state.case.Defendant} index={this.state.index} enabled={this.state.index >= 100} onGetDisplay={this.onGetDisplay} /></div>
                        <Layout onFocus={this.onProvisionFocus} uuid={this.uuid} marriageUuid={this.marriageUuid} case={this.state.case} provisions={this.state?.contract?.ContractProvisions} layout={this.state?.contract?.Layout} />
                    </Split>
                </>
            )
        } else {
            return (
                <>
                    <h3 style={{marginTop: "20px", marginLeft: "20px"}}>Agreement editor has not been enabled by the firm.</h3>
                </>
            )
        }
    }
}


Agreement.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    item: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getAgreement, getMarriage, setAlerts, sendPageSetting })(Agreement);