import {
  GET_FILE,
  CLEAR_FILE,
  SET_LOADING
} from "../actions/types";

const initialState = {
  file: null,
  loading: false,
};

function fileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_FILE:
      return {
        ...state,
        file: payload,
        loading: false,
      };
    case CLEAR_FILE: 
      return {
        ...state,
        file: null,
        loading: false
      }
    default:
      return state;
  }
}

export default fileReducer;


