import {
  GET_PURCHASES,
  GET_ADDONS,
  SET_LOADING
} from "../actions/types";

const initialState = {
  purchase: {},
  addons: [],
  loading: false,
};

function purchaseReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_PURCHASES:
      return {
        ...state,
        purchase: payload,
        loading: false,
      };
    case GET_ADDONS:
      return {
        ...state,
        addons: payload
      }
    default:
      return state;
  }
}

export default purchaseReducer;
