import api from "../utils/api";
import { send as sendAlert } from "./alert";
import { GET_MARRIAGE, GET_CHANGES, GET_COSTS, LOGOUT } from "./types";

export const lock = () => async (dispatch) => {
    try {
        const res = await api.post(`/marriages/me/lock`);
        dispatch(sendAlert("Locked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const unlock = () => async (dispatch) => {
    try {
        const res = await api.post(`/marriages/me/unlock`);
        dispatch(sendAlert("Locked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const save = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/marriages/me`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (force, expand=false) => async (dispatch) => {
    try {
        const res = await api.get(`/marriages/me/f/${force ? "t" : "f"}`);

        if (expand) {
            let i = 0;
            while (i < res.data?.Properties?.length || 0) {
                if (res.data.Properties[i].meta) res.data.Properties[i].meta = JSON.parse(res.data.Properties[i].meta);
                i++;
            }
        }
        
        dispatch({
            type: GET_MARRIAGE,
            payload: res.data,
        });

        const resChanges = await api.get(`/marriages/me/changes`);
        dispatch({
            type: GET_CHANGES,
            payload: resChanges.data.changes
        });

        const resCosts = await api.get(`/marriages/me/costs`);
        dispatch({
            type: GET_COSTS,
            payload: resCosts.data
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};