import React from "react";
import { Row } from "react-bootstrap";

import Price from "./price";

class Pricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    get columns() {
        const __count = this.props.items?.length || 0;
        let __rows = Math.trunc(__count / 4);
        __rows = ((__count % 4) === 0) ? __rows : __rows + 1;
        let __per_row = Math.trunc(__count / __rows);
        __per_row = ((__count % __rows) === 0) ? __per_row : __per_row + 1;
        return {
            md: __per_row==4 ? 6 : __per_row==3 ? 6 : __per_row==2 ? 6 : 12,
            sm: __per_row==4 ? 6 : __per_row==3 ? 6 : __per_row==2 ? 6 : 12,
            lg: __per_row==4 ? 6 : __per_row==3 ? 4 : __per_row==2 ? 6 : 12,
            xl: __per_row==4 ? 3 : __per_row==3 ? 4 : __per_row==2 ? 6 : 12,
            xs: 12
        }
    }

    render() {
        const __addonType = this.props.addonType;
        if (!this.props.items || !Array.isArray(this.props.items)) return;

        return (
            <Row>
                {this.props.items.map((element, index, array) => (
                    <Price sizes={this.columns} key={`price_card_${index}`} uuid={element.uuid} addonType={__addonType} name={element.name} value={element.value} price={element.price} total={array?.length || 0} onSelect={this.props.onSelect}>{element.description}</Price>
                ))}
            </Row>
        )
    }
}

export default Pricing;
