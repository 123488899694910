import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Accordion,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import CsLineIcons from "../../cs-line-icons";
import { deepEqual } from "../../utils/object";
import { currency } from "../../utils/number";
import { discount as getDiscount } from "../../actions/product";
import { connect } from "react-redux";

class Package extends React.Component {
  constructor(props) {
    super(props);

    this.DoClickPromoCodeLink = this.DoClickPromoCodeLink.bind(this);
    this.DoSearchForCode = this.DoSearchForCode.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.DoForward = this.DoForward.bind(this);
    this.DoBack = this.DoBack.bind(this);
    this.DoChange = this.DoChange.bind(this);
    this.DoRemovePromoCode = this.DoRemovePromoCode.bind(this);


    this.state = {
      code: props.discount ? {
        ...props.discount
      } : {
        show: false,
        valid: false,
        empty: true,
        value: "",
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps?.product, this.props?.product)) {
      this.setState({
        ...this.state,
        code: {
          show: false,
          valid: false,
          empty: true,
          value: "",
        }
      });
    }

    if (!deepEqual(prevProps?.discount, this.props?.discount)) {
      this.setState({
        ...this.state,
        code: { ...this.props.discount }
      });
    }
  }

  DoForward(event) {
    if (this.props.onForward) {
      this.props.onForward({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoBack(event) {
    if (this.props.onBack) {
      this.props.onBack({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoSearchForCode(event) {
    event.preventDefault();
    this.DoCalculatePromoCode();
  }

  DoClickPromoCodeLink(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        show: true,
      },
    });
  }

  DoChange() {
    if (this.props.onChange) {
      this.props.onChange({ name: "discount", value: { ...this.state.code } });
    }
  }

  DoRemovePromoCode(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        code: {
          ...this.state.code,
          amount: 0,
          style: "S",
          issuer: "",
          value: "",
          platform: {
            amount: 0,
            style: "S",
          },
        },
      },
      this.DoChange
    );
  }

  get discount() {
    return this.state.code?.style === "P" ? Number(this.props.product.price) * Number(this.state.code.amount) * 0.01 : (this.state.code?.amount || 0);
  }

  get price() {
    return Number(this.props.product.price) - this.discount;
  }

  get platform() {
    return this.state.code?.platform?.style === "P"
      ? Number(this.props.product.fee) - (Number(this.props.product.fee) * Number(this.state.code.platform.amount) * 0.01)
      : Number(this.props.product.fee) - (this.state.code?.platform?.amount || 0);
  }

  get attorney() {
    return this.price - this.platform;
  }

  get discountCaption() {
    if (!this.state.code?.amount) return "";

    const _pre_sign = this.state.code?.style === "S" ? "$" : "";
    const _post_sign = this.state.code?.style === "P" ? "%" : "";
    return `Save ${_pre_sign} ${this.state.code?.amount} ${_post_sign} (compliments of ${this.state.code?.issuer?.name})`;
  }


  async DoCalculatePromoCode() {
    const discount = await this.props.getDiscount(this.state.code?.value, this.props.product?.Area?.name);
    
    this.setState(
      {
        ...this.state,
        code: {
          ...this.state.code,
          amount: discount?.amount,
          style: discount?.style,
          issuer: discount?.Company,
          uuid: discount?.uuid,
          object: discount,
          platform: {
            amount: discount?.platformDiscount,
            style: discount?.platformDiscountStyle,
          },
        },
      },
      this.DoChange
    );
  }

  onCodeChange(event) {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        empty: event.target.value.length <= 4,
        value: event.target.value,
      },
    });
  }

  render() {
    return (
      <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
        <Row>
          <Col>
            <h3>YOUR PACKAGE</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              {this.props.product?.name} ({this.props.product?.Area?.name})
            </h4>
          </Col>
          <Col className="text-end">
            <h4>{currency(this.price)}</h4>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={3} md={2} className="offset-md-1 text-center">
            <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: "green" }}>
              <CsLineIcons icon={"check-circle"} />
            </span>
          </Col>
          <Col xs={9} className="fs-8">Settlement Agreement Builder</Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={3} md={2} className="offset-md-1 text-center">
            <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: this.props.product?.family ? "green" : "red" }}>
              <CsLineIcons icon={this.props.product?.family ? "check-circle" : "close"} />
            </span>
          </Col>
          <Col xs={9} className="fs-8" style={{
                color: this.props.product?.family ? "inherit" : "#c9c9c9",
              }}>
              Family Modules
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={3} md={2}  className="offset-md-1 text-center">
            <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: this.props.product?.property ? "green" : "red" }}>
              <CsLineIcons icon={this.props.product?.property ? "check-circle" : "close"} />
            </span>
          </Col>
          <Col xs={9} className="fs-8" style={{
                color: this.props.product?.property ? "inherit" : "#c9c9c9",
              }}>
              Property Modules
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={3} md={2} className="offset-md-1 text-center">
            <h5>{this.props?.product?.drafting}</h5>
          </Col>
          <Col xs={9} className="fs-8">
            Attorney drafting credit(s)
          </Col>
        </Row>
        {(this.props.show?.discount) ? (
          <Row style={{ marginTop: "20px", marginBottom: "30px" }}>
            {(this.state.code.show === false) ? (
              <Col className="col-md-12" style={{ marginTop: "10px" }}>
                <Link to="#" onClick={this.DoClickPromoCodeLink}>
                  Add Promo Code
                </Link>
              </Col>
            ) : (
              <>
                <Col className="col-md-6" style={{ marginTop: "1px", whiteSpace: "nowrap" }}>
                  <FloatingLabel
                    controlId="promoCode"
                    label="Code"
                    className="mb-1"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Code"
                      maxLength="10"
                      style={{ width: "120px" }}
                      value={this.state.code?.value}
                      onChange={this.onCodeChange}
                      name="value"
                    />
                  </FloatingLabel>
                  {!(this.state.code?.amount || 0) ? (
                    <Link to="#" onClick={this.DoSearchForCode}>
                      <span style={{ position: "relative", fontSize: "24px", color: "green" }}>
                        <CsLineIcons icon={"chevron-right"} />
                      </span>
                    </Link>
                  ) : (
                    <Link to="#" onClick={this.DoRemovePromoCode}>
                      <span style={{ position: "relative", fontSize: "24px", color: "red" }}>
                        <CsLineIcons icon={"close"} />
                      </span>
                    </Link>
                  )}
                </Col>
                <Col className="col-md-6">
                  <span>{this.discountCaption}</span>
                </Col>
              </>
            )}
          </Row>
        ) : (
            ``
        )}

        <Row>
          <Col xs="12">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Price Details</Accordion.Header>
                <Accordion.Body>
                      <Row>
                        <Col xs={1}>&nbsp;</Col>
                        <Col xs={ 2 } className="text-end fs-8">
                      {currency(this.attorney)}
                    </Col>
                        <Col xs={ 9 } className="fs-8 " style={{paddingLeft: "1rem"}}>
                        Attorney's fee (paid to{" "}
                        {this.props.product?.Area?.Licensee?.Company?.name})
                    </Col>
                  </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col xs={1} className="text-end">+</Col>
                        <Col xs={2} className="text-end fs-8">
                      {currency(this.platform)}
                    </Col>
                    <Col xs={9} className="fs-8" style={{paddingLeft: "1rem"}}>
                        Platform license (paid to DivorceX99.com)
                    </Col>
                  </Row>
                      <Row style={{ marginTop: "20px", borderTop: "1px solid silver" }}>
                        <Col xs={1} className="text-end">=</Col>
                        <Col xs={ 2 } className="text-end fs-8 ml-2" style={{paddingLeft: "1rem"}}>
                      {currency(this.price)}
                    </Col>
                        <Col xs={ 9 } className="fs-8" style={{paddingLeft: "1rem"}}>Your price</Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.back ? (
              <Button variant="primary" onClick={this.DoBack}>
                Back to {this.props.labels?.back}
              </Button>
            ) : (
              ``
            )}
          </Col>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.forward ? (
              <Button variant="primary" onClick={this.DoForward}>
                Continue to {this.props.labels?.forward}
              </Button>
            ) : (
              ``
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(null, { getDiscount })(Package);