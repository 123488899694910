import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { Toaster, toast } from 'react-hot-toast';

import CsLineIcons from '../../cs-line-icons';

import LayoutFullPage from '../../layout/FullPage';
import Header from '../../layout/header';
import PasswordBar from '../../layout/password';
import { verifyToken, resetByToken } from "../../actions/auth";


class Reset extends React.Component {
//const Reset = ({ match, profile, verifyToken, resetByToken, next }) => {
    constructor(props) {
        super(props);

        this.state = {
            confirm: "",
            password: "",
            score: 0,
            feedback: "",
            uuid: null
        }
    }
    
    async componentDidMount() {
        const validToken = await this.props.verifyToken(this.token, this.email);
        if (validToken?.success) {
            this.setState({ uuid: validToken.uuid });
        } else {   
            window.location.href = "/forgot";
        }
    }

    onChange = (e) =>
        this.setState({
            [e.target.name]: e.target.value,
        });
    
    
    onSubmit = async (e) => {
        const form = e.currentTarget;
        let _valid = form.checkValidity();

        if (_valid === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            const didReset = await this.props.resetByToken(this.token, this.state.uuid, this.email, this.state.password);
            if (didReset?.success) {
                window.document.location = `/login?e=${this.email}`;
            } else {
                toast.error("Unknown error attempting to set password")
            }
        }
    };
    
    onPasswordScored = (score, feedback) =>
        this.setState({
            score,
            feedback
        });
    
    get email() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["e"];
    }

    get token() {
        return this.props.match.params.token;
    }
    
    get leftSide() {
        return (
            <div className="min-h-100 d-flex align-items-center">
                <div className="w-100 w-lg-75 w-xxl-50">
                    <div>
                        <div className="mb-5">
                            <h1 className="display-3 text-white">{this.props.profile?.tagLine001}</h1>
                        </div>
                        <p className="h6 text-white lh-1-5 mb-5">
                            {this.props.profile?.tagLine002}
                        </p>
                        <div className="mb-5">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get rightSide() {
        return (
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                <div className="sw-lg-50 px-5">
                    <div className="sh-11">
                        <NavLink to="/">
                            <div className="logo-default" style={{ backgroundImage: `url(/img/l/logo/${this.props.profile?.smallLogo})` }} />
                        </NavLink>
                    </div>
                    <div className="mb-5">
                        <h2 className="cta-1 mb-0 text-primary">Password trouble?</h2>
                        <h2 className="cta-1 text-primary">let's change it now!</h2>
                    </div>
                    <div className="mb-5">
                        <p className="h6">Please enter your new password and then confirm. You are required to use at least one upper case, one lower case, one number, and one symbol. Your password must be at least 8 characters in length.</p>
                    </div>
                    <div>
                        <form id="resetForm" className="tooltip-end-bottom" onSubmit={this.onSubmit}>
                            <div className="mb-3 filled form-group tooltip-end-top">
                                <CsLineIcons icon="lock-off" />
                                <Form.Control className="form-control"
                                    type="password"
                                    placeholder=""
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    required />
                                <PasswordBar password={this.state.password} minLength={8} score={this.state.score} upper={1} lower={1} symbol={1} number={1} onChangeScore={this.onPasswordScored} />
                            </div>
                            <div className="mb-3 filled form-group tooltip-end-top">
                                <CsLineIcons icon="lock-on" />
                                <Form.Control className="form-control"
                                    type="password"
                                    placeholder=""
                                    name="confirm"
                                    value={this.state.confirm}
                                    onChange={this.onChange}
                                    required />
                            </div>
                            <Button size="lg" type="submit" disabled={(this.state.score < 4) || (this.state.confirm !== this.state.password)}>
                                Set Password
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        return (
            <>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title='Reset Password' description='Reset the password to the site' />
                <LayoutFullPage left={this.leftSide} right={this.rightSide} background={this.props.profile?.backgroundImage} />
            </>
        );
    }
}

Reset.propTypes = {
    verifyToken: PropTypes.func.isRequired,
    resetByToken: PropTypes.func.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        profile: state.licensee.profile,
        next: state.auth.next,
    });
};

export default connect(mapStateToProps, {verifyToken, resetByToken})(Reset);
