import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BreadcrumbList = ({ items = [{ to: '/', text: 'Home' }], basePath = "" }) => {
  return (
    <Breadcrumb className="breadcrumb-container d-inline-block">
      {items.map((breadcrumb, bIndex) => (
        <Breadcrumb.Item key={`breadCrumb.${bIndex}`} linkAs={NavLink} linkProps={{ to: `${basePath}${breadcrumb.to}` }}>
          {breadcrumb.title || breadcrumb.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbList;
