import React  from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { pack as setAlerts, loading } from "../../../actions/alert";
import { get as getMarriage } from "../../../actions/marriage";
import { select as selectProvision, suggest as suggestProvision, updates as getUpdates } from "../../../actions/contract";
import { deepEqual } from "../../../utils/object";
import { isGuid } from "../../../utils/string";
import { JavaScripttoXML } from "../../../utils/serialize";
import { permitted, userPermissions } from "../../../utils/security";
import Spinner from "../../../layout/message-spinner";
import { report } from "./report";
import Provision from "./provision";
import Editor from "./editor";


class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: props.layout || {},
            provisions: props.provisions || [],
            case: props.case || {},
            html: props.html || "",
            renders: [],
            loading: true,
            showEditor: false
        }
        this.status = "Loading ...";
    }

    get maxFlags() {
        return Math.pow(2, this.flags.length);
    }

    checkForUpdates = async () => {
        if (!isGuid(this.props.uuid)) return;
        
        const lastCheck = this.lastCheck || new Date().toISOString();
        this.lastCheck = new Date().toISOString();
        const updates = await this.props.getUpdates(this.props.uuid, lastCheck);
        let i = 0;
        while ((updates) && (i < updates.length)) {
            const item = updates[i];
            item.meta = JSON.parse(item.meta);

            if (item.changeMask & 2) {
                this.state.report.chats(item.index, Boolean(item.conversation));
            }

            if (item.changeMask & 4) {
                if (isGuid(item.selectedProvisionUuid)) {
                    this.state.report.select(item.index, item.selectedProvisionUuid, item.meta.selected);
                    i++;
                    continue;
                } else {
                    this.state.report.unselect(item.index);
                }
            }

            if ((item.changeMask & 8) && (isGuid(item.plaintiffProvisionUuid))) {
                const suggester = { uuid: this.onGetParty("Plaintiff").uuid, name: this.onGetParty("Plaintiff").firstName, by: "Plaintiff" };
                this.state.report.suggest(item.index, item.plaintiffProvisionUuid, item.meta.plaintiff, suggester);
            }

            if ((item.changeMask & 16) && (isGuid(item.defendantProvisionUuid))) {
                const suggester = { uuid: this.onGetParty("Defendant").uuid, name: this.onGetParty("Defendant").firstName, by: "Defendant" };
                this.state.report.suggest(item.index, item.defendantProvisionUuid, item.meta.defendant, suggester);
            }

            if ((item.changeMask & 64) && (isGuid(item.suggestedProvisionUuid))) {
                const suggester = { uuid: this.onGetParty("Employee").uuid, name: "Counsel", by: "Counsel" };
                this.state.report.suggest(item.index, item.suggestedProvisionUuid, item.meta.suggested, suggester);
            }

            this.setState({
                ...this.state,
                provisions: [...this.state.report.provisions]
            });
            
            i++;
        }
        this.waitToUpdate();
    }

    waitToUpdate = () => {
        this.timeout = setTimeout(() => {
            this.checkForUpdates();
        }, 5000);
    }
    
    componentDidMount = () => {
        this.status = "Loading Case ...";
        this.setState({
            ...this.state,
            loading: true
        }, this.htmlXsl);
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps, this.props)) {
            this.setState({
                ...this.state,
                layout: this.props.layout || {},
                provisions: this.props.provisions || [],
                case: this.props.case || {}
            }, this.componentDidMount);
        }
    }

    onProvisionSelect = async (index, uuid, data) => {
        const meta = {
            selected: {
                ...data
            }
        };
        await this.props.selectProvision(index, uuid, { meta: JSON.stringify(meta) });
        this.state.report.select(index, uuid, meta.selected);
    }

    onProvisionSuggest = async (index, uuid, data) => {
        const found = this.state?.provisions?.find(item => item.index === index);

        const meta = (found?.meta) ? JSON.parse(found.meta) : {};
        meta.suggested = { ...data };

        const result = await this.props.suggestProvision( index, uuid, { meta: JSON.stringify(meta) });
        this.state.report.suggest(index, uuid, { meta: data }, {
            uuid: this.props.auth.user.uuid,
            name: this.props.auth.user.firstName
        });
    }

    onProvisionCreate = async (index) => {
        this.setState({
            ...this.state,
            focused: index,
            showEditor: true
        });
        if (this.props.onFocus) this.props.onFocus(index);
    }

    onEditorCancel = async () => {
        this.setState({
            ...this.state,
            showEditor: false
        })     
    }

    onEditorOk= async () => {
        this.setState({
            ...this.state,
            showEditor: false
        })     
    }

    onFocus = async (index) => {
        this.setState({
            ...this.state,
            focused: index
        });
        if (this.props.onFocus) this.props.onFocus(index);
    }

    htmlDocument = () => {
        const __report = this.state.report;
        if (!__report) return <></>;
        const __suggest = (permitted(userPermissions(this.props.auth), ["SUGGEST_CONTRACT_PROVISION"]));
        const __select = (permitted(userPermissions(this.props.auth), ["SELECT_CONTRACT_PROVISION"]));
        const __edit = (permitted(userPermissions(this.props.auth), ["EDIT_PROVISION"]));

        this.status = "Rendering ...";
        return this.state.provisions.map((provision) => {
            const element = provision.options[0];
            if (!element) return;
            //return provision.options.map((element) => {
            return (
                <Provision
                    key={`r_u_${provision.subject || "0"}_${element.uuid}`}
                    index={element.index}
                    uuid={element.uuid}
                    selected={0}
                    transformed={element.transformed}
                    html={element.html}
                    content={element.content}
                    options={provision.options.slice(1)}
                    focused={element.index === this.state.focused}
                    escalated={provision.escalated}
                    chats={provision.chats}
                    suggest={__suggest}
                    select={__select}
                    editable={__edit && element.editable}
                    suggesters={element.suggesters}
                    approval={element.approval}
                    onSelect={this.onProvisionSelect}
                    onSuggest={this.onProvisionSuggest}
                    onFocus={this.onFocus}
                    onCreate={this.onProvisionCreate}
                />
            );
            //});
        });
    }


    get docxDocument() {
        
    }

    onReportComplete = () => {
        this.setState({
            loading: false,
        })
    }

    onGetParty = (partyType) => {
        return {
            ...this.props.case[partyType]
        }
    }

    onGetPartyType = (uuid) => {
        const party = (this.props.case.Plaintiff.uuid === uuid) ? "Plaintiff" :
            (this.props.case.Defendant.uuid === uuid) ? "Defendant" :
                (this.props.case.Employee.uuid === uuid) ? "Employee" : "Unknown";
            
        return party;
    }

    onGetPartyByUuid = (uuid) => {
        return {
            ...this.props.case[this.onGetPartyType(uuid)]
        }
    }

    onSetStatus = (index, count) => {
        this.status = `Creating provision ${index} of ${count}`;
    }

    onGetSubjects = (flag) => {
        const result = [];
        if (flag.area === "K") {
            for (let i = 0; i < this.props.case.Children.length; i++) {
                result.push(this.props.case.Children[i].uuid)
            }
        } else if (flag.area === "P") {
            const __items = this.props.case.Properties.filter((item) => (item.propertyCode.startsWith(flag.code || "")));
            for (let i = 0; i < __items.length; i++) {
                result.push(__items[i].uuid)
            }
        } else if (flag.area === "D") {
            const __items = this.props.case.Debts.filter((item) => (item.debtCode.startsWith(flag.code || "")));
            for (let i = 0; i < __items.length; i++) {
                result.push(__items[i].uuid)
            }
        }
        return result;
    }

    onGetReportXml = () => {
        return JavaScripttoXML(this.props.case);
    }

    onEnabled = (flag) => {
        let result = false;
        if (flag.area === "K") {
            result |= this.props.case.Purchase.moduleMask & 2;
        } else if (["P", "D"].includes(flag.area)) {
            result |= this.props.case.Purchase.moduleMask & 1;
        }
        return result;
    }


    htmlXsl = async () => {
        if ((!this.props.layout?.LayoutProvisions)) return;

        const __report = new report({
            templates: this.props.layout.xsl,
            available: this.props.layout.LayoutProvisions,
            onComplete: this.onReportComplete,
            onGetParty: this.onGetParty,
            onStatus: this.onSetStatus,
            onGetSubjects: this.onGetSubjects,
            onGetXml: this.onGetReportXml,
            onEnabled: this.onEnabled,
            selected: this.props.provisions
        });
        __report.transform();

        this.setState({
            ...this.state,
            report: __report,
            provisions: [...__report.provisions],
            loading: true
        }, this.waitToUpdate);
    }

    onGetStatus = () => {
        return this.status;
    }

    render() {

        return (
            <><div style={
                {
                    padding: "50px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "calc(100vh - 250px)",
                    fallbacks: ["-moz-calc(100vh - 250px)",
                        "-webkit-calc(100vh - 250px)",
                        "-o-calc(100vh - 250px)"
                    ]
                }}>
                {(this.state.loading) ?
                    <Spinner onMessage={this.onGetStatus} delay={500} repeat={false} />
                    : this.htmlDocument()}
            </div>
                <Editor show={this.state.showEditor} onCancel={this.onEditorCancel} onOk={ this.onEditorOk } title="Provision Editor" initialValue="" />
            </>
        )
    }
}

Layout.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { setAlerts, loading, getMarriage, getUpdates, selectProvision, suggestProvision })(Layout);