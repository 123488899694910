function isEmail(value) {
  const email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return is(value, email);
}

function is(value, match) {
  return value.match(match);
}

function asPhone(unformatted) {
  return as(unformatted, /(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

function as(unformatted, pattern, replace) {
  return unformatted.replace(pattern, replace);
}

module.exports = { isEmail, is, as, asPhone };
