import api from "../utils/api";
import { send as sendAlert } from "./alert";
import { GET_CHILDREN, CLEAR_CHILD, GET_CHILD, LOGOUT } from "./types";

export const lock = () => async (dispatch) => {
    try {
        const res = await api.post(`/children/me/lock`);
        dispatch(sendAlert("Locked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const unlock = () => async (dispatch) => {
    try {
        const res = await api.post(`/children/me/unlock`);
        dispatch(sendAlert("Unlocked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const save = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/children/me`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = ( id ) => async (dispatch) => {
    try {
        const res = await api.get(`/children/me/i/${id}`);
        dispatch({
            type: GET_CHILD,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = ( marriageId ) => async (dispatch) => {
    try {
        const res = await api.get(`/children/me/m/${marriageId}`);
        dispatch({
            type: GET_CHILDREN,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const remove = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/children/me`, {data: { uuid: id }});
        dispatch({
            type: CLEAR_CHILD
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};