import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Accordion,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import CsLineIcons from "../../../cs-line-icons";
import { deepEqual } from "../../../utils/object";
import { currency } from "../../../utils/number";
import { connect } from "react-redux";

class Package extends React.Component {
  constructor(props) {
    super(props);

    this.DoClickPromoCodeLink = this.DoClickPromoCodeLink.bind(this);
    this.DoSearchForCode = this.DoSearchForCode.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.DoForward = this.DoForward.bind(this);
    this.DoBack = this.DoBack.bind(this);
    this.DoChange = this.DoChange.bind(this);
    this.DoRemovePromoCode = this.DoRemovePromoCode.bind(this);


    this.state = {
      code: props.discount ? {
        ...props.discount
      } : {
        show: false,
        valid: false,
        empty: true,
        value: "",
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps?.product, this.props?.product)) {
      this.setState({
        ...this.state,
        code: {
          show: false,
          valid: false,
          empty: true,
          value: "",
        }
      });
    }

    if (!deepEqual(prevProps?.discount, this.props?.discount)) {
      this.setState({
        ...this.state,
        code: { ...this.props.discount }
      });
    }
  }

  DoForward(event) {
    if (this.props.onForward) {
      this.props.onForward({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoBack(event) {
    if (this.props.onBack) {
      this.props.onBack({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoSearchForCode(event) {
    event.preventDefault();
    this.DoCalculatePromoCode();
  }

  DoClickPromoCodeLink(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        show: true,
      },
    });
  }

  DoChange() {
    if (this.props.onChange) {
      this.props.onChange({ name: "discount", value: { ...this.state.code } });
    }
  }

  DoRemovePromoCode(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        code: {
          ...this.state.code,
          amount: 0,
          style: "S",
          issuer: "",
          value: "",
          platform: {
            amount: 0,
            style: "S",
          },
        },
      },
      this.DoChange
    );
  }

  get discount() {
    return 0;
  }

  get price() {
    return this.props.product.price - this.discount;
  }

  get platform() {
    return this.props.product.fee;
  }

  get attorney() {
    return this.price - this.platform;
  }

  onCodeChange(event) {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        empty: event.target.value.length <= 4,
        value: event.target.value,
      },
    });
  }

  render() {
    return (
      <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
        <Row>
          <Col>
            <h3>YOUR ADDON</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              {this.props.product?.name} ({this.props.product?.Area?.name})
            </h4>
          </Col>
          <Col className="text-end">
            <h4>{currency(this.price)}</h4>
          </Col>
        </Row>
        {this.props.product?.family ? (
          <Row style={{ marginTop: "20px" }}>
            <Col xs={3} md={2} className="offset-md-1 text-center">
              <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: "green" }}>
                <CsLineIcons icon="check-circle" />
              </span>
            </Col>
            <Col xs={9} className="fs-8">
              <h5>Family Modules (Add)</h5>
            </Col>
          </Row>
        ) : (``)}
        {this.props.product?.property ? (
          <Row style={{ marginTop: "20px" }}>
            <Col xs={3} md={2} className="offset-md-1 text-center">
              <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: "green" }}>
                <CsLineIcons icon="check-circle" />
              </span>
            </Col>
            <Col xs={9} className="fs-8">
              <h5>Property Modules (Add)</h5>
            </Col>
          </Row>
        ) : (``)}
        {this.props.product?.drafting ? (
          <Row style={{ marginTop: "20px" }}>
            <Col xs={3} md={2} className="offset-md-1 text-center fs-8">
              {this.props?.product?.drafting}
            </Col>
            <Col xs={9} className="fs-8">
              <h5>Attorney drafting credit(s) (Add)</h5>
            </Col>
          </Row>
        ) : (``)}

        <Row>
          <Col xs="12">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Price Details</Accordion.Header>
                <Accordion.Body>
                  <Row style={{ marginTop: "20px" }}>
                      <Col xs={1}>&nbsp;</Col>
                      <Col xs={ 2 } className="text-end fs-8">
                      {currency(this.attorney)}
                    </Col>
                    <Col xs={ 9 } className="fs-8 " style={{paddingLeft: "1rem"}}>
                      Attorney's fee (paid to{" "}
                      {this.props.product?.Area?.Licensee?.Company?.name})
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={1} className="text-end">+</Col>
                        <Col xs={2} className="text-end fs-8">
                      {currency(this.platform)}
                    </Col>
                    <Col xs={9} className="fs-8" style={{paddingLeft: "1rem"}}>
                      Platform license (paid to DivorceX99.com)
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px", borderTop: "1px solid silver" }}>
                    <Col xs={1} className="text-end">=</Col>
                        <Col xs={ 2 } className="text-end fs-8 ml-2" style={{paddingLeft: "1rem"}}>
                      {currency(this.price)}
                    </Col>
                    <Col xs={ 9 } className="fs-8" style={{paddingLeft: "1rem"}}>Your price</Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.back ? (
              <Button variant="primary" onClick={this.DoBack}>
                Back to {this.props.labels?.back}
              </Button>
            ) : (
              ``
            )}
          </Col>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.forward ? (
              <Button variant="primary" onClick={this.DoForward}>
                Continue to {this.props.labels?.forward}
              </Button>
            ) : (
              ``
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Package);