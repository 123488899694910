import api from "../utils/api";
import {
  GET_FIELD_DATA, LOGOUT
} from "./types";

export const getByArea = (areaUUid) => async (dispatch) => {
    try {
        const res = await api.get(`/settings/fields/a/${areaUUid}`);

        dispatch({
            type: GET_FIELD_DATA,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};
