import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Col, Row } from 'react-bootstrap';

import { calculate } from "../../utils/validation";
import { deepEqual } from "../../utils/object";

class Invite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email
        }
    }


    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            this.setState({
                ...this.props
            });
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "INVITE", this.state));
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }

    get locked() {
        return this.props.locked;
    }


    render() {
        if (this.props.hide) return ``;
        
        return (
            <>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Spouse's Information (for the invite)</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="6">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="firstName" value={this.state.firstName || ""} onChange={this.onChange} readOnly={ this.locked } />
                            <Form.Label>FIRST NAME</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="lastName" value={this.state.lastName || ""} onChange={this.onChange} readOnly={ this.locked } />
                            <Form.Label>LAST NAME</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control type="email" name="email" value={this.state.email || ""} onChange={this.onChange} readOnly={ this.locked } />
                            <Form.Label>EMAIL ADDRESS</Form.Label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

Invite.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Invite);