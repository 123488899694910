import React from "react";
import { connect } from "react-redux";
import {  Card, Row, Col } from 'react-bootstrap';

import { toDate } from "../../utils/date";
import CsLineIcons from '../../cs-line-icons';
import { currency } from "../../utils/number";
import { image as propertyTypeImage, color as propertyTypeColor } from "../../layout/select/property";
import { label as valueCodeLabel } from "../../layout/select/value-code";

class Item extends React.Component {

    doOpen = () => {
        if (this.props.onOpen) {
            this.props.onOpen(this.props.uuid);
        }
    }

    render() {
        return (
            <Col md="4" sm="6" lg="4" xl="3" xxl="2" xs="12">
                <Card className="mb-5" role="button" onDoubleClick={this.doOpen}>
                    <Card.Body>
                        <i className={`card-img-top ${propertyTypeImage(this.props.propertyCode)}`} style={{ fontColor: propertyTypeColor(this.props.propertyCode), fontSize: "60px" }} />
                        <Card.Title style={{marginTop: "10px"}}>{this.props.name}</Card.Title>
                        <Card.Text>Purchased: {this.props.purchaseDate ? toDate(this.props.purchaseDate).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric" }) : "Unknown"}</Card.Text>
                        <Card.Text>Value: {this.props.value ? currency(Number(this.props.value)) : "Unknown"} ({ valueCodeLabel(this.props.valueCode) })</Card.Text>
                        <Card.Text>
                            <small className="text-muted">Last updated <br/>{toDate(this.props.updatedAt).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric", hour: "numeric", minute: "numeric"})}</small>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export default connect()(Item);