import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Col, Row } from "react-bootstrap";



import { deepEqual } from "../../../../utils/object";
import { calculate } from "../../../../utils/validation";
import { format as formatDate } from "../../../../utils/date";

import CountrySelect, { exists as CountryValid, label as CountryLabel } from "../../../../layout/select/country";
import CountySelect, { exists as CountyValid } from "../../../../layout/select/county";
import StateSelect, { exists as StateValid, label as StateLabel } from "../../../../layout/select/state";
import DatePicker from "../../../../layout/date-picker";
import Address from "../../../../layout/address";
import OptionCard from "../../../../layout/optioncard";

import 'react-datepicker/dist/react-datepicker.css';

class Editor extends React.Component {
    static get defaultProps() {
        return {
            Location: {
                city: "",
                county: "",
                country: "US",
                state: "XX"
            },
            marriageDate: null,
            separationDate: null,
            LastCohabitation: {
                city: "",
                county: "",
                country: "US",
                state: "VA"
            },
            together: false,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.defaultProps,
            ...this.props
        };

    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            var __separationDate = this.state.separationDate;
            var __marriageDate = this.state.marriageDate;
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (this.props.separationDate !== oldProps.separationDate) {
                __separationDate = new Date(this.props.separationDate);
            }

            if (this.props.marriageDate !== oldProps.marriageDate) {
                __marriageDate = new Date(this.props.marriageDate);
            }

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props,
                    separationDate: __separationDate,
                    marriageDate: __marriageDate
                }, this.sendOnChange);
            }

            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "MARRIAGE", this.state));
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }

    onLocationOptionChange = (e) => {
        this.setState({
            Location: {
                ...this.state.Location,
                country: e.target.value === "US" ? "US" : this.state.Location?.country !== "US" ? this.state.Location?.country : e.target.value,
                state: e.target.value !== "US" ? this.state.Location?.state : null,
            }
        }, this.sendOnChange);
    }

    onLocationChange = (e) => {
        this.setState({
            Location:
            {
                ...this.state.Location,
                [e.target.name]: e.target.value
            }
        }, this.sendOnChange);
    }

    onTogetherChange = (e) => {
        this.onChange({
            target: {
                name: "together",
                value: Number(e.target.value) === 1
            }
        });
    }

    onLocationCountyChange = (e) => {
        if (CountyValid(this.state.Location.state, e.value)) {
            this.onLocationChange({ target: { name: "county", value: e.value } });
        }
    }

    onLocationStateChange = (e) => {
        if (StateValid(e.value)) {
            this.onLocationChange({ target: { name: "state", value: e.value } });
        }
    }

    onLocationCountryChange = (e) => {
        if (CountryValid(this.state.Location.country, e.value)) {
            this.onLocationChange({ target: { name: "country", value: e.value } });
        }
    }

    onMarriageDateChange = (e) => {
        this.onChange({ target: { name: "marriageDate", value: e } });
    }

    onSeparationDateChange = (e) => {
        this.onChange({ target: { name: "separationDate", value: e } });
    }

    onLastCohabitationChange = (e) => {
        this.setState({
            ...this.state,
            LastCohabitation: {
                ...this.state.LastCohabitation,
                ...e
            }
        }, this.sendOnChange);
    
    }

    get locked() {
        return this.props.locked;
    }
    

    render() {
        return (
            <>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">We Were Married ...</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="3">
                        <OptionCard name="countryCode" value="US" checked={this.state.Location?.country === "US"} onChange={this.locked ? null : this.onLocationOptionChange} iconClassName="imaps-usa-map" iconClassColor="black" iconFontSize="48px" caption="in the United States" />
                    </Col>
                    {this.state.Location?.country === "US" ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        <Form.Control value={this.state.Location?.city || ""} type="text" name="city" onChange={this.onLocationChange} readOnly={ this.locked } />
                                        <Form.Label>CITY</Form.Label>
                                    </div>                        
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="state" value={StateLabel(this.state.Location?.state) || ""} readOnly={true} />
                                            :
                                            <StateSelect value={this.state.Location?.state || "" } onChange={this.onLocationStateChange} placeholder="" name="state" />
                                        }
                                        <Form.Label>STATE</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="county" value={this.state.Location?.county || ""} readOnly={true} />
                                            :
                                            <CountySelect value={this.state.Location?.county || "" } state={this.state.Location.state} onChange={this.onLocationCountyChange} placeholder="" name="county" />
                                        }                                        
                                        <Form.Label>COUNTY</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="marriageDate" value={formatDate(this.state.marriageDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.marriageDate} onChange={this.onMarriageDateChange} placeholder="" name="marriageDate" />
                                        }
                                        <Form.Label>DATE OF MARRIAGE</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="countryCode" value="OT" checked={this.state.Location?.country !== "US"} onChange={this.locked ? null : this.onLocationOptionChange} iconClassName="imaps-globe-earth" iconClassColor="black" iconFontSize="48px" caption="Somewhere Else" />
                    </Col>
                    {this.state.Location?.country !== "US" ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        <Form.Control value={this.state.Location?.city || ""} type="text" name="city" onChange={this.onLocationChange} readOnly={ this.locked } />
                                        <Form.Label>CITY</Form.Label>
                                    </div>                        
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="country" value={CountryLabel(this.state.Location?.country) || ""} readOnly={true} />
                                            :
                                            <CountrySelect value={this.state.Location?.country || "" } onChange={this.onLocationCountryChange} placeholder="" name="country" />
                                        }                                        
                                        <Form.Label>COUNTRY</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="marriageDate" value={formatDate(this.state.marriageDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.marriageDate} onChange={this.onMarriageDateChange} placeholder="" name="marriageDate" />
                                        }
                                        <Form.Label>DATE OF MARRIAGE</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">The Last Address Where We Lived Together</h2>
                    </Col>
                </Row>
                <Address {...this.state.LastCohabitation} onChange={ this.onLastCohabitationChange } locked={this.locked}  />
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">We are ... </h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="3">
                        <OptionCard name="together" value="0" checked={!this.state.together} onChange={this.locked ? null : this.onTogetherChange} iconClassName="iapp-Single-Male" iconClassColor="black" iconFontSize="48px" caption="Living in Separate Homes" />
                    </Col>
                    {!this.state.together ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="separationDate" value={formatDate(this.state.separationDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.separationDate} onChange={this.onSeparationDateChange} placeholder="" name="separationDate" />
                                        }
                                        <Form.Label>DATE OF SEPARATION</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="together" value="1" checked={this.state.together} onChange={this.locked ? null : this.onTogetherChange} iconClassName="iapp-Male-and-Female-1" iconClassColor="black" iconFontSize="48px" caption="Living in the Same Home (but Separated)" />
                    </Col>
                    {this.state.together ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="separationDate" value={formatDate(this.state.separationDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.separationDate} onChange={this.onSeparationDateChange} placeholder="" name="separationDate" />
                                        }
                                        <Form.Label>DATE YOU STOPPED LIVING AS A COUPLE</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                </Row>
            </>
        )
    }
}

Editor.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};


export default connect(mapStateToProps)(Editor);