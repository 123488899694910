import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

import ConditionalButton from "../../layout/conditional-button";
import Modal, { initial } from "../../layout/modal";
import { get as getMarriage, save as saveMarriage, lock, unlock } from "../../actions/marriage";
import { pack as setAlerts } from "../../actions/alert";
import { send as sendPageSetting } from "../../actions/page";
import { toDate } from "../../utils/date";
import { deepEqual } from "../../utils/object";

import Editor from "./sections/editor";



const Buttons = {
    Save: 0,
    Submit: 1,
    Unlock: 3
}

class Marriage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marriage: {},
            modal: { ...initial }
        }
    }

    postMarriageUpdate = () => {
        this.setState({
            ...this.state,
        });
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.marriage, this.props.marriage) && !deepEqual(this.props.marriage, this.state.marriage)) {
            this.setState({
                marriage: { ...this.props.marriage }
            });
        }
    }

    loadData = async (e) => {
        this.props.getMarriage(true);
        this.props.sendPageSetting({ context: `marriage-form` });
    }

    componentDidMount = async () => {
        setTimeout(async (e) => {
            this.loadData();
        }, 30);
    }

    onChange = (value, errors) => {
        this.setState({
            ...this.state,
            marriage: {
                ...value
            },
            errors: errors
        });
    }

    onSaveClick = async (e) => {
        const result = await this.props.saveMarriage(this.state.marriage);
        if (result.success) {
            this.setState({
                marriage: {
                    ...result.marriage,
                    marriageDate: toDate(result.marriage.marriageDate),
                    separationDate: toDate(result.marriage.separationDate),
                    intentDate: toDate(result.marriage.intentDate),
                    filingDate: toDate(result.marriage.filingDate),
                    grantDate: toDate(result.marriage.grantDate),
                }
            })
        }
    }

    onSubmitClick = async (e) => {
        await this.props.saveMarriage(this.state.marriage);
        const result = await this.props.lock();
        if (!result.error) {
            this.setState({
                marriage: {
                    ...result.marriage,
                    marriageDate: toDate(result.marriageDate),
                    separationDate: toDate(result.separationDate),
                    intentDate: toDate(result.intentDate),
                    filingDate: toDate(result.filingDate),
                    grantDate: toDate(result.grantDate),
                }
            });
            this.props.history.push(`/dashboard/marriage`);
        }
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    get locked() {
        return Boolean(this.state.marriage.lockDate);
    }

    onOkUnlockModal = async (e) => {
        await this.props.unlock();
        this.props.history.push(`/dashboard/marriage`);
    }

    onHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: false
            }
        })
    }

    onUnlockClick = async (e) => {
        const __buttons = this.state.modal.buttons;
        __buttons[0].onClick = this.onHideModal;
        __buttons[1].onClick = this.onOkUnlockModal;
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: true,
                title: "Confirm Unlock",
                body: `Confirm unlock of marriage information? If the attorney has already reviewed your information, this action may incur a cost equivalent to a drafting credit. Click ok to continue or cancel to return to the previous screen.`
            }
        });
    }


    display = (button) => {
        switch (button) {
            case Buttons.Submit:
            case Buttons.Save:
                return (!this.locked);
            case Buttons.Unlock:
                return this.locked;
            default:
                return false;

        }
    }

    enable = (button) => {
        switch (button) {
            case Buttons.Submit:
                return this.state.errors?.length === 0;
            default:
                return false;
        }
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="5" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="I'm still working on this" display={this.display(Buttons.Save)} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Have the lawyer review" display={this.display(Buttons.Submit)} enabled={this.enable(Buttons.Submit)} onEnabledClick={this.onSubmitClick} onDisabledClick={this.onShowErrors} icon="lock-on">Submit</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" tooltip="Unlock to make additional changes" display={this.display(Buttons.Unlock)} enabled={true} icon="lock-off" onEnabledClick={this.onUnlockClick}>Unlock</ConditionalButton>
                </Col>
                <Col md="12">
                    <Form name="frm" action="post" onSubmit={this.onSubmit} >
                        <Row>
                            <Col>
                                <Card body className="mb-5">
                                    <Editor {...this.state.marriage} onChange={this.onChange} locked={this.locked} />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Modal show={this.state.modal.show} title={this.state.modal.title} body={this.state.modal.body} buttons={this.state.modal.buttons} />
            </>
        )
    }
}

Marriage.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    marriage: PropTypes.object,
    fields: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        marriage: state.matter.marriage,
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, {setAlerts, getMarriage, saveMarriage, lock, unlock, sendPageSetting})(Marriage);