import Tooltip from "react-bootstrap/Tooltip";

const ExTooltip = (props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {props.popper?.state?.options?.message}
    </Tooltip>
  );
};

export default ExTooltip;
