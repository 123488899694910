import React from "react";
import { connect } from "react-redux";
import {  Card, Col } from 'react-bootstrap';
import { toDate } from "../../utils/date";
import CsLineIcons from '../../cs-line-icons';
import { left, right } from "../../utils/string";
import { currency } from "../../utils/number";
import { color as DebtColor, image as DebtImage } from "../../layout/select/debt";

class Item extends React.Component {

    get debtType() {
        switch (left(this.props.debtCode, 2, "OT")) {
            case "CR": {
                switch (right(this.props.debtCode, 1)) {
                    case "V":
                        return "Visa";
                    case "M":
                        return "Mastercard";
                    case "D":
                        return "Discover";
                    case "A":
                        return "American Express";
                    default:
                        return "Credit Card";
                }
            }
            case "PL":
                return "Personal Loan"
            case "MO":
                switch (right(this.props.debtCode, 1)) {
                    case "1":
                        return "First Mortgage";
                    case "2":
                        return "Second Mortgage";
                    case "E":
                        return "Home Equity";
                    default:
                        return "Mortgage";
                }
            case "VF":
                switch (right(this.props.debtCode, 1)) {
                    case "P":
                        return "Vehicle Purchase";
                    case "L":
                        return "Vehicle Lease";
                    default:
                        return "Vehicle Finance";
                }
            case "SL":
                return "Student Loan";
            default:
                return "Other";
        }        
    }

    get typeColor() {
        switch (left(this.props.debtCode, 2, "OT")) {
            case "CR":
                return "#C2C5BB";
            case "PL":
                return "#77B28C";
            case "MO":
                return "#157A6E";
            case "VF":
                return "#499F68";
            case "SL":
            case "OT":
            default:
                return "#B4654A";
        }        
    }

    get typeImage() {
        switch (left(this.props.debtCode, 2, "OT"))  {
            case "CR":
                return "iapp-Credit-card4";
            case "PL":
                return "iapp-Discount-dollar";
            case "MO":
                return "iapp-Office-Building";
            case "VF":
                return "iapp-Fire-Truck";
            case "SL":
            case "OT":
            default:
                return "iapp-Payment1";
        }
    }

    doOpen = () => {
        if (this.props.onOpen) {
            this.props.onOpen(this.props.uuid);
        }
    }

    render() {
        return (
            <Col md="4" sm="6" lg="4" xl="3" xxl="2" xs="12">
                <Card className="mb-5" role="button" onDoubleClick={this.doOpen}>
                    <Card.Body>
                        <i className={`card-img-top ${DebtImage(this.props.debtCode)}`} style={{ fontColor: DebtColor(this.props.debtCode), fontSize: "60px" }} />
                        <Card.Title style={{ marginTop: "10px" }}>{this.props.name}</Card.Title>
                        <Card.Text>Due: {this.props.value ? currency(Number(this.props.value)) : "Unknown"}</Card.Text>
                        <Card.Text>As of: {this.props.valueDate ? toDate(this.props.valueDate).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric" }) : "Unknown"}</Card.Text>
                        <Card.Text>
                            <small className="text-muted">Last updated <br/>{toDate(this.props.updatedAt).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric", hour: "numeric", minute: "numeric"})}</small>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export default connect()(Item);