import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";


import { deepEqual } from "../../../../utils/object";
import { calculate } from "../../../../utils/validation";
import { right } from "../../../../utils/string";
import { format as formatDate } from "../../../../utils/date";

import DatePicker from '../../../../layout/date-picker';
import PropertyTypeSelect, { exists as PropertyTypeExists, label as PropertyLabel } from "../../../../layout/select/property";
import ValueCodeSelect, { exists as ValueCodeExists, label as ValueLabel } from "../../../../layout/select/value-code";
import AddressEditor from "../../../../layout/address";
import AccountEditor from "../../../../layout/financial-account";
import VehicleEditor from "../../../../layout/vehicle";
import BoatEditor from "../../../../layout/boat";
import AircraftEditor from "../../../../layout/aircraft";

import 'react-datepicker/dist/react-datepicker.css';

class Editor extends React.Component {
    static get defaultProps() {
        return {
            valueCode: "NA",
            propertyCode: "OT"
        }
    }
    
    constructor(props) {
        super(props);
        this.state = {
            ...this.defaultProps,
            ...props
        };
    }

    get fieldPrefix() {
        return `PROPERTY${this.props.propertyCode}`;
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;
            var __purchaseDate = this.state.purchaseDate;
            var __valueDate = this.state.valueDate;

            if (this.props.purchaseDate !== oldProps.purchaseDate) {
                __purchaseDate = new Date(this.props.purchaseDate);
            }

            if (this.props.valueDate !== oldProps.valueDate) {
                __valueDate = new Date(this.props.valueDate);
            }

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props,
                    purchaseDate: __purchaseDate,
                    valueDate: __valueDate
                });
            }

            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange);
    }

    onPropertyCodeChange = (e) => {
        this.onChange({ target: { name: "propertyCode", value: e.value } });
    }

    onPurchaseDateChange = (date) => {
        this.onChange({ target: { name: "purchaseDate", value: date } });
    }

    onValueDateChange = (date) => {
        this.onChange({ target: { name: "valueDate", value: date } });
    }

    onPropertyCodeChange = (e) => {
        if (PropertyTypeExists(e)) {
            this.setState({ ...this.state, propertyCode: e.value }, this.sendOnChange);
        }
    }

    onValueCodeChange = (e) => {
        if (ValueCodeExists(e.value)) {
            this.setState({ ...this.state, valueCode: e.value }, this.sendOnChange);
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state, calculate(this.props.fields, this.fieldPrefix, this.state));
        }
    }

    onAddressMetaChange = (e) => {
        this.setState({
            ...this.state,
            meta: JSON.stringify(e),
            name: `${e.line1} ${e.line2 || ""} ${e.city || ""} ${e.state || ""}`
        }, this.sendOnChange);
    }

    onAccountMetaChange = (e) => {
        this.setState({
            ...this.state,
            meta: JSON.stringify(e),
            name: `${e.institution || ""} ending in ${right(e.account, 4, "XXXX") || ""}`
        }, this.sendOnChange);
    }

    onVehicleMetaChange = (e) => {
        this.setState({
            ...this.state,
            meta: JSON.stringify(e),
            name: `${e.year || ""} ${e.make || ""} ${e.model || ""} VIN ending in ${right(e.vin, 4, "XXXX") || ""}`
        }, this.sendOnChange);
    }

    onBoatMetaChange = (e) => {
        this.setState({
            ...this.state,
            meta: JSON.stringify(e),
            name: `${e.year || ""} ${e.make || ""} ${e.model || ""} registration ending in ${right(e.registration, 4, "XXXX") || ""}`
        }, this.sendOnChange);
    }

    onAircraftMetaChange = (e) => {
        this.setState({
            ...this.state,
            meta: JSON.stringify(e),
            name: `${e.year || ""} ${e.make || ""} registration # ${e.registration || ""}`
        }, this.sendOnChange);
    }

    renderAddressMeta = () => {
        var __address = JSON.parse(this.state.meta || "{}");
        return <AddressEditor {...__address} onChange={ this.onAddressMetaChange } locked={this.props.locked} />
    }

    renderAccountMeta = () => {
        var __account = JSON.parse(this.state.meta || "{}");
        return <AccountEditor {...__account} onChange={ this.onAccountMetaChange} locked={this.props.locked} />
    }

    renderVehicleMeta = () => {
        var __vehicle = JSON.parse(this.state.meta || "{}");
        return <VehicleEditor {...__vehicle} onChange={ this.onVehicleMetaChange} locked={this.props.locked} />
    }

    renderBoatMeta = () => {
        var __vehicle = JSON.parse(this.state.meta || "{}");
        return <BoatEditor {...__vehicle} onChange={ this.onBoatMetaChange} locked={this.props.locked} />
    }

    renderAircraftMeta = () => {
        var __vehicle = JSON.parse(this.state.meta || "{}");
        return <AircraftEditor {...__vehicle} onChange={this.onAircraftMetaChange} locked={this.props.locked} />
    }

    renderMetaContainer = () => {
        switch (this.state.propertyCode) {
            case "RE":
                return this.renderAddressMeta();
            case "BK":
            case "RA":
                return this.renderAccountMeta();
            case "VE":
                return this.renderVehicleMeta();
            case "BO":
                return this.renderBoatMeta();
            case "AI":
                return this.renderAircraftMeta();
            default:
                return ``;
        }
    }

    render() {
        return (
            <>
                <Row className="g-3" style={{paddingTop: "10px"}}>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="propertyCode" value={PropertyLabel(this.state.propertyCode) || ""} readOnly={true} />
                                :
                                <PropertyTypeSelect value={this.state.propertyCode || ""} onChange={this.onPropertyCodeChange} placeholder="" name="propertyCode" />
                            }
                            <Form.Label>TYPE OF PROPERTY</Form.Label>
                        </div>
                    </Col>
                </Row>
                { this.renderMetaContainer() }
                <Row className="g-3 pt-3">
                    <Col lg="8">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="name" value={this.state.name || ""} onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>DESCRIPTION</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="purchaseDate" value={formatDate(this.state.purchaseDate) || ""} readOnly={true} />
                                :
                                <DatePicker className="form-control" selected={this.state.purchaseDate} onChange={this.onPurchaseDateChange} placeholder="" name="purchaseDate" />
                            }
                            <Form.Label>DATE OF PURCHASE</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="value" value={this.state.value || ""} onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>VALUE</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="valueCode" value={ValueLabel(this.state.valueCode) || ""} readOnly={true} />
                                :
                                <ValueCodeSelect value={this.state.valueCode || ""} onChange={this.onValueCodeChange} placeholder="" name="valueCode" />
                            }                            
                            <Form.Label>VALUATION METHOD</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="valueDate" value={formatDate(this.state.valueDate) || ""} readOnly={true} />
                                :
                                <DatePicker className="form-control" selected={this.state.valueDate} onChange={this.onValueDateChange} placeholder="" name="valueDate" />
                            }                            
                            <Form.Label>DATE OF VALUATION</Form.Label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

Editor.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, { })(Editor);

