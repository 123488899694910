import api from "../utils/api";
import {
  GET_PRODUCT,
  GET_PRODUCTS,
  NO_PRODUCTS,
  NO_PRODUCT,
  PRODUCT_ERROR,
} from "./types";

export const clear = () => async (dispatch) => {
  dispatch({
    type: NO_PRODUCT,
    payload: {},
  });
};

export const list = (visible) => async (dispatch) => {
    try {
        const res = await api.get("/products/" + (visible ? "public/" : ""));

        dispatch({
            type: GET_PRODUCTS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: NO_PRODUCTS,
            payload: {
                msg: err && err.response && err.response.statusText,
                status: err && err.response && err.response.status,
            },
        });
    }
}

export const get = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/products/i/${id}`);
        dispatch({
            type: GET_PRODUCT,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: PRODUCT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status },
        });
    }
}

export const discount = (code, area) => async (dispatch) => {
    try {
        const res = await api.get(`/discounts/c/${code}/${area}`);
        return res.data;
    } catch (err) {
        return { success: false }
    }
}

export const agreements = (id, flags) => async (dispatch) => {
    try {
        const res = await api.get(`/agreements/p/${id}/f/${flags}`);
        return res.data;
    } catch (err) {
        return { success: false }
    }
}
