import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";


import { deepEqual } from "../../../../utils/object";
import { calculate } from "../../../../utils/validation";
import { left, right } from "../../../../utils/string";
import { isGuid } from "../../../../utils/string";
import { format as formatDate } from "../../../../utils/date";

import DatePicker from '../../../../layout/date-picker';
import DebtTypeSelect, { exists as DebtCodeExists, label as DebtLabel } from "../../../../layout/select/debt";
import SecuredBySelect, { label as SecuredByLabel } from "../../../../layout/select/property-list";

import OptionCard from "../../../../layout/optioncard";
import 'react-datepicker/dist/react-datepicker.css';

class Editor extends React.Component {
        static get defaultProps() {
        return {
            debtCode: "OT",
            belongsTo: "BO",
            name: ""
        }
    }
    
    constructor(props) {
        super(props);
        this.state = {
            ...this.defaultProps,
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;
            var __valueDate = this.state.valueDate;

            if (this.props.valueDate !== oldProps.valueDate)
                __valueDate = new Date(this.props.valueDate);

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props,
                    valueDate: __valueDate
                });
            }

            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange);
    }
    
    onValueDateChange = (date) => {
        this.onChange({ target: { name: "valueDate", value: date } });
    }

    onDebtCodeChange = (e) => {
        if (DebtCodeExists(e.value)) {
            this.setState({ ...this.state, debtCode: e.value }, this.sendOnChange);
        }
    }

    onSecuredByChange = (e) => {
        if (isGuid(e?.value)) {
            this.setState({ ...this.state, securedByUuid: e?.value }, this.sendOnChange);
        }
    }

    onDescriptionChange = (e) => {
        const __grantor = e.target.name === "grantor" ? e.target.value : this.state.grantor || "";
        const __account = e.target.name === "account" ? e.target.value : this.state.account || "";
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            name: `${__grantor} ending in ${right(__account, 4, "XXXX") || ""}`
        }, this.sendOnChange);
        
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state, calculate(this.props.fields, this.fieldPrefix, this.state));
        }
    }

    get fieldPrefix() {
        return `DEBT${left(this.props.debtCode, 2, "OT")}`;
    }

    get locked() {
        return this.props.locked;
    }

    render() {
        return (
            <>
                <Row className="g-3" style={{paddingTop: "10px"}}>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="debtCode" value={DebtLabel(this.state.debtCode) || ""} readOnly={true} />
                                :
                                <DebtTypeSelect value={this.state.debtCode || ""} onChange={this.onDebtCodeChange} placeholder="" name="debtCode" />
                            }                            
                            <Form.Label>TYPE OF DEBT</Form.Label>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="grantor" value={this.state.grantor || ""} onChange={this.onDescriptionChange} readOnly={this.locked} />
                            <Form.Label>CREDIT GRANTOR</Form.Label>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="account" value={this.state.account || ""} onChange={this.onDescriptionChange} readOnly={this.locked} />
                            <Form.Label>ACCOUNT #</Form.Label>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="name" value={this.state.name || ""} onChange={this.onChange} readOnly={this.locked} />
                            <Form.Label>NAME</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="value" value={this.state.value || ""} onChange={this.onChange} readOnly={this.locked} />
                            <Form.Label>AMOUNT DUE</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="valueDate" value={formatDate(this.state.valueDate) || ""} readOnly={true} />
                                :
                                <DatePicker className="form-control" selected={this.state.valueDate} onChange={this.onValueDateChange} placeholder="" name="valueDate" />
                            }                            
                            <Form.Label>DATE OF VALUATION</Form.Label>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="securedByUuid" value={SecuredByLabel(this.props.properties, this.state.securedByUuid) || ""} readOnly={true} />
                                :
                                <SecuredBySelect value={this.state.securedByUuid || ""} onChange={this.onSecuredByChange} properties={this.props.properties} placeholder="" name="securedByUuid" />
                            }                            
                            <Form.Label>SECURED BY (PROPERTY) (backspace to remove)</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Whose name is attached to this debt?</h2>
                    </Col>
                </Row>
                <Row className="g-3 mb-3">
                    <Col xs="2">
                        <OptionCard name="belongsTo" value="PL" checked={this.state.belongsTo === "PL"} onChange={this.locked ? null : this.onChange} iconClassName="iapp-Single-Male" iconClassColor="black" iconFontSize="48px" caption={ `${this.props.marriage?.Plaintiff?.firstName} ${this.props.marriage?.Plaintiff?.lastName} ` } />
                    </Col>
                    <Col xs="2">
                        <OptionCard name="belongsTo" value="DE" checked={this.state.belongsTo === "DE"} onChange={this.locked ? null : this.onChange} iconClassName="iapp-Single-Male" iconClassColor="black" iconFontSize="48px" caption={ `${this.props.marriage?.Defendant?.firstName} ${this.props.marriage?.Defendant?.lastName} ` } />
                    </Col>
                    <Col xs="2">
                        <OptionCard name="belongsTo" value="BO" checked={this.state.belongsTo === "BO"} onChange={this.locked ? null : this.onChange} iconClassName="iapp-Two-Males" iconClassColor="black" iconFontSize="48px" caption="Both of Us" />
                    </Col>
                </Row>
            </>
        )
    }
}

Editor.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, { })(Editor);

