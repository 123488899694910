import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ConditionalLink from "../../../layout/conditional-link";
import { deepEqual } from "../../../utils/object";

class Provision extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected || 0,
            html: props.html || "<></>",
            transformed: props.transformed || "... calculating ...",
            uuid: props.uuid,
            suggesters: props.suggesters
        }
    }

    componentDidUpdate = (oldProps) => {
        if ((oldProps.transformed !== this.props.transformed) || (oldProps.html !== this.props.html) || (!deepEqual(oldProps.suggesters, this.props.suggesters))) {
            this.setState({
                ...this.state,
                html: this.props.html || "<></>",
                transformed: this.props.transformed || "... calculating ...",
                uuid: this.props.uuid,
                suggesters: this.props.suggesters
            })
        }
    }

    componentDidMount = () => {
    }

    readFormValues = () => {
        const __elements = document.getElementsByClassName("embeddedControl");
        const result = {};
        for (let i = 0; i < __elements.length; i++) {
            const element = __elements[i];
            result[element.name] = element.value;
        }
        return result;
    }

    selectedObject = (index) => {
        if (index === 0) {
            return { ...this.props };
        } else {
            return { ...this.props.options[index - 1] };
        }
    }

    setScrollTo = (index) => {
        const __selected = this.selectedObject(index);

        this.setState({
            ...this.state,
            selected: index,
            transformed: __selected.transformed,
            html: __selected.html,
            uuid: __selected.uuid,
            suggesters: __selected.suggesters
        });
    }

    onScrollRight = () => {
        this.onFocus();
        this.setScrollTo(this.state.selected + 1);
        if (this.props.onScrollRight) this.props.onScrollRight({ ...this.props });
    }

    onScrollLeft = () => {
        this.onFocus();
        this.setScrollTo(this.state.selected - 1);
        if (this.props.onScrollLeft) this.props.onScrollLeft({ ...this.props });
    }

    onFocus = () => {
        if (this.props.onFocus) this.props.onFocus(this.props.index);
    }

    onCreateProvision = () => {
        if (this.props.onCreate) this.props.onCreate(this.props.index);
    }

    get displayToolbar() {
        return (this.props.select || this.props.suggest) && (this.props.approval > 0);
    }

    doSuggest = () => {
        if (this.props.onSuggest) this.props.onSuggest(this.props.index, this.state.uuid, this.readFormValues());
    }

    doAdopt = () => {
        if (this.props.onSuggest) this.props.onSuggest(this.props.index, this.state.uuid, this.props.meta);
    }

    doSelect = () => {
        if (this.props.onSelect) this.props.onSelect(this.props.index, this.state.uuid, this.readFormValues());
    }

    canSelect = () => {
        return this.props.select==="true";
    }

    get suggesters() {
        let result = "";
        for (let i = 0; i < this.state.suggesters.length; i++) {
            const element = this.state.suggesters[i];
            result += element.name + ((i < this.state.suggesters.length - 1) ? ", " : "")
        }
        return result;
    }

    get adoptable() {
        let result = true;
        for (let i = 0; i < this.state.suggesters.length; i++) {
            const element = this.state.suggesters[i];
            result &= element.uuid !== this.props.auth.user.uuid;
            if (!result) break;
        }
        return result;
    }

    get style() {
        return this.props.focused ? { borderLeft: "3px var(--secondary) dotted" } : this.props.escalated ? { borderLeft: "3px #dc3545 dotted" } : {};
    }
    
    render() {
        return (
            <Row className="align-items-center" style={this.style}>
                
                {(this.displayToolbar) ? (
                    <Col xs="12">
                    
                        <div style={{ marginTop: "30px", height: "24px", backgroundColor: "white" }}>
                            {(this.props.chats) ? (
                            <ConditionalLink onClick={this.onFocus} display title="There are chats about this ..." icon="mic" style={{ marginRight: "10px" }}></ConditionalLink>
                            ) : ``}
                            <ConditionalLink onClick={this.doSelect} display={ this.canSelect() } title="select this provision (locks this selection)" icon="flash" style={{ marginRight: "10px" }}> select</ConditionalLink>
                            <ConditionalLink onClick={this.doSuggest} display={!this.state.suggesters?.length} title="suggest this provision" icon="light-off" > suggest</ConditionalLink>
                            {(this.state.suggesters?.length) ? (
                                <span>Suggested by: {this.suggesters}</span>
                            ) : ``}
                            <ConditionalLink style={{ marginLeft: "10px" }} onClick={this.doAdopt} display={this.state.suggesters?.length && this.adoptable} title="adopt this provision" icon="check-square" > adopt</ConditionalLink>
                            {(this.props.escalated) ? (
                                <span style={{ marginLeft: "10px" }}>Drafting</span>
                            ) : ``}
                        </div>
                    
                    </Col>
                ) : ``}
                <Col xs="1">
                    <ConditionalLink onClick={this.onScrollLeft} icon="chevron-left" display={(this.state.selected !== 0)} />
                </Col>
                <Col xs="10" onClick={this.onFocus}>
                    <div key={`provision_${this.state.uuid}`} style={{ paddingTop: "15px" }} className="provisionText" dangerouslySetInnerHTML={{ __html: this.state.transformed }} />
                </Col>
                <Col xs="1">
                    <ConditionalLink onClick={this.onScrollRight} icon="chevron-right" display={((this.props.options?.length > 0) && ((this.state.selected) < this.props.options.length))} />
                    <ConditionalLink onClick={this.onCreateProvision} icon="plus" display={((this.state.selected) === this.props.options.length) && this.props.editable} />
                </Col>
                
            </Row>
        )
    }
}

Provision.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps)(Provision);