import React from "react";

class OptionCard extends React.Component {
    render() {
        return (
            <label className="form-check custom-card w-100 position-relative p-0 m-0">
                <input type="radio" className="form-check-input position-absolute e-2 t-2 z-index-1" name={this.props.name} value={this.props.value} checked={this.props.checked} onChange={this.props.onChange} />
                <span className="card form-check-label w-100">
                    <span className="card-body text-center">
                        <i className={ this.props.iconClassName } style={{ color: this.props.iconClassColor, fontSize: this.props.iconFontSize }} />
                        <span className="heading mt-3 text-body text-primary d-block">{ this.props.caption }</span>
                    </span>
                </span>
            </label>
            
        )
    }
}

export default OptionCard;