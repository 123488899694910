import React from "react";
import { Row, Col, Form, FloatingLabel, OverlayTrigger } from "react-bootstrap";
import Tooltip from "../../layout/tooltip";
import StateSelect, { exists as StateValid, label as StateLabel } from "../../layout/select/state";
import Masked from "../../layout/masked-input";
import CsLineIcons from "../../cs-line-icons";

import './Style.css';

const Package = React.lazy(() => import("./Package"));

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {
            for: {
                firstName: "",
                lastName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "VA",
                zipCode: "",
                email: "",
                target: "S",
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userPhone: "",
                ...props?.for
            }
        };
        this.validated = false;
        this.formRef = React.createRef();
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            for: {
                ...this.state.for,
                [e.target.name]: e.target.value,
            },
        }, this.handleOnChange);
    }

    handleOnChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange({ name: "for", value: {...this.state.for }})
        }
    }
    
    handleDiscountChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        let _valid = form.checkValidity();
        form.reportValidity();
        
        this.setState({
            ...this.state,
            ready: _valid,
        }, this.handleOnChange);

        this.validated = true;

        if (_valid === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (this.props.onForward) this.props.onForward(this.state);
        }
    }

    onForward = () => {
        this.formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
        return this.validated;
    }
    
    changeTarget = (e) => {
        this.setState({
            ...this.state,
            for: {
                ...this.state.for,
                target: e.target.value,
            }
        });
    }

    onStateChange = (e) => {
        if (StateValid(e.value)) {
            this.onChange({ target: { name: "state", value: e.value } });
        }
    }


    render() {
        if (!this.props?.display) return <></>;

        return (
            <div
                style={{
                    backgroundColor: "#f0f0f0",
                    width: "100%",
                    position: "relative",
                    paddingTop: "20px",
                    paddingLeft: "1rem",
                    display: "flex",
                    WebkitBoxAlign: "center",
                    minHeight: "10rem",
                }}
            >
                <Row
                    className="justify-content-center"
                    style={{ width: "100%", padding: "0.5rem" }}
                >
                    <Col sm={12} md={6}>
                        <div
                            style={{
                                padding: "32px",
                                background: "#fff",
                                margin: "0px",
                                borderRadius: "8px",
                                animation: "transition-in .25s ease-out forwards",
                            }}
                        >
                            <Form
                                noValidate
                                validated={this.validated}
                                className="form-floating"
                                onSubmit={this.handleSubmit}
                                ref={this.formRef}
                            >
                                <Row>
                                    <Col xs={12} style={{marginBottom: "15px"}}>
                                        <span>I am purchasing this for
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={Tooltip}
                                                popperConfig={{
                                                    message:
                                                        "Select if you are buying this access for yourself or for someone else.",
                                                }}
                                            >
                                                <span style={{color: "silver", fontSize: "10px", position: "relative", top:"-15px"}}><CsLineIcons icon="light-on" /></span>
                                            </OverlayTrigger></span>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="rdoMyself"
                                        >
                                            <Form.Check.Input type="radio" isValid name="whoFor" value="S" onChange={this.changeTarget} checked={this.state?.for?.target === "S"}/>
                                            <Form.Check.Label style={{ display: "contents" }}>Myself</Form.Check.Label>
                                        </Form.Check>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="rdoAnother"
                                        >
                                            <Form.Check.Input name="whoFor" type="radio" isValid style={{ paddingRight: "5px", paddingLeft: "5px" }} value="O" checked={this.state?.for?.target === "O"} onChange={this.changeTarget}/>
                                            <Form.Check.Label style={{ display: "contents" }}>Someone else</Form.Check.Label>
                                        </Form.Check>
                                    
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        marginTop: "20px",
                                        borderTop: "1px solid #c9c9c9",
                                        marginBottom: "20px",
                                    }}
                                ></div>
                                <div>
                                    <Row style={{ marginBottom: "20px" }}>
                                        <Col md={12}>
                                            Purchaser's Information{` `}
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={Tooltip}
                                                popperConfig={{
                                                    message:
                                                        "Provide the information of the person making the purchase.",
                                                }}
                                            >
                                                <span style={{color: "silver", fontSize: "10px", position: "relative", top:"-15px"}}><CsLineIcons icon="light-on" /></span>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FloatingLabel
                                                controlId="firstName"
                                                label="First Name"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    onChange={this.onChange}
                                                    value={this.state.for?.firstName}
                                                    name="firstName"
                                                    required
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel
                                                controlId="lastName"
                                                label="Last Name"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    value={this.state.for?.lastName}
                                                    onChange={this.onChange}
                                                    name="lastName"
                                                    required
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FloatingLabel
                                                controlId="addressLine1"
                                                label="Address (Line 1)"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    placeholder="Address (Line 1)"
                                                    value={this.state.for?.addressLine1}
                                                    onChange={this.onChange}
                                                    name="addressLine1"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FloatingLabel
                                                controlId="addressLine2"
                                                label="Address (Line 2)"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Address (Line 2)"
                                                    value={this.state.for?.addressLine2}
                                                    onChange={this.onChange}
                                                    name="addressLine2"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FloatingLabel
                                                controlId="city"
                                                label="City"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    placeholder="City"
                                                    value={this.state.for?.city}
                                                    onChange={this.onChange}
                                                    name="city"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={3}>
                                            <FloatingLabel
                                                controlId="state"
                                                label=""
                                                className="mb-3"
                                            >
                                                <StateSelect
                                                    onChange={this.onStateChange}
                                                    defaultValue={this.state.for?.state}
                                                    abbreviated="true"
                                                    className=""
                                                    styles={{ height: "52px" }}
                                                    name="state"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={3}>
                                            <FloatingLabel controlId="zipCode" label="Zip Code">
                                                <Masked
                                                    type="text"
                                                    required
                                                    placeholder="Zip Code"
                                                    mask="11111"
                                                    pattern="^[0-9]{5}$"
                                                    value={this.state.for?.zipCode}
                                                    onChange={this.onChange}
                                                    name="zipCode"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FloatingLabel
                                                controlId="email"
                                                label="Email Address"
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="email"
                                                    required
                                                    placeholder="john@doe.com"
                                                    value={this.state.for?.email}
                                                    onChange={this.onChange}
                                                    name="email"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FloatingLabel
                                                controlId="phone"
                                                label="Phone #"
                                                className="mb-3"
                                            >
                                                <Masked
                                                    type="text"
                                                    required
                                                    placeholder="(555)555-1212"
                                                    mask="(111)111-1111"
                                                    pattern="^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$"
                                                    value={this.state.for?.phone}
                                                    onChange={this.onChange}
                                                    name="phone"
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </div>
                                {this.state?.target === "O" ? (
                                    <>
                                        <div
                                            style={{
                                                marginTop: "20px",
                                                borderTop: "1px solid #c9c9c9",
                                                marginBottom: "20px",
                                            }}
                                        ></div>
                                        <div>
                                            <Row style={{ marginBottom: "20px" }}>
                                                <Col md={12}>
                                                    User's Information{` `}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={Tooltip}
                                                        popperConfig={{
                                                            message:
                                                                "Provide the information of the person who will be using this software.",
                                                        }}
                                                    >
                                                        <span style={{color: "silver", fontSize: "10px", position: "relative", top:"-15px"}}><CsLineIcons icon="light-on" /></span>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FloatingLabel
                                                        controlId="userFirstName"
                                                        label="First Name"
                                                        className="mb-3"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            required={this.state?.target === "O"}
                                                            placeholder="First Name"
                                                            value={this.state.for?.userFirstName}
                                                            onChange={this.onChange}
                                                            name="userFirstName"
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel
                                                        controlId="userLastName"
                                                        label="Last Name"
                                                        className="mb-3"
                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            required={this.state?.target === "O"}
                                                            placeholder="Last Name"
                                                            value={this.state.for?.userLastName}
                                                            onChange={this.onChange}
                                                            name="userLastName"
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FloatingLabel
                                                        controlId="userEmail"
                                                        label="Email Address"
                                                        className="mb-3"
                                                    >
                                                        <Form.Control
                                                            type="email"
                                                            required={this.state?.target === "O"}
                                                            placeholder="Email Address"
                                                            value={this.state.for?.userEmail}
                                                            onChange={this.onChange}
                                                            name="userEmail"
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FloatingLabel
                                                        controlId="userPhone"
                                                        label="Phone #"
                                                        className="mb-3"
                                                    >
                                                        <Masked
                                                            type="text"
                                                            required={this.state?.target === "O"}
                                                            placeholder="(555)555-1212"
                                                            mask="(111)111-1111"
                                                            pattern="^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$"
                                                            value={this.state.for?.userPhone}
                                                            onChange={this.onChange}
                                                            name="userPhone"
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Form>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Package
                            show={{ forward: true, back: false, discount: true }}
                            labels={{ forward: "Agreements" }}
                            discount={this.props?.discount}
                            product={this.props.product}
                            onChange={this.handleDiscountChange}
                            onForward={this.onForward}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Step;
