import React from "react";
import { Button, Col, Card } from "react-bootstrap";
import CsLineIcons from "../../cs-line-icons";
import { currency } from "../../utils/number";

class Price extends React.Component {
    get addonTypeImage() {
        switch (this.props.addonType) {
            case 1:
                return "pen";
            case 2:
                return "building";
            case 4:
            default:
                return "graduation";
        }
    }

    doSelect = (e) => {
        if (this.props.onSelect) this.props.onSelect(e, this.props.uuid);
    }

    render() {
        return (
            <Col {...this.props.sizes}>
                <Card className="mb-5 h-100 hover-scale-up">
                    <Card.Body className="pb-0 justify-content-center">
                        <div className="d-flex flex-column align-items-center mb-4">
                            <div className="bg-gradient-light sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center mb-2">
                                <CsLineIcons icon={this.addonTypeImage} className="text-white" />
                            </div>
                            <div className="cta-4 text-primary mb-1">{this.props.name}</div>
                            <div className="text-muted sh-3 line-through">{currency(Number(this.props.value))}</div>
                            <div className="display-4">{currency(Number(this.props.price))}</div>
                        </div>
                        <p className="text-alternate mb-4">{this.props.children}</p>
                    </Card.Body>
                    <Card.Footer className="pt-0 border-0">
                        <div className="d-flex justify-content-center">
                            <Button variant="foreground" className="btn-icon btn-icon-start hover-outline stretched-link" onClick={ this.doSelect }>
                                <CsLineIcons icon="chevron-right" /> <span>Purchase</span>
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        )
    }
}

export default Price;