import api from "../utils/api";
import { CLEAR_SPOUSE } from "./types";
import { JavaScripttoXML } from "../utils/serialize";
import { send as setAlert } from "./alert";
import { build, send } from "../utils/email";

export const revoke = (id) => async (dispatch) => {
    try {
        let __response;
        __response = await api.post("/invites/me/revoke", { inviteeUuid: id });
        if (__response.data.success) {
            dispatch({
                type: CLEAR_SPOUSE,
                payload: {},
            });
        } else {
            dispatch(setAlert(__response.data.error, "error"));
        }
        return __response.data;
    } catch (err) {
        const errors = err.response?.data?.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
    }
}

export const remind = (uuid) => async (dispatch) => {
    let __response;
    try {
        __response = await api.put("/invites/me", { uuid, inviteDate: Date.now() });
        let __email = await build(JavaScripttoXML(__response.data), "SPOUSE.INVITE", __response.data.licenseeUuid);
        let __send = await send(__email.from, __response.data.email, __email.subject, __email.html);
        __response = {
            ...__response.data,
            Send: __send
        }
        return __response;       
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
    }
}

export const spouse = (firstName, lastName, email) => async (dispatch) => {
    let __response;
    try {
        __response = await api.post("/invites/me", { firstName, lastName, email, inviteType: "SP", distinct: true });
        let __email = await build(JavaScripttoXML(__response.data), "SPOUSE.INVITE", __response.data.licenseeUuid);
        let __send = await send(__email.from, __response.data.email, __email.subject, __email.html);
        __response = {
            ...__response.data,
            Send: __send
        }
        return __response;       
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
    }
}

export const get = (inviteType) => async (dispatch) => {
    const results = await api.get("/invites/me");
    const __data = results.data.filter((item) => {
        return item.inviteType === inviteType ? item : null;
    });
    return __data;
} 


