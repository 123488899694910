import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row, Card, Image } from "react-bootstrap";
import { PatternFormat as NumberFormat } from 'react-number-format';
import PropTypes from "prop-types";

import { deepEqual } from "../../../../utils/object";
import { between, wordsOrdinal } from "../../../../utils/number";
import { calculate } from "../../../../utils/validation";
import { format as formatDate } from "../../../../utils/date";

import GenerationsSelect from "../../../../layout/select/generation";
import CountrySelect, { exists as CountryExists, label as CountryLabel } from "../../../../layout/select/country";
import StateSelect, { exists as StateValid, label as StateLabel } from "../../../../layout/select/state";
import RaceSelect, { exists as RaceValid, label as RaceLabel } from "../../../../layout/select/race";
import EducationSelect, { exists as EducationValid, label as EducationLabel } from "../../../../layout/select/education";
import NumberSelect from "../../../../layout/select/numeric";
import Address from "../../../../layout/address";
import Dropzone from "../../../../layout/dropzone";
import OptionCard from "../../../../layout/optioncard";
import DatePicker from '../../../../layout/date-picker';

import { get as getFile, remove as removeFile } from "../../../../actions/file";

import 'react-datepicker/dist/react-datepicker.css';

class Personal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            generation: props.generation,
            BirthPlace: {
                country: "US",
                state: "XX"
            },
            haveSSN: Boolean(props.ssn),
            raceCode: "UN",
            marriageCount: 1,
            genderCode: "O",
            pregnant: "0"
        };
        this.afterUpdate();
    }

    afterUpdate = async () => {
        await this.props.getFile({ category: "Identification", index: 0 });
    }

    updateUrl = () => {
        this.setState({
            ...this.state,
            url: URL.createObjectURL(this.state.blob)
        });
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;
            delete __old_minus_fields.file;
            delete __props_minus_fields.file;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                }, this.sendOnChange);
            } 
            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
            if (this.props.file !== oldProps.file) 
            {
                this.setState({
                    ...this.state,
                   blob: new Blob([this.props.file], { type: 'image/png' }),
                }, this.updateUrl);
            }
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }

    onGenerationChange = (e) => {
        this.onChange({ target: { name: "generation", value: e.value } });
    }

    onBirthplaceChange = (e) => {
        this.setState({
            ...this.state,
            BirthPlace: {
                ...this.state.BirthPlace,
                country: e.target.value === "US" ? "US" : this.state.BirthPlace?.country !== "US" ? this.state.BirthPlace?.country : e.target.value,
                state: e.target.value !== "US" ? this.state.BirthPlace?.state : null,
            }
        }, this.sendOnChange);
    }

    onBirthDateChange = (date) => {
        this.onChange({ target: { name: "birthDate", value: date } });
    }

    onAddressChange = (e) => {
        this.setState({
            ...this.state,
            Residence: {
                ...this.state.Residence,
                ...e
            }
        }, this.sendOnChange);
    }

    onPhoneChange = (e) => {

        this.setState({
            ...this.state,
            Phone: {
                ...this.state.Phone,
                number: e.target.value
            }
        }, this.sendOnChange)
    }

    onMarriageCountChange = (e) => {
        if (between(20, 1, Number(e.value))) this.onChange({ target: { name: "marriageCount", value: e.value } });
    }
    
    onEducationLevelChange = (e) => {
        if (EducationValid(e.value)) {
            this.onChange({ target: { name: "educationLevel", value: e.value } });
        }
    }

    onRaceCodeChange = (e) => {
        if (RaceValid(e.value)) {
            this.onChange({ target: { name: "raceCode", value: e.value } });
        }
    }

    onBirthplaceCountryChange = (e) => {
        if (CountryExists(e.value)) {
            this.setState({ ...this.state, BirthPlace: {...this.state.BirthPlace, country: e.value } }, this.sendOnChange);
        }
    }

    onBirthPlaceStateChange = (e) => {
        if (StateValid(e.value)) {
            this.setState({ ...this.state, BirthPlace: { ...this.state.BirthPlace, country: "US", state: e.value } }, this.sendOnChange);
        }
    }

    onPregnantChange = (e) => {
        this.onChange({
            target: {
                name: "pregnant",
                value: e.target.value
            }
        })
    }

    onDueDateChange = (date) => {
        if (date < Date.now()) return;
        this.onChange({ target: { name: "dueDate", value: date } });
    }

    onHaveSSNChange = (e) => {
        if (e.target.value === "true") {
            this.onChange({ target: { name: "haveSSN", value: true } });
        } else {
            this.setState({ ...this.state, haveSSN: false, ssn: null }, this.sendOnChange);
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "PROFILE", this.state));
        }
    }

    removeImage = async () => {
        await this.props.removeFile({
            category: "Identification",
            index: 0
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg="9">
                        <Row className="g-3">
                            <Col lg="12">
                                <h2 className="small-title">Personal Information</h2>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="firstName" value={this.state.firstName || ""} onChange={this.onChange} readOnly={ this.props.locked } />
                                    <Form.Label>FIRST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="middleName" value={this.state.middleName || ""} onChange={this.onChange} readOnly={ this.props.locked } />
                                    <Form.Label>MIDDLE NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="lastName" value={this.state.lastName || ""}  onChange={this.onChange} readOnly={ this.props.locked } />
                                    <Form.Label>LAST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    { (this.props.locked) ? 
                                    <Form.Control type="text" name="generation" value={this.state.generation || ""} readOnly={ this.props.locked } />
                                    :
                                    <GenerationsSelect value={this.state.generation || ""} onChange={this.onGenerationChange} placeholder="" name="generation" />
                                    }
                                    <Form.Label>SUFFIX</Form.Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="6">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="maiden" value={this.state.maiden || ""} onChange={this.onChange} readOnly={ this.props.locked }/>
                                    <Form.Label>MAIDEN NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3 top-label">
                                    { (this.props.locked) ? 
                                    <Form.Control type="text" name="marriageCount" value={wordsOrdinal(this.state.marriageCount) || ""}  readOnly={ this.props.locked } />
                                    :
                                    <NumberSelect value={this.state.marriageCount} minValue="1" maxValue="20" suffix={true} onChange={this.onMarriageCountChange} placeholder="" name="marriageCount" />
                                    }
                                    <Form.Label>THIS MARRIAGE IS MY ...</Form.Label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="g-3">
                            <Col lg="6">
                                <div className="mb-3 top-label">
                                    { (this.props.locked) ? 
                                    <Form.Control type="text" name="educationLevel" value={EducationLabel(this.state.educationLevel) || ""}  readOnly={ true } />
                                    :
                                    <EducationSelect value={this.state.educationLevel} onChange={this.onEducationLevelChange} placeholder="" name="educationLevel" />
                                    }
                                    <Form.Label>EDUCATION LEVEL</Form.Label>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3 top-label">
                                    { (this.props.locked) ? 
                                    <Form.Control type="text" name="raceCode" value={RaceLabel(this.state.raceCode) || ""}  readOnly={ true } />
                                    :
                                    <RaceSelect value={this.state.raceCode} onChange={this.onRaceCodeChange} placeholder="" name="raceCode" />
                                    }
                                    <Form.Label>RACIAL IDENTIFICATION</Form.Label>
                                </div>
                            </Col>
                        </Row>
                
                    </Col>
                    <Col lg="3">
                        <div>
                            <h2 className="small-title">Identification</h2>
                            <Card body>
                                {(!this.props.file) ?
                                    <Dropzone label="Upload driver's license or passport" referenceUuid={this.state.uuid} category="Identification" />
                                    :
                                    <>
                                        <button type="button" className="btn-close text-quaternary" aria-label="remove image" style={{ position: "relative", top: "20px" }} onClick={this.removeImage}></button>
                                        <Image src={this.state.url} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                    </>
                                }
                            </Card>
                        </div>
                    </Col>
                </Row>

                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">My Current Address</h2>
                    </Col>
                </Row>
                <Address {...this.state.Residence} onChange={ this.onAddressChange } locked={ this.props.locked }  />
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">My Preferred Phone #</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <div className="mb-3">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="phone" value={this.state.Phone?.number || ""} readOnly={true} style={{width: "200px"}} />
                                :
                                <NumberFormat format="(###) ###-####" mask="_" className="form-control" value={this.state.Phone?.number} allowEmptyFormatting onChange={this.onPhoneChange} name="Phone.number" />
                            }
                        </div>
                    </Col>
                </Row>
                
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Social Security Number?</h2>
                    </Col>
                </Row>
                <Row className="g-3 mb-3">
                    <Col lg="3">
                        <OptionCard name="haveSSN" value={true} checked={this.state.haveSSN} onChange={this.props.locked ? null : this.onHaveSSNChange} iconClassName="iapp-Barcode" iconClassColor="green" iconFontSize="48px" caption="I Have a SSN" />
                    </Col>
                    {this.state.haveSSN ? (
                        <Col lg="2" className="mb-3 top-label">
                            {(this.props.locked) ?
                                <Form.Control type="text" name="ssn" value={this.state.ssn || ""} readOnly={true} />
                                :
                                <NumberFormat format="###-##-####" mask="_" className="form-control" value={this.state.ssn} allowEmptyFormatting onChange={this.onChange} name="ssn" />
                            }
                            <Form.Label>SOCIAL SECURITY #</Form.Label>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="haveSSN" value={false} checked={!this.state.haveSSN} onChange={this.props.locked ? null : this.onHaveSSNChange} iconClassName="iapp-Barcode-scan" iconClassColor="red" iconFontSize="48px" caption="I Don't Have a SSN" />
                    </Col>
                    
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">My Gender</h2>
                    </Col>
                </Row>
                <Row className="g-3 mb-3">
                    <Col xs="3">
                        <OptionCard name="genderCode" value="M" checked={this.state.genderCode === "M"} onChange={this.props.locked ? null : this.onChange} iconClassName="igender-MALE" iconClassColor="skyblue" iconFontSize="48px" caption="Male" />
                    </Col>
                    <Col xs="3">
                        <OptionCard name="genderCode" value="F" checked={this.state.genderCode === "F"} onChange={this.props.locked ? null : this.onChange} iconClassName="igender-FEMALE" iconClassColor="pink" iconFontSize="48px" caption="Female" />
                    </Col>
                    <Col xs="3">
                        <OptionCard name="genderCode" value="O" checked={this.state.genderCode === "O"} onChange={this.props.locked ? null : this.onChange} iconClassName="igender-AGENDER" iconClassColor="blueviolet" iconFontSize="48px" caption="Other" />
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">I Was Born ...</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="3">
                        <OptionCard name="countryCode" value="US" checked={this.state.BirthPlace?.country === "US"} onChange={this.props.locked ? null : this.onBirthplaceChange} iconClassName="imaps-usa-map" iconClassColor="black" iconFontSize="48px" caption="in the United States" />
                    </Col>
                    {this.state.BirthPlace?.country === "US" ? (
                        <Col lg="4">
                            <Row><Col lg="12">
                                <div className="mb-3 top-label">
                                    {(this.props.locked) ?
                                        <Form.Control type="text" name="birthplace.state" value={StateLabel(this.state.BirthPlace.state) || ""} readOnly={true} />
                                        :
                                        <StateSelect value={this.state.BirthPlace.state} onChange={this.onBirthPlaceStateChange} placeholder="" name="birthplace.state" />
                                    }
                                    <Form.Label>STATE OF BIRTH</Form.Label>
                                </div>
                            </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.props.locked) ?
                                            <Form.Control type="text" name="birthDate" value={formatDate(this.state.birthDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.birthDate} onChange={this.onBirthDateChange} placeholder="" name="birthDate" />
                                        }
                                        <Form.Label>DATE OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="countryCode" value="OT" checked={this.state.BirthPlace?.country !== "US"} onChange={this.props.locked ? null : this.onBirthplaceChange} iconClassName="imaps-globe-earth" iconClassColor="black" iconFontSize="48px" caption="Somewhere Else" />
                    </Col>
                    {this.state.BirthPlace?.country !== "US" ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.props.locked) ?
                                            <Form.Control type="text" name="birthplace.country" value={CountryLabel(this.state.BirthPlace?.country) || ""} readOnly={true} />
                                            :
                                            <CountrySelect value={this.state.BirthPlace?.country} onChange={this.onBirthplaceCountryChange} placeholder="" name="birthplace.country" />
                                        }
                                        <Form.Label>COUNTRY OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.props.locked) ?
                                            <Form.Control type="text" name="birthDate" value={formatDate(this.state.birthDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.birthDate} onChange={this.onBirthDateChange} placeholder="" name="birthDate" />
                                        }
                                        
                                        <Form.Label>DATE OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                </Row>
                <Row className="g-3" style={{paddingTop: "10px"}}>
                    <Col lg="12">
                        <h2 className="small-title">I am Currently ...</h2>
                    </Col>
                </Row>
                <Row className="g-3" >
                    <Col lg="3">
                        <OptionCard name="pregnant" value="1" checked={(this.state.pregnant || "0") === "1"} onChange={this.props.locked ? null : this.onPregnantChange} iconClassName="icon-stork-with-bundle" iconClassColor="green" iconFontSize="48px" caption="Pregnant" />
                    </Col>
                    {this.state.pregnant === "1" ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.props.locked) ?
                                            <Form.Control type="text" name="dueDate" value={formatDate(this.state.dueDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.dueDate} onChange={this.onDueDateChange} placeholder="" name="dueDate" />
                                        }
                                        <Form.Label>DUE DATE</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="pregnant" value="0" checked={this.state.pregnant !== "1"} onChange={this.props.locked ? null : this.onPregnantChange} iconClassName="icon-girl" iconClassColor="black" iconFontSize="48px" caption="Not Pregnant" />
                    </Col>
                    {this.state.pregnant !== "1" ? (
                        <Col lg="4">
                        </Col>
                    ) : ``}
                </Row>
            </>
        )
    }
}

Personal.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items,
        file: state.files.file
    });
};

export default connect(mapStateToProps, { getFile, removeFile })(Personal);

