import { combineReducers } from "redux";
import auth from "./auth";
import licensee from "./licensee";
import alert from "./alert";
import notification from "./notification";
import matter from "./case";
import purchase from "./purchase";
import fields from "./field";
import files from "./file";
import page from "./page";

export default combineReducers({
  auth,
  alert,
  licensee,
  notification,
  matter,
  purchase,
  fields,
  files,
  page
});
