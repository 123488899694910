
import React from "react";
import queryString from "query-string";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { connect } from "react-redux";

import { intent as getIntent } from "../../../actions/purchase";
import { pending as pendingPayment } from "../../../actions/cost";
import { CheckoutForm } from "./Checkout";
import { key } from "../../../constants/stripe";

const stripePromise = loadStripe(key);

class Step extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: "",
            step: 0,
            secret: "",
            intent: null
        };
        this.pending = false;
    }

    get price() {
        const costs_array = this.props.purchase?.Costs;
        let result = 0;
        for (let i = 0; (i < costs_array?.length || 0); i++) {
            const element = costs_array[i];
            result += Number(element.amount);
        }
        result -= Number(this.props.purchase?.paymentReceived);
        return result;
    }

    get formattedPrice() {
        try {
            return Math.floor(this.price * 100);
        } catch (ex) {
            return 0
        }
    }

    get returnUrl() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["return_url"];
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
        await this.componentDidUpdate();
    }

    async componentDidUpdate() {
        if (!this.state.intent && this.props.display && !this.pending && !isNaN(this.price) && this.price > 0) {
            this.pending = true;
            const intent = await this.props.getIntent(this.props.for?.email, this.price);
            const payment = await this.props.pendingPayment(this.props.purchase.uuid, intent);
            this.setState({
                ...this.state,
                intent,
                payment
            });
            this.pending = false;
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ ...this.state, navClass: "nav-sticky", imglight: false });
        } else {
            this.setState({ ...this.state, navClass: "", imglight: true });
        }
    };

    render() {
        if (((!this.props.display) || (!this.state.intent) || (isNaN(this.price)) || (this.price === 0))) return <></>;

        return (
            <Elements options={{ mode: "payment", currency: "usd", amount: this.formattedPrice, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                <CheckoutForm
                    product={this.props?.purchase?.Product}
                    costs={this.props?.purchase?.Costs}
                    intent={this.state.intent}
                    payment={this.state.payment}
                    events={this.props.events}
                    labels={{ back: "Agreement" }}
                    show={{ back: true }}
                    returnUrl={this.returnUrl}
                    purchase={this.props?.purchase}
                />
            </Elements>

        );
    }
}

export default connect(null, { getIntent, pendingPayment })(Step);