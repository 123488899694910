import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { deepEqual } from "../../utils/object";

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            uuid: this.props.uuid || uuidv4()
        }
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps, this.props)) {
            this.setState({
                ...this.props
            });
        }
    }

    sendOnChange = (e) => {
        if (this.props.onChange) {
            let __data_element = { ...this.state };
            delete __data_element["onChange"];
            this.props.onChange(__data_element);
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.sendOnChange);
        
    }

    render() {
        return (
            <>
                <Row className={this.props.rowClassName}>
                    <Col lg="4" sm="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.year || ""} type="text" name="year" onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>YEAR</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4" sm="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.make || ""} type="text" name="make" onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>MAKE</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4" sm="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.model || ""} type="text" name="model" onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>MODEL</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6" sm="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.registration || ""} type="text" name="registration" onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>REGISTRATION #</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6" sm="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.vessel || ""} type="text" name="vessel" onChange={this.onChange} readOnly={this.props.locked} />
                            <Form.Label>VESSEL NAME</Form.Label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Editor;