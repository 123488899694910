import api, { authenticated } from "../utils/api";
import { send as sendAlert } from "./alert";

export const pending = (purchaseUuid, product, intent) => async (dispatch) => {
    try {
        const res = await api.post(`/purchases/addons/pending/${purchaseUuid}`, {
            product,
            intent
        });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
}


export const confirm = (uuid, intent) => async (dispatch) => {
    try {
        const res = await authenticated.post(`/purchases/addons/confirm`, {
            uuid,
            intent
        });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
} 