import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";

import { deepEqual } from "../../../utils/object";
import { currency } from "../../../utils/number";
import { label } from "../../../layout/select/cost-type-list";

class Package extends React.Component {
  constructor(props) {
    super(props);

    this.DoClickPromoCodeLink = this.DoClickPromoCodeLink.bind(this);
    this.DoSearchForCode = this.DoSearchForCode.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.DoForward = this.DoForward.bind(this);
    this.DoBack = this.DoBack.bind(this);
    this.DoChange = this.DoChange.bind(this);
    this.DoRemovePromoCode = this.DoRemovePromoCode.bind(this);


    this.state = {
      code: props.discount ? {
        ...props.discount
      } : {
        show: false,
        valid: false,
        empty: true,
        value: "",
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps?.product, this.props?.product)) {
      this.setState({
        ...this.state,
        code: {
          show: false,
          valid: false,
          empty: true,
          value: "",
        }
      });
    }

    if (!deepEqual(prevProps?.discount, this.props?.discount)) {
      this.setState({
        ...this.state,
        code: { ...this.props.discount }
      });
    }
  }

  DoForward(event) {
    if (this.props.onForward) {
      this.props.onForward({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoBack(event) {
    if (this.props.onBack) {
      this.props.onBack({
        price: this.price,
        code: this.state.code,
      });
    }
  }

  DoSearchForCode(event) {
    event.preventDefault();
    this.DoCalculatePromoCode();
  }

  DoClickPromoCodeLink(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        show: true,
      },
    });
  }

  DoChange() {
    if (this.props.onChange) {
      this.props.onChange({ name: "discount", value: { ...this.state.code } });
    }
  }

  DoRemovePromoCode(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        code: {
          ...this.state.code,
          amount: 0,
          style: "S",
          issuer: "",
          value: "",
          platform: {
            amount: 0,
            style: "S",
          },
        },
      },
      this.DoChange
    );
  }

  get discount() {
    return 0;
  }

  get price() {
    const costs_array = this.props.costs;
    let result = 0;
    for (let i = 0; (i < costs_array?.length || 0); i++) {
      const element = costs_array[i];
      result += Number(element.amount);
    }
    result -= Number(this.props.purchase?.paymentReceived || 0);
    return result;
  }

  get platform() {
    return this.props.product.fee;
  }

  get attorney() {
    return this.price - this.platform;
  }

  onCodeChange(event) {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        empty: event.target?.value?.length <= 4,
        value: event.target?.value,
      },
    });
  }

  render() {
    return (
      <div style={{ marginTop: "30px", marginLeft: "30px" }}>
        <Row>
          <Col>
            <h3>YOUR COSTS</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              {this.props.product?.name} ({this.props.product?.Area?.name})
            </h4>
          </Col>
          <Col className="text-end">
            <h4>{currency(this.price)}</h4>
          </Col>
        </Row>
        

        <Row>
          <Col xs="12">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Price Details</Accordion.Header>
                <Accordion.Body>
                  {this.props.costs?.map((element, index) => {return (
                    <Row style={{ marginTop: "20px" }}>
                      <Col xs={1}>&nbsp;</Col>
                      <Col xs={2} className="text-end fs-8">
                        {currency(Number(element.amount))}
                      </Col>
                      <Col xs={9} className="fs-8">
                        {`${label(element.costType)}: ${element.description}`} 
                      </Col>
                    </Row>
                  )})}
                  <Row style={{ marginTop: "20px", borderTop: "1px solid silver" }}>
                    <Col xs={1} className="text-end fs-8">=</Col>
                    <Col xs={2} className="text-end fs-8">
                      {currency(this.price)}
                    </Col>
                    <Col xs={9} className="fs-8">Your price</Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              </Accordion>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.back ? (
              <Button variant="primary" onClick={this.DoBack}>
                Back to {this.props.labels?.back}
              </Button>
            ) : (
              ``
            )}
          </Col>
          <Col sm={12} md={6} style={{ marginTop: "20px" }}>
            {this.props.show?.forward ? (
              <Button variant="primary" onClick={this.DoForward}>
                Continue to {this.props.labels?.forward}
              </Button>
            ) : (
              ``
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Package);