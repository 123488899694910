import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

const DEFAULT_IMAGE = "iapp-Bill11";
const COLOR_ARRAY = [
    "#B4654A",
    "#C2C5BB",
    "#77B28C",
    "#157A6E",
    "#499F68"
];

export function exists(value) {
    return DebtTypeData.filter(obj => { return obj.value === value });
}

export function label(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].label : "Other";
}

export function image(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].image : DEFAULT_IMAGE;
}

export function color(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].color : COLOR_ARRAY[0];
}


export const DebtTypeData = [
    { value: "OT", label: "Other", image: DEFAULT_IMAGE, color: COLOR_ARRAY[0] },
    { value: "CRV", label: "Visa", image: "iapp-Credit-card4", color: COLOR_ARRAY[1] },
    { value: "CRM", label: "Mastercard", image: "iapp-Credit-card4", color: COLOR_ARRAY[1] },
    { value: "CRD", label: "Discover", image: "iapp-Credit-card4", color: COLOR_ARRAY[1] },
    { value: "CRA", label: "American Express", image: "iapp-Credit-card4", color: COLOR_ARRAY[1] },
    { value: "CRO", label: "Other Credit Card", image: "iapp-Credit-card4", color: COLOR_ARRAY[1] },
    { value: "PL", label: "Personal Loan", image: "iapp-Document-file1", color: COLOR_ARRAY[2] },
    { value: "MO1", label: "First Mortgage", image: "iapp-Office-Building", color: COLOR_ARRAY[3] },
    { value: "MO2", label: "Second Mortgage", image: "iapp-Office-Building", color: COLOR_ARRAY[3] },
    { value: "MOO", label: "Other Mortgage", image: "iapp-Office-Building", color: COLOR_ARRAY[3] },
    { value: "MOE", label: "Home Equity", image: "iapp-Office-Building", color: COLOR_ARRAY[3] },
    { value: "VFP", label: "Vehicle Purchase", image: "iapp-Fire-Truck", color: COLOR_ARRAY[4] },
    { value: "VFL", label: "Vehicle Lease", image: "iapp-Fire-Truck", color: COLOR_ARRAY[4] },
    { value: "SL", label: "Student Loan", image: "iapp-Wallet11", color: COLOR_ARRAY[0] }
];

class DebtTypeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, this.sendOnChange);
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) this.props.onChange({ value: this.state.value });
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={DebtTypeData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={DebtTypeData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default DebtTypeSelect;
