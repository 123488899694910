import {
    GET_LOGGED_IN,
    GET_SPOUSE,
    GET_MARRIAGE,
    GET_CHILDREN,
    GET_CHILD,
    GET_PROPERTIES,
    GET_PROPERTY,
    GET_DEBT,
    GET_DEBTS,
    CLEAR_SPOUSE,
    CLEAR_CHILD,
    CLEAR_PROPERTY,
    CLEAR_DEBT,
    SET_LOADING,
    SET_LOADED,
    GET_CHANGES,
    GET_COSTS
} from "../actions/types";

import { toDate } from "../utils/date";

const initialState = {
    loading: true,
    self: {},
    spouse: {},
    marriage: {},
    children: [],
    properties: [],
    debts: [],
    costs: [],
    changes: 0
};


function caseReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_COSTS:
            return {
                ...state,
                costs: payload
            };
        case GET_CHANGES:
            return {
                ...state,
                changes: payload.changes
            };
        case CLEAR_SPOUSE:
            return {
                ...state,
                spouse: {},
                self: {
                    ...state.self,
                    spouse: {},
                    spouseUuid: null
                },
                loading: false,
            };
        case GET_SPOUSE:
            return {
                ...state,
                loading: false,
                spouse: {
                    ...payload,
                    birthDate: toDate(payload.birthDate),
                    haveSSN: Boolean(payload.ssn)
                },
            };
        case GET_MARRIAGE:
            return {
                ...state,
                loading: false,
                marriage: payload
            };
        case GET_CHILDREN:
            return {
                ...state,
                loading: false,
                children: payload
            };
        case GET_CHILD:
            return {
                ...state,
                loading: false,
                child: payload
            };
        case CLEAR_CHILD:
            return {
                ...state,
                child: {},
                children: []
            };
        case GET_PROPERTIES:
            return {
                ...state,
                loading: false,
                properties: payload
            };
        case GET_PROPERTY:
            return {
                ...state,
                loading: false,
                property: payload
            };
        case CLEAR_PROPERTY:
            return {
                ...state,
                property: {},
                properties: []
            };
        case GET_DEBTS:
            return {
                ...state,
                loading: false,
                debts: payload
            };
        case GET_DEBT:
            return {
                ...state,
                loading: false,
                debt: payload
            };
        case CLEAR_DEBT:
            return {
                ...state,
                debt: {},
                debts: []
            };
        case GET_LOGGED_IN:
            return {
                ...state,
                loading: false,
                self: {
                    ...payload,
                    birthDate: toDate(payload.birthDate),
                    haveSSN: Boolean(payload.ssn)
                },
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SET_LOADED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}

export default caseReducer;
