import { send as setAlert } from "./alert";
import api from "../utils/api";
import { JavaScripttoXML } from "../utils/serialize";
import { build, send } from "../utils/email";
import { get as getNotifications } from "./notification";
import { get as getPurchase } from "./purchase";

import {
  AUTH_ERROR,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
  PASSWORD_CHANGE,
  RESET_SUCCESS,
  RESET_FAILED,
  VERIFY_TOKEN_FAILED,
  VERIFY_TOKEN_SUCCESS
} from "./types";

export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/users/me");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch(getNotifications());
    dispatch(getPurchase());

  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (email, password, url, licensee) => async (dispatch) => {
  try {
    const res = await api.post("/auth", { email, password, url, licenseeUuid: licensee });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(userAudit());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: LOGIN_FAILED,
    });
  }
};

export const logout = () => ({ type: LOGOUT });

export const changePassword = (userId, passwords) => async (dispatch) => {
  try {
    const res = await api.post(`/users/${userId}/password/change`, passwords);
    dispatch({
      type: PASSWORD_CHANGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};


export const verifyToken = (token, email) => async (dispatch) => {
  try {
    const res = await api.post(`/auth/verify/token`, { email, token });
    dispatch({
      type: VERIFY_TOKEN_SUCCESS,
      payload: {
        uuid: res.data.uuid
      }
    });
    return { uuid: res.data.uuid, success: true };

  } catch (err) {
    dispatch({
      type: VERIFY_TOKEN_FAILED,
      payload: {
        email,
        token,
        msg: err?.response?.statusText,
        status: err?.response?.status
      }
    });
  };
  return { uuid: null, success: false };
};

export const resetByToken = (token, userId, email, passwords) => async (dispatch) => {
  try {
    await api.post(`/users/t/${token}/u/${userId}/password/change`, { email, password: passwords });
    dispatch({
      type: RESET_SUCCESS,
      payload: {
        email,
        uuid: userId
      }
    });
    return {
      success: true,
      uuid: userId,
      email
    };
  } catch (err) {
    dispatch({
      type: RESET_FAILED,
      payload: {
        email,
        token,
        uuid: userId,
        msg: err?.response?.statusText,
        status: err?.response?.status
      }
    });
  };

  return {
    success: false,
    uuid: userId,
    email
  };
  
};


export const sendReset = (email, licensee) => async (dispatch) => {
  let __response;
  try {
    __response = await api.post("/auth/reset", { email, licenseeUuid: licensee });

    let __email = await build(JavaScripttoXML(__response.data), "RESET", licensee);
    let __send = await send(__email.from, __response.data.email, __email.subject, __email.html);
    __response = {
      ...__response.data,
      Send: __send
    }

    return __response;
                
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
  }
}

export const userAudit = () => async (dispatch) => {
  let __response;
  try {
    __response = await api.get("/tasks/audit?type=R");
    return __response;
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
  }
}

