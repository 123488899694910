import axios from 'axios';
import store from '../../store';
import { LOGOUT } from '../../actions/types';

export const development_mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const api_server_secure = !development_mode;
const api_server_address = development_mode ? "localhost" : "api.divorce.feradel.com";
const api_server_port = development_mode ? 10600 : 443;
const api_server_path = "api";

export function url() {
         // http[s]://server:port/path
  return `http${api_server_secure ? "s" : ""}://${api_server_address}:${api_server_port}/${api_server_path}`;
}

const api = axios.create({
  baseURL: url(),
  headers: {
    'Content-Type': 'application/json'
  }
});

export const authenticated = axios.create({
  baseURL: url(),
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "cFD4FR82V8UmNYvY"
  },
});

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/
api.interceptors.request.use(
  cfg => {
    cfg.headers['x-auth-token'] = localStorage.token;
    return cfg;
  },
  err => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

authenticated.interceptors.request.use(
  cfg => {
    cfg.headers['x-auth-token'] = localStorage.token;
    return cfg;
  },
  err => {
    return Promise.reject(err);
  }
);

authenticated.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

export default api;