import api, { authenticated } from "../utils/api";
import {
  GET_PURCHASES, GET_ADDONS, LOGOUT
} from "./types";
import { getByArea as getFields } from "./field";
import { send as sendAlert } from "./alert";
import { addons as myAddons } from "./purchase";
import { isGuid } from "../utils/string";
import { build, send } from "../utils/email";
import { JavaScripttoXML } from "../utils/serialize";

export const addons = (purchaseUuid) => async (dispatch) => {
    try {
        const res = await api.get(`/purchases/addons/a/${purchaseUuid}/f/all`);

        dispatch({
            type: GET_ADDONS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = () => async (dispatch) => {
    try {
        const res = await api.get(`/purchases/me`);
        dispatch({
            type: GET_PURCHASES,
            payload: res.data,
        });
        dispatch(getFields(res.data.Product.areaUuid));
        dispatch(myAddons(res.data.uuid));
        
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const intent = (email, amount) => async (dispatch) => {
    try {
        const res = await api.post(`/payments/stripe/intent`, { email, amount });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"))
    }
}

export const pending = (whose, product, discount, intent) => async (dispatch) => {
    try {
        const res = await authenticated.post(`/purchases/support/pending`, {
            for: whose,
            product,
            discount,
            intent
        });
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
}

export const confirm = (uuid, intent) => async (dispatch) => {
    try {
        const res = await authenticated.post(`/purchases/support/confirm`, {
            uuid,
            intent
        });
        
        let __email = await build(JavaScripttoXML(res.data), "INVITE", res.data.licenseeUuid);
        await send(__email.from, res.data.email, __email.subject, __email.html);
        return res.data;
    } catch (err) {
        dispatch(sendAlert(err, "Error"));
    }
} 