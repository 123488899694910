import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

import ConditionalButton from "../../../../layout/conditional-button";
import Modal from "../../../../layout/modal";
import { deepEqual } from "../../../../utils/object";
import { isGuid } from "../../../../utils/string";
import { toDate } from "../../../../utils/date";
import Editor from "./biographical";

import { get as getProperty, save as saveProperty, remove as deleteProperty } from "../../../../actions/property";
import { get as getMarriage } from "../../../../actions/marriage";
import { pack as setAlerts } from "../../../../actions/alert";
import { send as sendPageSetting } from "../../../../actions/page";
import { calculate } from "../../../../utils/validation";

const Buttons = {
    Save: 0,
    Delete: 1,
    SaveAdd: 2
}

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            property: {
                uuid: uuidv4(),
                propertyCode: "OT"
            },
            errors: [],
            modal: {
                show: false,
                title: "",
                body: "",
                buttons: [
                    {
                        default: true,
                        caption: "cancel",
                        onClick: null,
                        variant: "secondary"
                    },
                    {
                        caption: "ok",
                        onClick: null,
                        variant: "outline-primary"
                    }
                ]
            }
        };
    }

    get subject() {
        return this.props.match.params.subject;
    }

    get fieldPrefix() {
        return `PROPERTY${this.props.propertyCode}`;
    }

    componentDidMount = async () => {
        setTimeout(async () => {
            await this.props.getMarriage();
            if (isGuid(this.subject)) {
                await this.props.getProperty(this.subject);
            }
            this.setState({
                errors: calculate(this.props.fields, this.fieldPrefix, this.state.property)
            });
            this.props.sendPageSetting({ context: `form-property` });
        }, 30);
    }
    
    postUpdate = () => {
        this.props.sendPageSetting({ title: `${this.state.property.name || ''}` });
        this.setState({
            errors: calculate(this.props.fields, this.fieldPrefix, this.state.property)
        });
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.property, this.props.property) && !deepEqual(this.props.property, this.state.property)) {
            this.setState({
                property: this.props.property
            }, this.postUpdate);
        }
    }

    onChange = (value, errors) => {
        this.setState({
            ...this.state,
            property: value,
            errors: errors
        }, this.postUpdate);
        
    }

    onSaveClick = async (e) => {
        const result = await this.props.saveProperty({ ...this.state.property, marriageUuid: this.props.marriage.uuid, createdByUuid: this.props.auth.user.uuid });
        if (result && !e?.ignore) {
            this.props.history.push("/dashboard/property");
        }
    }

    onHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: false
            }
        })
    }

    onOkDeleteModal = async (e) => {
        if (this.state.property.uuid) {
            await this.props.deleteProperty(this.state.property.uuid);
        }
        this.props.history.push("/dashboard/property");
    }

    onDeleteClick = async (e) => {
        const __buttons = this.state.modal.buttons;
        __buttons[0].onClick = this.onHideModal;
        __buttons[1].onClick = this.onOkDeleteModal;
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: true,
                title: "Confirm Delete",
                body: `Confirm delete of property ${this.state.property.name || ""}? Click ok to continue or cancel to return to editor.`
            }
        });
    }

    onSaveAddClick = async (e) => {
        await this.onSaveClick(e);
        this.props.history.push("/dashboard/property/add");
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    display = (button) => {
        switch (button) {
            case Buttons.Delete:
            case Buttons.Save:
            case Buttons.SaveAdd:
                return (!Boolean(this.props.marriage.propertyLockDate));
            default:
                return false;
        }
    }

    enable = (button) => {
        switch (button) {
            case Buttons.Save:
            case Buttons.SaveAdd:
                return this.state.errors?.length === 0;
            default:
                return false;
        }
    }

    get locked() {
        return Boolean(this.state.property.Marriage?.propertyLockDate);
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="5" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" disabledVariant="muted" tooltip="Save this property's information" display={this.display(Buttons.Save)} enabled={this.enable(Buttons.Save)} onEnabledClick={this.onSaveClick} onDisabledClick={this.onShowErrors} icon="save">Save</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Delete this property's information" display={this.display(Buttons.Delete)} enabled={true} onEnabledClick={this.onDeleteClick} icon="multiply">Delete</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Save and add a new property" display={this.display(Buttons.SaveAdd)} enabled={this.enable(Buttons.SaveAdd)} icon="plus" onEnabledClick={this.onSaveAddClick} onDisabledClick={this.onShowErrors}>Save + Add</ConditionalButton>
                </Col>
                <Col md="12">
                    <Form name="frm" action="post">
                        <Row>
                            <Col>
                                <Card body className="mb-5">
                                    <Editor {...this.props.property} onChange={this.onChange} locked={this.locked} />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                {/* Static Backdrop Start */}
                <Modal show={this.state.modal.show} title={this.state.modal.title} body={this.state.modal.body} buttons={this.state.modal.buttons} />
                    
                {/* Static Backdrop End */}
            </>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    property: PropTypes.object, 
    marriage: PropTypes.object,
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        property: state.matter.property,
        marriage: state.matter.marriage,
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, { getProperty, deleteProperty, saveProperty, setAlerts, getMarriage, sendPageSetting })(Profile);
