import { development_mode } from "../utils/api";

export const keys =
{
    test: {
        key: "pk_test_51MG8hTLXm1s8C2TnHT7TqUxh2nanyIbXkLv9S7n448Fy7aEuE78QyUK7Fg2vmR8I0hZXKX48LHuN5OfVlIblb2Al00b7IANzUO"
    },
    live: {
        key: "pk_live_51MG8hTLXm1s8C2Tn6r8Bb0YNqG6qLbsScoUJOPAbOMeVokWdWAKu6fkJkqdET7eo4cm8siOWci9tCYagfkQXc5lI004srJEPlF"
    }
}

export const key = keys[development_mode ? "test" : "live"].key; 
