import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
//import useLayout from '../hooks/useLayout';

const Wizard = ({ caption, children, profile }) => {
  //useLayout();

  useEffect(() => {
    document.body.classList.add('h-100');
    const root = document.getElementById('root');
    if (root) {
      root.classList.add('h-100');
    }
    return () => {
      document.body.classList.remove('h-100');
      if (root) {
        root.classList.remove('h-100');
      }
    };
  }, []);

  return (
    <>
    <span id="purchase-section-header">
      <div>
        <header
          role="banner"
          style={{
            padding: "0px 56px",
            display: "flex",
            width: "100%",
            WebkitBoxAlign: "center",
            alignItems: "center",
            backgroundColor: "white",
            height: "60px",
          }}
        >
          <div className="logo position-relative">
            <a href="https://divorcex99.com"><Image src={`/img/l/logo/${profile?.smallLogo}`} /></a>
          </div>
        </header>
      </div>
      {children}
      </span>
      <div class="footer" style={{ backgroundColor: "#2f3539", borderTop: "1px solid #393f43", padding: "55px 0", fontWeight: "300", fontSize: "16px", color: "#cecece" }}>
          <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="copyright">
                        <p>Copyright © 2023-2024 <a href="https://www.divorcex.co">DivorceX99.com LLC</a>. All Rights Reserved.</p>
                        <p>Use of divorcex99.com is subject to our <a href="https://www.divorcex99.com/terms/">Terms of Service</a> and <a href="https://www.divorcex99.com/privacy/">Privacy Policy</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </>
  );
};

export default Wizard;
