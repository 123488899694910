import React from "react";
import { connect } from "react-redux";
import { Row } from 'react-bootstrap';
import Item from "./item";

class Items extends React.Component {
    render() {
        return (
            <Row>
                {this.props.items.map( (element, index) => (
                    <Item key={ `child_card_${index}` } {...element} total={this.props.items?.length || 0} onOpen={this.props.onOpen} /> 
                ))}
            </Row>
        )
    }
}

export default connect()(Items);