export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";

export const CODES_LOADED = "CODES_LOADED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADED = "SET_LOADED";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";

export const REM_ALERT = "REM_ALERT";
export const REM_ALERTS = "REM_ALERTS";
export const SET_ALERT = "SET_ALERT";
export const SET_ALERTS = "SET_ALERTS";

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_FIELD_DATA = "GET_FIELD_DATA";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";
export const VERIFY_TOKEN_FAILED = "VERIFY_TOKEN_FAILED";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_LOGGED_IN = "GET_LOGGED_IN"; 
export const GET_SPOUSE = "GET_SPOUSE"; 
export const CLEAR_SPOUSE = "CLEAR_SPOUSE";

export const SET_VERB = "SET_VERB"; 

export const GET_PURCHASES = "GET_PURCHASES";
export const GET_ADDONS = "GET_ADDONS";

export const GET_FILE = "GET_FILE";
export const SET_FILE = "SET_FILE";
export const CLEAR_FILE = "CLEAR_FILE";

export const GET_MARRIAGE = "GET_MARRIAGE"; 

export const GET_CHILD = "GET_CHILD"; 
export const GET_CHILDREN = "GET_CHILDREN";
export const CLEAR_CHILD = "CLEAR_CHILD";

export const SET_PAGE_INFO = "SET_PAGE_INFO";

export const GET_PROPERTY = "GET_PROPERTY"; 
export const GET_PROPERTIES = "GET_PROPERTIES";
export const CLEAR_PROPERTY = "CLEAR_PROPERTY";

export const GET_DEBT = "GET_DEBT"; 
export const GET_DEBTS = "GET_DEBTS";
export const CLEAR_DEBT = "CLEAR_DEBT";

export const GET_CONTRACT = "GET_CONTRACT";
export const SET_CONTRACT = "SET_CONTRACT";

export const GET_CHANGES = "GET_CHANGES";
export const GET_COSTS = "GET_COSTS";

export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const GET_PRODUCT = "GET_PRODUCT";
export const NO_PRODUCTS = "NO_PRODUCTS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const NO_PRODUCT = "NO_PRODUCT"; 