import api from "../utils/api";
import { send as sendAlert } from "./alert";
import { v4 as uuidv4 } from 'uuid';
import { isGuid } from "../utils/string";

export const update = (contractUuid, index, postedByUuid, content, uuid="") => async (dispatch) => {
    const data = {
        uuid: (isGuid(uuid) ? uuid: uuidv4()), postedByUuid, content
    };

    try {
        const res = await api.post(`/contracts/me/x/${index}`, data);
        return res.data;
    } catch (err) {
        dispatch(sendAlert("Error posting chat.", "danger"));
        return false;
    }
};

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/contracts/me/chat/c`);
        return res.data;
    } catch (err) {
        dispatch(sendAlert("Error retrieving chats.", "danger"));
        return false;        
    }
}

export const updates = (index, since) => async (dispatch) => {
    try {
        const res = await api.get(`/contracts/me/chat/c/i/${index}/s/${since}`);
        return res.data;
    } catch (err) {
        dispatch(sendAlert("Error retrieving chats.", "danger"));
        return false;        
    }
}