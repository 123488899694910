import api from "../utils/api";
import {
  GET_FILE, CLEAR_FILE, LOGOUT
} from "./types";

export const get = ({ category, index }) => async (dispatch) => {
    try {
        const res = await api.get(`/files/me/c/${category}/i/${index}`, {responseType: 'blob'});

        dispatch({
            type: GET_FILE,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const remove = ({ category, index }) => async (dispatch) => {
    try {
        const res = await api.delete(`/files/me/c/${category}/i/${index}`, {responseType: 'blob'});

        dispatch({
            type: CLEAR_FILE,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}