import React from "react";
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import { Button, Modal } from 'react-bootstrap';

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            show: false
        }
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    onCancel = async (e) => {
        if (this.props.onCancel) this.props.onCancel(e, this.state);
    }

    onOk = async (e) => {
        if (this.props.onOk) this.props.onOk(e, this.state);
    }

    handleEditorChange = async (value, editor) => {
        this.setState({ ...this.state, value });
    }

    render() {
        if (!this.props.show) return (<></>);
        return (
            <>
                <Modal size="xl" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title id="staticBackdropLabel">{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TinyMCE
                            apiKey='w52yl8nuvm7pk7cu0cbwqex3yi350j67av5xc1klrd690htd'
                            onEditorChange={this.handleEditorChange}
                            initialValue={this.props.initialValue}
                            value={this.state.value}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family: Times New Roman,sans-serif; font-size:14px }'
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onCancel}>cancel</Button>
                        <Button variant="primary" onClick={this.onOk}>ok</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Editor;