import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CsLineIcons from "../../../../cs-line-icons";
import { iif } from "../../../../utils/object";
import Modal from "../../../../layout/addon-modal";

class Display extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addonSelectModal: {
                show: false
            }
        }
    }

    addonPricing = (addonType) => {
        const __addons = this.props.addons.filter(o => (o.addon & addonType) === addonType);
        if (__addons.length === 1) {
            this.props.history.push(`/purchase/a/${this.props.purchase.uuid}/i/${__addons[0].uuid}`);
            return;
        } else if (__addons.length > 1) {
            return __addons;
        }
    }

    onSelectAddonModal = async (e, uuid) => {
        this.props.history.push(`/purchase/a/${this.props.purchase.uuid}/i/${uuid}?return_url=/dashboard`);
    }

    onHideAddonModal = (e) => {
        this.setState({
            ...this.state,
            addonSelectModal: {
                ...this.state.addonSelectModal,
                show: false
            }
        })
    }

    moduleContains = (value) => {
        return (this.props.purchase?.moduleMask & value);
    }

    onClickBuyMoreDraftingCredits = (e) => {
        const __addons = this.addonPricing(1);
        if (__addons) {
            this.setState({
                ...this.state,
                addonSelectModal: {
                    ...this.state.addonSelectModal,
                    show: true,
                    addons: __addons,
                    addonType: 1
                }
            })
        }
    }

    onClickBuyProperty = (e) => {
        const __addons = this.addonPricing(2);
    }

    onClickBuyFamily = (e) => {
        const __addons = this.addonPricing(4);
    }

    render() {
        return (
            <div style={{ marginTop: "30px", marginLeft: "30px" }}>
                <Row>
                    <Col>
                        <h4>
                            {this.props.purchase?.Product?.name} ({this.props.purchase?.Product?.Area?.name})
                        </h4>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col className="text-center col-md-1">
                        <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: "green" }}>
                            <CsLineIcons icon={"check-circle"} />
                        </span>
                    </Col>
                    <Col className="col-md-9">
                        <h5>Settlement Agreement Builder</h5>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col className="text-center col-md-1">
                        <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: iif(this.moduleContains(2), "green", "red") }}>
                            <CsLineIcons icon={iif(this.moduleContains(2), "check-circle", "close")} />
                        </span>
                    </Col>
                    <Col className="col-md-9">
                        <h5 style={{ color: iif(this.moduleContains(2), "inherit", "#c9c9c9") }}>
                            Family Modules
                            {iif((this.moduleContains(2) && this.props.purchase?.Product.family), " (Added)", "")}
                            {iif(this.moduleContains(2), "", <> <a href="#" onClick={ this.onClickBuyFamily } className="badge bg-primary">Buy Now</a></>)}
                        </h5>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col className="text-center col-md-1">
                        <span style={{ position: "relative", top: "-15px", fontSize: "24px", color: iif(this.moduleContains(1), "green", "red") }}>
                            <CsLineIcons icon={iif(this.moduleContains(1), "check-circle", "close")} />
                        </span>
                    </Col>
                    <Col className="col-md-9">
                        <h5 style={{ color: iif(this.moduleContains(1), "inherit", "#c9c9c9") }}>
                            Property Modules
                            {iif((this.moduleContains(1) && this.props.purchase?.Product.property), " (Added)", "" )}
                            {iif(this.moduleContains(1), "", <> <a href="#" onClick={ this.onClickBuyProperty } className="badge bg-primary">Buy Now</a></>)}
                        </h5>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col className="text-center col-md-1">
                        <h5>{(this.props?.purchase?.draftingCredits || 0) - (this.props?.purchase?.draftingCreditsUsed || 0)}</h5>
                    </Col>
                    <Col className="col-md-9">
                        <h5>Attorney drafting credit(s) available. <a href="#" onClick={ this.onClickBuyMoreDraftingCredits } className="badge bg-primary">Buy More</a></h5>
                    </Col>
                </Row>
                <Modal title="Purchase Addon" addonType={ this.state.addonSelectModal.addonType } show={ this.state.addonSelectModal.show } onHide={(e) => this.setState({ ...this.state, addonSelectModal: { show: false } })} items={this.state.addonSelectModal.addons} onSelect={ this.onSelectAddonModal }>

                </Modal>
            </div>

        )
    }
}

Display.propTypes = {
    auth: PropTypes.object.isRequired,
    addons: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        addons: state.purchase.addons,
        purchase: state.purchase.purchase
    });
};

export default connect(mapStateToProps)(Display);
