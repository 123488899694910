var o2x = require("object-to-xml");

export function JavaScripttoXML(data, header=true) {
  let obj = header ? {
    '?xml version="1.0" encoding="iso-8859-1"?': null,
    data,
  } : {
    data
  }

  return o2x(obj);
}
