import api from "../utils/api";
import { send as sendAlert } from "./alert";
import { GET_PROPERTY, CLEAR_PROPERTY, GET_PROPERTIES, GET_MARRIAGE, LOGOUT } from "./types";

export const lock = () => async (dispatch) => {
    try {
        const res = await api.post(`/properties/me/lock`);
        dispatch(sendAlert("Locked succesfully", "success"));
        dispatch({
            type: GET_MARRIAGE,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const unlock = () => async (dispatch) => {
    try {
        const res = await api.post(`/properties/me/unlock`);
        dispatch(sendAlert("Unlocked succesfully", "success"));
        dispatch({
            type: GET_MARRIAGE,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}


export const save = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/properties/me`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = ( id ) => async (dispatch) => {
    try {
        const res = await api.get(`/properties/me/i/${id}`);
        dispatch({
            type: GET_PROPERTY,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = ( marriageId ) => async (dispatch) => {
    try {
        const res = await api.get(`/properties/me/m/${marriageId}`);
        dispatch({
            type: GET_PROPERTIES,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const remove = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/properties/me`, {data: { uuid: id }});
        dispatch({
            type: CLEAR_PROPERTY
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};