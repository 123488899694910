import React, { Suspense } from 'react';
import { connect } from "react-redux";
import queryString from "query-string";

import WizardPage from '../../../layout/Wizard';
import Header from '../../../layout/header';
import { withLoader } from "../../../layout/loader";

import { Toaster, toast } from 'react-hot-toast';
import { get as getProduct } from "../../../actions/product";
import Payment from "./Payment";
import { deepEqual } from '../../../utils/object';

class Purchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: props.step || 0,
            product: {}
        }
    }

    ///:startat/:product/:base
    get startAt() {
        return this.props.match.params.startat;
    }

    get productUuid() {
        return this.props.match.params.item;
    }
  
    get purchaseUuid() {
        return this.props.match.params.purchase;
    }

    get returnUrl() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["return_url"];
    }

    get caption() {
        switch (this.state.step) {
            case 0:
                return "Pay Now.";
            default:
                break;
        }
    }

    display = (step) => {
        return step === this.state?.step;
    }

    onChange = (e) =>
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });

    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.auth, prevProps.auth)) this.componentDidMount();
    }
  
    componentDidMount() {
        setTimeout(async () => {
            const product = await this.props.getProduct(this.productUuid);
            this.setState({
                ...this.state,
                product: { ...product },
                for: { ...this.props.auth?.user }
            });

        }, 30);
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.name]: e.value
        })
    }

    render() {
        if (!this.state?.for?.email) return <></>;

        return (
            <Suspense fallback={withLoader()}>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title={`DivorceX | ${this.caption}`} description={`Purchase your DivorceX add-ons here`} profile={this.props.profile} />
                <WizardPage caption={this.caption} background={this.props.profile?.backgroundImage} profile={this.props.profile}>
                    <Payment parent={this.purchaseUuid} for={ this.state?.for } display={this.display(0)} product={this.state?.product} onChange={this.onChange} events={{ onBack: () => { this.props.history.push("/dashboard") } }} />
                </WizardPage>
            </Suspense>
        );
    }
}



const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    profile: state.licensee.profile
  });
}

export default connect(mapStateToProps, { getProduct })(Purchase);