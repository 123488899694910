import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { get as getMarriage } from "../../../actions/marriage";
import { get as getPurchase } from "../../../actions/purchase";
import { send as sendPageSetting } from "../../../actions/page";

import Package from "./package";
import Costs from "./costs";

class Sections extends React.Component {

    loadData = () => {
        if (!this.props.purchase) {
            this.props.getPurchase();
        }
        if (!this.props.marriage) {
            this.props.getMarriage();
        }
        this.props.sendPageSetting({ context: "understand-the-dashboard" });
    }

    componentDidUpdate = (oldProps) => {
    }

    componentDidMount = async () => {
        this.loadData();
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="6">
                        <Package purchase={this.props.purchase} history={this.props.history} match={this.props.match} />
                    </Col>
                    <Col md="6">
                        <Costs marriage={ this.props.marriage } costs={this.props.costs} history={this.props.history} match={this.props.match} />
                    </Col>
                </Row>
            </>
        )
    }
}

Sections.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    marriage: PropTypes.object,
    purchase: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        marriage: state.matter.marriage,
        costs: state.matter.costs,
        purchase: state.purchase.purchase
    });
};

export default connect(mapStateToProps, { getMarriage, getPurchase, sendPageSetting })(Sections);