import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { confirm as confirmPurchase } from "../../../actions/addon";

import '../Style.css';

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.mounting = true;
    }

    async componentDidMount() {
        setTimeout(async () => {
            if (!this.mounting) return;
            this.mounting = false;

            const queryParams = queryString.parse(window.location.search)
            const intent = queryParams["payment_intent"];
            const data = await this.props.confirmPurchase(this.props.match.params.purchase, intent);
            this.setState({
                email: data.email,
                intent,
                complete: data.complete
            });
            this.props.history.push(queryParams["return_url"] ? queryParams["return_url"] : "/dashboard");
        }, 30);
    }


    render() {
        return <></>;
    }
}


export default connect(null, { confirmPurchase })(Step);