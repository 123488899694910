
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { connect } from "react-redux";

import { intent as getIntent, pending as pendingPayment } from "../../actions/purchase";
import { CheckoutForm } from "./Checkout";
import { key } from "../../constants/stripe";

const stripePromise = loadStripe(key);

class Step extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: "",
            step: 0,
            secret: "",
            intent: null
        };
        this.pending = false;
    }

    get discount() {
        return this.props.discount?.style === "P" ? this.props.product.price * this.props.discount.amount * 0.01 : (this.props.discount?.amount || 0);
    }

    get price() {
        return this.props.product.price - this.discount;
    }

    get platform() {
        return this.props.discount?.platform?.style === "P"
            ? this.props.product.fee - this.discount * this.props.discount?.platform.amount
            : this.props.product.fee - (this.props.discount?.platform?.amount || 0);
    }

    get attorney() {
        return this.price - this.platform;
    }

    get formattedPrice() {
        try {
            return Math.floor(this.price * 100);
        } catch (ex) {
            return 0
        }
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
        await this.componentDidUpdate();
    }

    async componentDidUpdate() {
        if (!this.state.intent && this.props.display && !this.pending && !isNaN(this.price) && this.price > 0) {
            this.pending = true;
            const intent = await this.props.getIntent(this.props.for?.email, this.price);
            const payment = await this.props.pendingPayment(this.props.for, this.props.product, this.props.discount, intent);
            this.setState({
                ...this.state,
                intent,
                payment
            });
            this.pending = false;
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ ...this.state, navClass: "nav-sticky", imglight: false });
        } else {
            this.setState({ ...this.state, navClass: "", imglight: true });
        }
    };

    render() {
        if (((!this.props.display) || (!this.state.intent) || (isNaN(this.price)) || (this.price === 0))) return <></>;

        return (
            <Elements options={{ mode: "payment", currency: "usd", amount: this.formattedPrice, appearance: { theme: "stripe" } }} stripe={stripePromise}>
                <CheckoutForm
                    discount={this.props?.discount}
                    product={this.props?.product}
                    for={this.props?.for}
                    intent={this.state.intent}
                    payment={this.state.payment}
                    events={this.props.events}
                    labels={{ back: "Agreement" }}
                    show={{ back: true }}
                />
            </Elements>

        );
    }
}

export default connect(null, { getIntent, pendingPayment })(Step);