import {
  GET_PROFILE_DATA
} from "../actions/types";

const initialState = {
  profile: null,
  error: {},
};

function licenseeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        profile: {
          ...payload
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default licenseeReducer;
