import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { icon as getIcon } from "../../constants/menu";

import SidebarMenuItems from "./items";
import ItemLabel from "./label";

class item extends React.Component {
    constructor(props) {
        super(props);
    }

    get page() {
        return this.props.item;
    }

    get pathname() {
        return window.location.pathname;
    }
    
    get active() {
        return this.page.to.startsWith('#') ? false : this.pathname === this.page.to || this.pathname.indexOf(`${this.page.to}/`) > -1;
    }

    get fields() {
        
        if ((this.page.id === "my_profile") && (this.props.auth.user.uuid === this.props.marriage.plaintiffUuid)) 
            return "plaintiffLockDate";
        
        if ((this.page.id === "my_profile") && (this.props.auth.user.uuid === this.props.marriage.defendantUuid))
            return "defendantLockDate";
        
        if ((this.page.id === "spouse_profile") && (this.props.auth.user.uuid === this.props.marriage.plaintiffUuid))
            return "defendantLockDate";
        
        if ((this.page.id === "spouse_profile") && (this.props.auth.user.uuid === this.props.marriage.defendantUuid))
            return "plaintiffLockDate";

        return this.page.fields?.lock;
    }

    get iconColor() {
        return getIcon(this.page, this.props.marriage, this.fields, "locked") === this.page.icons.default ? null : "#136F63";
    }

    render() {

        /*
        if (this.page.contains && !this.page.contains?.hide) {
            return (
                <li>
                    <NavLink to={this.page.to} data-bs-target={this.page.to}>
                        <ItemLabel icon={this.page.icon} caption={this.page.title} />
                    </NavLink>
                    <ul>
                        <SidebarMenuItems items={this.page.contains} />
                    </ul>
                </li>
            );
        }
        */
        
        if (this.page.target) {
            return (
                <li key={this.page.id}>
                    <a href={this.page.to} target={this.page.target} rel="noopener noreferrer">
                        <ItemLabel iconColor={ this.iconColor } icon={getIcon(this.page, this.props.marriage, this.fields, "locked")} caption={this.page.title} />
                    </a>
                </li>
            );
        }
        return (
            <li>
                <NavLink to={this.page.to} activeClassName="">
                    <ItemLabel iconColor={ this.iconColor } icon={getIcon(this.page, this.props.marriage, this.fields, "locked")} caption={this.page.title} />
                </NavLink>
            </li>
        );
    }
};


item.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.licensee.profile,
    self: state.matter.self,
    spouse: state.matter.spouse,
    marriage: state.matter.marriage
  };
};

export default connect(mapStateToProps)(item);
