import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { deepEqual } from "../../utils/object";

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            uuid: this.props.uuid || uuidv4()
        }
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps, this.props)) {
            this.setState({
                ...this.props
            });
        }
    }

    sendOnChange = (e) => {
        if (this.props.onChange) {
            let __data_element = { ...this.state };
            delete __data_element["onChange"];
            this.props.onChange(__data_element);
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.sendOnChange);
        
    }

    render() {
        return (
            <>
                <Row className={this.props.rowClassName}>
                    <Col lg="6" md="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.institution || ""} type="text" name="institution" onChange={this.onChange} />
                            <Form.Label>FINANCIAL INSTITUTION</Form.Label>
                        </div>
                    </Col>
                    <Col lg="6" md="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.account || ""} type="text" name="account" onChange={this.onChange} />
                            <Form.Label>ACCOUNT #</Form.Label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Editor;