import React, {useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";

import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Package from "./Package";

export function CheckoutForm({ intent, for: whose, events, uuid, discount, product, payment }) {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(whose?.email || "");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    await elements.submit();

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: intent.secret,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/purchase/c/${payment.uuid}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    setSubmitted(true);
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setLoading(false);
  }

  const onForward = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = intent?.secret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Please select a payment method.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  
  return (
    <form id="payment-form" onSubmit={handleSubmit} ref={formRef}>
      <div
        style={{
          backgroundColor: "#f0f0f0",
          width: "100%",
          position: "relative",
          paddingTop: "20px",
          paddingLeft: "3rem",
          display: "flex",
          WebkitBoxAlign: "center",
          minHeight: "10rem",
        }}
      >
        <Row
          className="justify-content-center"
          style={{ width: "100%", padding: "1.5rem" }}
        >
          <Col md="6">
            <Row style={{ marginTop: "20px" }}>
              <Col md="12" style={{ marginBottom: "32px" }}>
                <LinkAuthenticationElement
                  id="link-authentication-element"
                  onChange={(e) => setEmail(e.target?.value || email)}
                  options={
                    {
                      defaultValues: {
                        email: whose?.email,
                        billingDetails: {
                          name: `${whose?.firstName} ${whose?.lastName}`,
                          phone: whose?.phone,
                          address: {
                            postal_code: whose?.zipCode,
                            country: "US"
                          }
                        }
                      }
                    }
                  }
                /></Col>
              <Col md="12">
                <PaymentElement id="payment-element" options={{
                  layout: "tabs",
                  defaultValues: {
                    email: whose?.email,
                    billingDetails: {
                      name: `${whose?.firstName} ${whose?.lastName}`,
                      phone: whose?.phone,
                      address: {
                        postal_code: whose?.zipCode,
                        country: "US"
                      }
                    }
                  }
                }} />
              </Col>
              <Col md="12" style={{ marginTop: "32px" }}>
                {message && <div id="payment-message">{message}</div>}
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Package
              show={{ forward: true, back: true, discount: false }}
              labels={{ forward: "Pay Now", back: "Agreements" }}
              discount={discount}
              product={product}
              onForward={onForward}
              onBack={events.onBack}
            />
          </Col>
          {/* Show any error or success messages */}
        
        </Row>
      </div>
    </form>
  )
}


export default CheckoutForm;