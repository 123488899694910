import React from "react";
import { deepEqual } from "../../../utils/object";
import Select from 'react-select';

export function exists(value) {
    return CostTypesArray.filter(obj => { return obj.Abbr === value });
}

export function label(value) {
    return CostTypesArray.find(obj => { return obj.Abbr === value })?.Full;
}

export const CostTypesArray = [
  { Abbr: "OT", Full: "Other" },
  { Abbr: "CF", Full: "Court filing fees" },
  { Abbr: "SF", Full: "Service fees" },
  { Abbr: "EL", Full: "Electronic payment fees" }
];

const CostTypesData = (Full = true) => {
    const result = [];
    CostTypesArray.forEach((item) => {
        result.push({
            value: item.Abbr,
            label: Full ? item.Full : item.Abbr
        });
    });
    return result;
}

class CostTypesSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={CostTypesData(this.props.full).filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={CostTypesData(this.props.full)}
                disabled={this.props.disabled}
                styles={{height: "52px"}}
                style={{height: "52px"}}
            >
            </Select>
        );
    }
};

export default CostTypesSelect;
