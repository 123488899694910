
import api from "../../../utils/api";
import { isGuid } from "../../../utils/string";
import { deepEqual } from "../../../utils/object";
import { JavaScripttoXML } from "../../../utils/serialize";
import { exclude } from "../../../utils/array";

const XSL_HEADER = `<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform" xmlns:xml="http://www.w3.org/XML/1998/namespace">`;
const XSL_OUTPUT = `<xsl:output method="xml" indent="no" encoding="UTF-8"/>`;
const XSL_TEMPLATE_TAG_OPEN = `<xsl:template match="/">`;
const XSL_TEMPLATE_TAG_CLOSE = `</xsl:template>`;
const XSL_FOOTER = `</xsl:stylesheet>`;

export class report {
    static get flags() {
        return [
            {
                area: "U",
                code: null,
                bitmask: Math.pow(2, 0)
            },
            { 
                area: "A",
                code: "NA",
                bitmask: Math.pow(2, 1)
            },
            { 
                area: "P",
                code: null,
                bitmask: Math.pow(2, 2)
            },
            { 
                area: "K",
                code: null,
                bitmask: Math.pow(2, 3)
            },
            { 
                area: "P",
                code: "RE",
                bitmask: Math.pow(2, 4)
            },
            { 
                area: "P",
                code: "BK",
                bitmask: Math.pow(2, 5)
            },
            { 
                area: "P",
                code: "RA",
                bitmask: Math.pow(2, 6)
            },
            { 
                area: "P",
                code: "BU",
                bitmask: Math.pow(2, 7)
            },
            { 
                area: "P",
                code: "PE",
                bitmask: Math.pow(2, 8)
            },
            { 
                area: "P",
                code: "IP",
                bitmask: Math.pow(2, 9)
            },
            { 
                area: "P",
                code: "VE",
                bitmask: Math.pow(2, 10)
            },
            { 
                area: "P",
                code: "BO",
                bitmask: Math.pow(2, 11)
            },
            { 
                area: "P",
                code: "AI",
                bitmask: Math.pow(2, 12)
            },
            { 
                area: "P",
                code: "OT",
                bitmask: Math.pow(2, 13)
            },
            { 
                area: "U",
                code: null,
                bitmask: Math.pow(2, 14)
            },
            { 
                area: "U",
                code: null,
                bitmask: Math.pow(2, 15)
            },
            { 
                area: "D",
                code: "CR",
                bitmask: Math.pow(2, 16)
            },
            { 
                area: "D",
                code: "PL",
                bitmask: Math.pow(2, 17)
            },
            { 
                area: "D",
                code: "MO",
                bitmask: Math.pow(2, 18)
            },
            { 
                area: "D",
                code: "VF",
                bitmask: Math.pow(2, 19)
            },
            { 
                area: "D",
                code: "SL",
                bitmask: Math.pow(2, 20)
            },
            { 
                area: "D",
                code: "TX",
                bitmask: Math.pow(2, 21)
            }
        ];
    } 

    formattedXsl = (xsl) => {
        return XSL_HEADER + XSL_OUTPUT + this.templates + XSL_TEMPLATE_TAG_OPEN + this.header + xsl + XSL_TEMPLATE_TAG_CLOSE + XSL_FOOTER;
    }

    transformOne = async (xml, xsl) => {
        const __xsl = this.formattedXsl(xsl);
        const resSubject = await api.post("/renderer/transform", {
            xml: xml,
            xsl: __xsl,
        });
        return resSubject.data.result;
    }

    transformAsync = async (xml, xsl, option) => {
        const __xsl = this.formattedXsl(xsl);
        return api.post("/renderer/transform", {
            xml: xml,
            xsl: __xsl,
        }).then(response => {
            option.transformed = response.data.result;
            this.rendered++;
            this.onStatus(this.rendered, this.count);
        })
    }

    transformAll = async (array) => {
        
        return api.post("/renderer/transform/all", {
            xsl: {
                header: XSL_HEADER,
                output: XSL_OUTPUT,
                templates: this.templates || "",
                open: XSL_TEMPLATE_TAG_OPEN,
                prefix: this.header,
                close: XSL_TEMPLATE_TAG_CLOSE,
                footer: XSL_FOOTER
            },
            items: exclude(array, "option")
        }).then(response => {
            const __array = response.data; 
            for (let i = 0; i < __array.length; i++) {
                const element = __array[i];
                array[i].option.transformed = element.result;
                this.rendered++;
                this.onStatus(this.rendered, this.count);
            }
        })
    }


    transform = async (provisions = null) => {
        if (this.pending) return;

        this.pending = true;
        let i = 0;
        const xml = this.onGetXml();
        const indexOf = xml.indexOf("<data>");
        this.onStatus(0, this.count);
        const promises = [];
        const items = [];
        this.rendered = 0;
        const working = provisions || this.provisions;

        while (i < working.length) {
            let j = 0;
            while (j < working[i].options.length) {
                const option = working[i].options[j];
                let __xml = xml;
                if (option.meta) {
                    __xml = xml.substring(0, indexOf + 6) + JavaScripttoXML(option.meta, false) + xml.substring(indexOf + 6);
                }

                if (isGuid(working[i].subject)) {
                    __xml = __xml.substring(0, indexOf + 6) + `<subjectUuid>${working[i].subject}</subjectUuid>` + __xml.substring(indexOf + 6);
                }    

                if (option.transform) {
                    //promises.push(this.transformAsync(__xml, option.html, option));
                    items.push(
                        {
                            xml: __xml,
                            xsl: option.html,
                            option
                        })
                } else {
                    option.transformed = option.html;
                }
                j++;
            }
            i++;
        }
        this.transformAll(items).then(this.onComplete);
        this.pending = false;
        //Promise.allSettled(promises).then(this.onComplete);
        //this.onComplete();
    }

    chats(index, has) {
        const provision = this.provisions.find(item => (item.index === index));
        if (provision) {
            provision.chats = has;
        }
    }

    select(index, uuid, meta) {
        const provision = this.provisions.find(item => (item.index === index));
        if (provision) {
            this.count -= provision.options.length;
            this.count++;
            const option = provision.options.find(item => item.uuid === uuid);
            option.meta = meta;
            provision.options = [{ ...option }];
        }
        this.transform([provision]);
    }

    unselect(index) {
        const provision = this.provisions.find(item => (item.index === index));
        if (provision) {
            this.count--;
            const __source = this.available.filter(item => item.Provision.index === index);
            for (let i = 0; i < __source.length; i++) {
                const element = __source[i];
                provision.options.push({
                    ...element.Provision,
                    uuid: element.uuid
                });
                this.count++;
            }
            this.transform([provision]);
        }
    }

    suggest(index, uuid, meta, suggester) {
        const provision = this.provisions.find(item => (item.index === index));
        const working = {
            suggester: {
                object: null,
                index: -1
            },
            option: {
                object: provision.options.find(item => (item.suggesters?.find(internal => internal.uuid === suggester.uuid))),
                index: -1
            },
            matched: {
                object: provision.options.find(item => item.uuid === uuid && item.suggesters && deepEqual(item.meta, meta)),
                index: -1
            },
            suggested: {
                object: provision.options.find(item => item.uuid === uuid && !item.suggesters),
                index: -1
            }
        };

        if (working.option.object) {
            if ((working.option.object.uuid === uuid) && (deepEqual(working.option.object.meta, meta))) return;
            if (isGuid(working.option.object.uuid)) {
                working.option.index = provision.options.indexOf(working.option.object);
                working.suggester.object = working.option.object.suggesters.find(item => item.uuid === suggester.uuid);
                working.suggester.index = working.option.object.suggesters.indexOf(working.suggester.object);
                working.option.object.suggesters.splice(working.suggester.index, 1);
                this.count--;
                if (working.option.object.suggesters.length === 0) {
                    provision.options.splice(working.option.index, 1);
                }
            }
        }
        
        if (working.matched.object) {
            working.matched.object.suggesters.push({ ...suggester });
        } else {
            const suggested = { ...working.suggested.object, meta, suggesters: [{...suggester}] };
            provision.options.splice(0, 0, suggested);
            this.count++;
        }
        this.transform([provision]);
    }



    constructor({ templates, available, selected, onEnabled, onGetSubjects, onGetXml, onStatus, onGetParty, onComplete }) {
        this.provisions = [];
        this.header = "";
        this.templates = templates;
        this.available = available;
        this.selected = selected;
        this.onGetSubjects = onGetSubjects;
        this.onGetXml = onGetXml;
        this.onStatus = onStatus;
        this.onGetParty = onGetParty;
        this.onComplete = onComplete;
        this.onEnabled = onEnabled;
        this.count = 0;
        this.pending = false;

        let __index = -1;
        let __subjects = [];
        let i = 0;
        let __continue = false;

        // first add all the available provisions
        while (i < available.length) {
            const element = available[i];
            if (element.Provision.index === 0) {
                this.header = element.Provision.html;
                i++;
                continue;
            }

            if (element.Provision.index !== __index) {
                __subjects = [];
                __continue = false;

                for (let j = 2; j < report.flags.length; j++) {
                    const flag = report.flags[j];
                    const __flag = Math.abs(element.Provision.required);
                    const __negative = __flag !== element.Provision.required;

                    if (flag.bitmask & element.Provision.flags) {
                        __continue = !onEnabled(flag);
                    }

                    if (flag.bitmask & __flag) {
                        const ___subjects = onGetSubjects(flag);
                        __continue |= (Boolean(__negative) === Boolean(___subjects?.length));
                        __subjects.push(...___subjects);
                    }                    
                }

                if (__continue) {
                    i++;
                    continue;
                }

                this.provisions.push({
                    index: element.Provision.index,
                    flags: element.Provision.flags,
                    repeats: element.Provision.repeats,
                    subjects: [...__subjects],
                    subject: null,
                    options: []
                });
                
                __index = element.index;
            }

            let __provision = this.provisions.find(item => (item.index === element.Provision.index));
            if (__provision) {
                __provision.options.push({
                    ...element.Provision,
                    uuid: element.uuid
                });
                this.count++;
            }
            i++;
        }

        i = 0;
        // everything is added... now expand for each subject
        while ((i < this.provisions.length)){
            const element = this.provisions[i];

            if ((element.options?.length || 0) === 0) {
                this.provisions.splice(i, 1);
                continue;
            }

            if ((element.subjects.length === 0) || (element.repeats === 0)) {
                i++;
                continue;
            }
            element.subject = element.subjects.pop();

            while (element.subjects.length > 0) {
                let item = JSON.parse(JSON.stringify(element));
                delete item.subjects;
                this.count += element.subjects.length ? item.options.length : 0;
                item.subject = element.subjects.pop();
                item.subjects = [];
                i++;
                this.provisions.splice(i, 0, item);
            }
            i++;
        }

        i = 0;
        while (i < selected.length) {
            const element = selected[i];
            const provision = isGuid(element.subjectUuid) ? this.provisions.find(item => (item.index === element.index) && (item.subject === element.subjectUuid)) : this.provisions.find(item => item.index === element.index);
            const show = { plaintiff: true, defendant: true };

            if (!provision) {
                i++;
                continue;
            }

            const meta = JSON.parse(element.meta);

            provision.escalated = ((element.escalatedDate) && (!element.resolvedDate)); 
            provision.chats = Boolean(element.conversation);

            if (isGuid(element.selectedProvisionUuid)) {
                const option = provision.options.find(item => item.uuid === element.selectedProvisionUuid);
                option.meta = meta.selected;
                this.count -= provision.options.length;
                this.count++;
                provision.options = [{ ...option }];
                i++;
                continue;
            }

            if (isGuid(element.suggestedProvisionUuid)) {
                const option = provision.options.find(item => item.uuid === element.suggestedProvisionUuid);
                const suggested = { ...option };
                suggested.meta = meta.suggested;
                suggested.suggesters = [];
                suggested.suggesters.push({ uuid: onGetParty("Employee").uuid, name: "Counsel", by: "Counsel" });

                if ((element.plaintiffProvisionUuid === element.suggestedProvisionUuid) && deepEqual(meta.plaintiff, meta.suggested)) {
                    const plaintiff = onGetParty("Plaintiff");
                    suggested.suggesters.push({ uuid: plaintiff.uuid, name: plaintiff.firstName, by: "Plaintiff" });
                    show.plaintiff = false;
                }

                if ((element.defendantProvisionUuid === element.suggestedProvisionUuid) && deepEqual(meta.defendant, meta.suggested)) {
                    const defendant = onGetParty("Defendant");
                    suggested.suggesters.push({ uuid: defendant.uuid, name: defendant.firstName, by: "Defendant" });
                    show.defendant = false;
                }
                provision.options.splice(0, 0, suggested);
                this.count++;
            }

            if (show.plaintiff && isGuid(element.plaintiffProvisionUuid)) {
                const option = provision.options.find(item => item.uuid === element.plaintiffProvisionUuid);
                const suggested = { ...option };
                const plaintiff = onGetParty("Plaintiff");
                suggested.meta = meta.plaintiff;
                suggested.suggesters = [];
                suggested.suggesters.push({ uuid: plaintiff.uuid, name: plaintiff.firstName, by: "Plaintiff" });

                if ((element.defendantProvisionUuid === element.plaintiffProvisionUuid) && deepEqual(meta.defendant, meta.plaintiff)) {
                    const defendant = onGetParty("Defendant");
                    suggested.suggesters.push({ uuid: defendant.uuid, name: defendant.firstName, by: "Defendant" });
                    show.defendant = false;
                }
                provision.options.splice(0, 0, suggested);
                this.count++;
            }

            if (show.defendant && isGuid(element.defendantProvisionUuid)) {
                const option = provision.options.find(item => item.uuid === element.defendantProvisionUuid);
                const suggested = { ...option };
                const defendant = onGetParty("Defendant");
                suggested.meta = meta.defendant;
                suggested.suggesters = [];
                suggested.suggesters.push({ uuid: defendant.uuid, name: defendant.firstName, by: "Defendant" });
                provision.options.splice(0, 0, suggested);
                this.count++;
            }

            i++;
        }




    }


}