import React from "react";
import { connect } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { 
    Tab, 
    Tabs, 
    TabList, 
    TabPanel 
} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { agreements as getAgreements } from "../../actions/product";

import './Style.css';
const Package = React.lazy(() => import("./Package"));

class Step extends React.Component {
  
    constructor(props) {
        super(props);
        this.onForward = this.onForward.bind(this);
        this.onBack = this.onBack.bind(this);
        let __agreements = props?.agreements ? props.agreements : [];

        this.state = {
            selectedIndex: 0,
            agreements: [
                ...__agreements
            ],
        };
        this.validated = false;
        this.formRef = React.createRef();
    }

    async DoPrePurchase(item) {
        return await this.props.addPurchase(item);
    }

    handleDiscountChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    get price() {
        let _discount =
            this.props.discount.style === "P"
                ? this.props.product.price * this.props.discount.amount * 0.01
                : this.props.discount.amount;
        return this.props.product.price - _discount;
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        let _valid = form.checkValidity();

        this.state.agreements.every((item, idx) => {
            _valid &= item.agreed;
            if (!_valid) {
                this.setState({
                    ...this.state,
                    selectedIndex: idx,
                    ready: _valid,
                });
                return false;
            }
            return true;
        });

        if (_valid) {
            this.setState({
                ...this.state,
                ready: _valid,
            });
        }
        this.validated = true;

        if (!_valid) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (this.props.onForward) this.props.onForward(this.state);
        }
    };

    changeTarget = (e) => {
        this.setState({
            ...this.state,
            target: e.target.value,
        });
    };

    onForward = () => {   
        this.formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
        return this.validated;
    }

    onBack = () => {   
        if (this.props.onBack) this.props.onBack(this.state);
        return this.validated;
    }

    onSelect = index => {
        this.setState({
            ...this.state,
            selectedIndex: index,
        })
    }

    componentDidMount = (e) => {
        this.getContent();
    }

    componentDidUpdate = (oldProps) => {
       // console.log(this.props);
    }

    get flags() {
        return this.props?.for?.target === "S" ? 2 : this.props?.for?.target === "O" ? 1 : 0;
    }


    async getContent() {
        const agreements = await this.props.getAgreements(this.props.product.uuid, this.flags);
        this.setState({
            ...this.state,
            agreements
        });
    }

    DoAgree = (e) => {
        let __agreements = this.state.agreements;
        let __agreement = __agreements.find(element => element.uuid === e.target.value);
        __agreement.agreed = e.target.checked;
        __agreement.agreedDate = e.target.checked ? new Date() : null;

        this.setState({
            ...this.state,
            agreements: __agreements
        })
    }

    content = (item) => {
        return (
            <div>
                <div key={`cc${item.uuid}`} style={{ height: "300px", overflowY: "auto" }} className="col-12 mb-4" dangerouslySetInnerHTML={{ __html: item.content }} />
                <div>
                <Form.Check
                    inline
                    type="checkbox"
                    id={`chkAgree_${item.uuid}`}
                    label={`Agree to ${item.title}`}
                    name={`chkAgree_${item.uuid}`}
                    value={item.uuid}
                    checked={Boolean(item.agreed)}
                        onChange={this.DoAgree}
                        style={{ position: "relative", top: "-15px" }}
                        className="agreement-check"
                /></div>
            </div>
        )
    }

    tabs = () => {
        return this.state.agreements.map((item) => {
            return <Tab key={`tab${item.uuid}`}>{item.title}</Tab>
        });
    }

    panels = () => {
        return this.state.agreements.map((item) => {
            return <TabPanel key={`tp${item.uuid}`}>{this.content(item)}</TabPanel>
        });
    }

    render() {
        if (!this.props.display) return <></>;

        return (
            <div
                style={{
                    backgroundColor: "#f0f0f0",
                    width: "100%",
                    position: "relative",
                    paddingTop: "20px",
                    paddingLeft: "3rem",
                    display: "flex",
                    WebkitBoxAlign: "center",
                    minHeight: "10rem",
                }}
            >
                <Row
                    className="justify-content-center"
                    style={{ width: "100%", padding: "1.5rem" }}
                >
                    <Col md={6}>
                        <div
                            style={{
                                padding: "32px",
                                background: "#fff",
                                margin: "0px",
                                borderRadius: "8px",
                                animation: "transition-in .25s ease-out forwards",
                            }}
                        >
                            <Form
                                noValidate
                                validated={this.validated}
                                className="form-floating"
                                onSubmit={this.handleSubmit}
                                ref={this.formRef}
                            >
                                <Tabs id="tabsAgreement" className="mb-12" selectedIndex={this.state.selectedIndex} onSelect={this.onSelect}>
                                    <TabList>{this.tabs()}</TabList>
                                    {this.panels()}
                                </Tabs>

                            </Form>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Package
                            show={{ forward: true, back: true, discount: true }}
                            labels={{ forward: "Payment", back: "Information" }}
                            discount={this.props?.discount}
                            product={this.props.product}
                            onChange={this.handleDiscountChange}
                            onForward={this.onForward}
                            onBack={this.onBack}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(null, { getAgreements })(Step);