import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Col, Form, Row, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import moment from "moment";

import { pack as setAlerts } from "../../../actions/alert";
import { update as setChat, updates as getUpdates } from "../../../actions/chat";
import { escalate } from "../../../actions/contract";

import CsLineIcons from "../../../cs-line-icons";
import Speaker from "./speaker";
import Messages from "./messages";
import DraftingModal from "../../../layout/modal";

import '../../../css/chat.css';

class ChatViewer extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            pending: ""
        }
        this.chatters = {};
    }

    selectByIndex = async () => {
        this.componentWillUnmount();
        const selected = await this.props.getUpdates(this.props.index, moment().subtract(730, 'days').toDate());
        this.setState({
            ...this.state,
            selected: selected ? JSON.parse(selected.conversation) : null,
            escalated: ((selected.escalatedDate) && (!selected.resolvedDate)),
            lastCheck: moment().subtract(5, 'seconds').toDate()
        });
        this.waitToUpdate();
    }

    getDisplayName = (uuid) => {
        if (!this.chatters[uuid]) {
            this.chatters[uuid] = this.props.onGetDisplay(uuid);
        } 
        return this.chatters[uuid];
    }

    renderSelected = () => {
        let __uuid = "";

        return (
            //<div className="mb-2 imessage"> {
            this.state.selected?.map((item, index, array) => {
                if (item.postedByUuid !== __uuid) {
                    __uuid = item.postedByUuid;
                } else {
                    return null;
                }
                return (
                    <Fragment key={`chat_${index}`}>
                        <Speaker name={this.getDisplayName(__uuid)} />
                        <div className="mb-2 imessage">
                            <Messages messages={array.slice(index)} uuid={__uuid} />
                        </div>
                    </Fragment>
                )
            })
        )
    }

    checkForUpdates = async () => {
        if (this.props.index === -1) return;
        
        const lastCheck = this.state?.lastCheck || moment().subtract(730, 'days').toDate();

        const updates = await this.props.getUpdates(this.props.index, lastCheck);
        if (updates) {
            this.setState({
                ...this.state,
                selected: updates ? JSON.parse(updates.conversation) : null,
                escalated: ((updates.escalatedDate) && (!updates.resolvedDate)),
                lastCheck: moment().subtract(4, 'seconds').toDate()
            });
        }
        this.waitToUpdate();
    }

    waitToUpdate = () => {
        this.timeout = setTimeout(() => {
            this.checkForUpdates();
        }, 5000);
    }

    componentWillUnmount = () => {
        try {
            if (this.timeout) clearTimeout(this.timeout)
        } catch { }
    }

    componentDidMount = async () => {
        this.waitToUpdate();
    }

    componentDidUpdate = async (oldProps) => {
        if (this.props.index !== oldProps.index) {
            this.selectByIndex();
        }
    }

    onTextChange = (e) => {
        this.setState({
            ...this.state,
            pending: e.target.value
        });
    }

    onSend = async (e) => {
        const __value = this.state.pending;
        if (__value.length >= 1) {
            await this.props.setChat(this.props.index, this.props.auth.user.uuid, __value);
            await this.selectByIndex();

            this.setState({
                ...this.state,
                pending: ""
            });

        }
    }

    onKeyDown = (e) => {
        if (e.key === "Enter") {
            this.onSend();
        }
    }

    onSubmit = () => {
        this.setState({
            ...this.state,
            showDrafting: true
        })
    }

    doHideDrafting = () => {
        this.setState({
            ...this.state,
            showDrafting: false
        })        
    }

    doEscalate = () => {
        this.props.escalate(this.props.marriageUuid, this.props.contractUuid, this.props.index);
        this.doHideDrafting();
    }

    render()
    {
        if (!this.props.enabled) {
            return <></>
        }
        return (
            <Row style={{ minHeight: "10vh", background: "linear-gradient(to right, #f9f9f9 80%, #f0f0f0)" }}>
                { this.renderSelected() }
                <Col xs="12" style={{minHeight: "10vh", paddingTop: "1em"}}>
                    <Form.Control
                        onChange={this.onTextChange}
                        onKeyDown={this.onKeyDown}
                        placeholder="Text to Send"
                        value={this.state.pending}
                        style={{ marginRight: "3px", width: `calc(100% - ${this.state.escalated ? "45" : "140"}px)`, display: "inline" }}
                    />
                    <Button style={{ position: "relative", left: "3px" }} className="btn-icon btn-icon-only mb-1 btn btn-primary" onClick={this.onSend}><CsLineIcons icon="send" /></Button>
                    {this.state.escalated ? `` : (
                        <Button style={{ position: "relative", left: "6px" }} title="Submit to the attorney for custom drafting" className="btn-icon mb-1 btn btn-primary" onClick={this.onSubmit}><CsLineIcons icon="pen" /> Draft</Button>
                    )}
                </Col>
                <DraftingModal show={this.state.showDrafting} title="Confirm Submission to Attorney" body="Click the Ok button to submit this provision and all the comments to an attorney for custom drafting services. This may consume a drafting credit." buttons={
                    [
                        {
                            default: true,
                            onClick: this.doHideDrafting,
                            caption: "Cancel"
                        },
                        {
                            onClick: this.doEscalate,
                            caption: "OK"
                        }
                    ]} />
            </Row>
        )
    }

}

ChatViewer.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { setAlerts, setChat, getUpdates, escalate })(ChatViewer);