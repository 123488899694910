import React from "react";
import { connect } from "react-redux";
import {  Card, Row, Col } from 'react-bootstrap';
import { toDate } from "../../utils/date";
import CsLineIcons from '../../cs-line-icons';

class Item extends React.Component {

    get genderColor() {
        switch (this.props.genderCode) {
            case "F":
                return "pink";
            case "M":
                return "skyblue";
            case "O":
            case "UN":
            default:
                return "blueviolet";
        }        
    }

    get genderImage() {
        switch (this.props.genderCode) {
            case "F":
                return "igender-FEMALE";
            case "M":
                return "igender-MALE";
            case "O":
            case "UN":
            default:
                return "igender-AGENDER";
        }
    }

    doOpen = () => {
        if (this.props.onOpen) {
            this.props.onOpen(this.props.uuid);
        }
    }

    render() {
        return (
            <Col md="4" sm="6" lg="4" xl="3" xxl="2" xs="12">
                <Card className="mb-5" role="button" onDoubleClick={this.doOpen}>
                    <Card.Body>
                        <i className={`card-img-top ${this.genderImage}`} style={{ fontColor: this.genderColor, fontSize: "60px" }} />
                        <Card.Title style={{marginTop: "10px"}}>{this.props.firstName} {this.props.lastName}</Card.Title>
                        <Card.Text>Born: {toDate(this.props.birthDate).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric" })}</Card.Text>
                        <Card.Text>
                            <small className="text-muted">Last updated <br/>{toDate(this.props.updatedAt).toLocaleDateString("en-us", { month: "short", year: "numeric", day: "numeric", hour: "numeric", minute: "numeric"})}</small>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export default connect()(Item);