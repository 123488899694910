import React, { Suspense } from 'react';
import { connect } from "react-redux";
import queryString from "query-string";

import WizardPage from '../../layout/Wizard';
import Header from '../../layout/header';
import { withLoader } from "../../layout/loader";

import For from './For';
import { Toaster } from 'react-hot-toast';
import { get as getProduct } from "../../actions/product";
import Agreement from './Agreement';
import Payment from "./Payment";
import Complete from "./Complete";

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step || 0,
      product: {}
    }
  }

  ///:startat/:product/:base
  get startAt() {
    return this.props.match.params.startat;
  }

  get productUuid() {
    return this.props.match.params.product;
  }
  
  get baseUuid() {
    return this.props.match.params.base;
  }

  get returnUrl() {
    const queryParams = queryString.parse(window.location.search)
    return queryParams["return_url"];
  }

  get caption() {
    switch (this.startAt) {
      case "c":
        switch (this.state.step) {
          case 0:
            return "Thank You.";
          default:
            break;
        }
        break;
      case "i":
        switch (this.state.step) {
          case 0:
            return "Let's Get Started."
          case 1:
            return "The Legal Stuff."
          case 2:
            return "Pay Now."
          default:
            break;
        }
        break;
      default:
        return "Unknown";
    }
    return "Unknown";
  }

  display = (startAt, step) => {
    return this.startAt === startAt && step === this.state?.step;
  }

  onChange = (e) =>
    this.setState({
      ...this.state,
      [e.target?.name || e.name]: e.target?.value || e.value,
    });

  componentDidUpdate(prevProps) {

  }
  
  componentDidMount() {
    setTimeout(async () => {
      const product = await this.props.getProduct(this.productUuid);
      this.setState({
        ...this.state,
        product: { ...product }
      });

    }, 30);
  }

  render() {
    return (
          
      <Suspense fallback={withLoader()}>
        <Toaster position='bottom-right' reverseOrder={false} />
        <Header title={`DivorceX | ${this.caption}`} description={`Purchase your DivorceX products and add-ons here`} profile={this.props.profile} />
        <WizardPage caption={this.caption} background={this.props.profile?.backgroundImage} profile={this.props.profile}>
          <For display={this.display("i", 0)} product={this.state?.product} onChange={this.onChange} for={this.state?.for} discount={this.state?.discount} onForward={() => { this.setState({ step: this.state.step + 1 }) }} />
          <Agreement display={this.display("i", 1)} agreements={ this.state?.agreements } product={this.state?.product} onChange={this.onChange} for={this.state?.for} discount={this.state?.discount} onForward={()=> { this.setState({ step: this.state.step + 1 })} } onBack={()=> { this.setState({ step: this.state.step - 1 })} } />
          <Payment display={this.display("i", 2)} product={this.state?.product} onChange={this.onChange} for={this.state?.for} discount={this.state?.discount} events={{ onBack: () => { this.setState({ step: this.state.step - 1 }) } }} />
          <Complete display={this.display("c", 0)} uuid={this.productUuid} onChange={this.onChange} />
        </WizardPage>
      </Suspense>
    );
  }
}



const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    profile: state.licensee.profile
  });
}

export default connect(mapStateToProps, { getProduct })(Purchase);