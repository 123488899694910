import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

export function exists(value) {
    return GenerationsData.filter(obj => { return obj.value === value });
}

export const GenerationsData = [
  { value: "", label: "N/A" },
  { value: "Sr", label: "Sr." },
  { value: "Jr", label: "Jr." },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" },
  { value: "VI", label: "VI" },
  { value: "VII", label: "VII" },
  { value: "VIII", label: "VIII" },
];



class GenerationsSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={GenerationsData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={GenerationsData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default GenerationsSelect;
