import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import { PatternFormat as NumberFormat } from 'react-number-format';
import PropTypes from "prop-types";


import { deepEqual } from "../../../../utils/object";
import { calculate } from "../../../../utils/validation";
import { format as formatDate } from "../../../../utils/date";

import DatePicker from '../../../../layout/date-picker';
import GenerationsSelect from "../../../../layout/select/generation";
import CountrySelect, { exists as CountryExists, label as CountryLabel } from "../../../../layout/select/country";
import StateSelect, { exists as StateValid, label as StateLabel } from "../../../../layout/select/state";
import OptionCard from "../../../../layout/optioncard";

import 'react-datepicker/dist/react-datepicker.css';

class Editor extends React.Component {
    static get defaultProps() {
        return {
            generation: "",
            BirthPlace: {
                country: "US",
                state: "XX"
            },
            haveSSN: true,
            genderCode: "O"
        }
    }
    
    constructor(props) {
        super(props);
        this.state = {
            ...this.defaultProps,
            ...props
        };
       
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            var __birthDate = this.state.birthDate;

            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (this.props.birthDate !== oldProps.birthDate) {
                __birthDate = new Date(this.props.birthDate); 
            }

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props,
                    birthDate: __birthDate
                });
            }

            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange);
    }

    onGenerationChange = (e) => {
        this.onChange({ target: { name: "generation", value: e.value } });
    }

    onBirthplaceChange = (e) => {
        this.setState({
            ...this.state,
            BirthPlace: {
                ...this.state.BirthPlace,
                country: e.target.value === "US" ? "US" : this.state.BirthPlace?.country !== "US" ? this.state.BirthPlace?.country : e.target.value,
                state: e.target.value !== "US" ? this.state.BirthPlace?.state : null,
            }
        }, this.sendOnChange);
    }

    onBirthDateChange = (date) => {
        this.onChange({ target: { name: "birthDate", value: date } });
    }

    onBirthplaceCountryChange = (e) => {
        if (CountryExists(e.value)) {
            this.setState({ ...this.state, BirthPlace: {...this.state.BirthPlace, country: e.value } }, this.sendOnChange);
        }
    }

    onBirthPlaceStateChange = (e) => {
        if (StateValid(e.value)) {
            this.setState({ ...this.state, BirthPlace: { ...this.state.BirthPlace, country: "US", state: e.value } }, this.sendOnChange);
        }
    }

    onHaveSSNChange = (e) => {
        if (e.target.value === "true") {
            this.onChange({ target: { name: "haveSSN", value: true } });
        } else {
            this.setState({ ...this.state, haveSSN: false, ssn: null }, this.sendOnChange);
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "CHILD", this.state));
        }
    }

    get locked() {
        return Boolean(this.state.Child?.Marriage?.childrenLockDate);
    }

    render() {
        return (
            <>
                <Row style={{paddingTop: "10px"}}>
                    <Col lg="9">
                        <Row className="g-3">
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="firstName" value={this.state.firstName || ""} onChange={this.onChange} readOnly={ this.locked } />
                                    <Form.Label>FIRST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="middleName" value={this.state.middleName || ""} onChange={this.onChange} readOnly={this.locked} />
                                    <Form.Label>MIDDLE NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="lastName" value={this.state.lastName || ""}  onChange={this.onChange} readOnly={this.locked} />
                                    <Form.Label>LAST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    {(this.locked) ?
                                        <Form.Control type="text" name="generation" value={this.state.generation || ""} readOnly={this.locked} />
                                        :
                                        <GenerationsSelect value={this.state.generation || ""} onChange={this.onGenerationChange} placeholder="" name="generation" />
                                    }
                                    <Form.Label>SUFFIX</Form.Label>
                                </div>
                            </Col>
                        </Row>
                
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Gender</h2>
                    </Col>
                </Row>
                <Row className="g-3 mb-3">
                    <Col xs="3">
                        <OptionCard name="genderCode" value="M" checked={this.state.genderCode === "M"} onChange={this.locked ? null : this.onChange} iconClassName="igender-MALE" iconClassColor="skyblue" iconFontSize="48px" caption="Male" />
                    </Col>
                    <Col xs="3">
                        <OptionCard name="genderCode" value="F" checked={this.state.genderCode === "F"} onChange={this.locked ? null : this.onChange} iconClassName="igender-FEMALE" iconClassColor="pink" iconFontSize="48px" caption="Female" />
                    </Col>
                    <Col xs="3">
                        <OptionCard name="genderCode" value="O" checked={this.state.genderCode === "O"} onChange={this.locked ? null : this.onChange} iconClassName="igender-AGENDER" iconClassColor="blueviolet" iconFontSize="48px" caption="Other" />
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Social Security #</h2>
                    </Col>
                </Row>
                <Row className="g-3 mb-3">
                    <Col lg="3">
                        <OptionCard name="haveSSN" value={true} checked={this.state.haveSSN} onChange={this.locked ? null : this.onHaveSSNChange} iconClassName="iapp-Barcode" iconClassColor="green" iconFontSize="48px" caption="Has a SSN" />
                    </Col>
                    {this.state.haveSSN ? (
                        <Col lg="2" className="mb-3 top-label">
                            {(this.locked) ?
                                <Form.Control type="text" name="ssn" value={this.state.ssn || ""} readOnly={true} />
                                :
                                <NumberFormat format="###-##-####" mask="_" className="form-control" value={this.state.ssn} allowEmptyFormatting onChange={this.onChange} name="ssn" />
                            }
                            <Form.Label>SOCIAL SECURITY #</Form.Label>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="haveSSN" value={false} checked={!this.state.haveSSN} onChange={this.locked ? null : this.onHaveSSNChange} iconClassName="iapp-Barcode-scan" iconClassColor="red" iconFontSize="48px" caption="Doesn't Have a SSN" />
                    </Col>
                    
                </Row>

                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Born ...</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="3">
                        <OptionCard name="countryCode" value="US" checked={this.state.BirthPlace?.country === "US"} onChange={this.locked ? null : this.onBirthplaceChange} iconClassName="imaps-usa-map" iconClassColor="black" iconFontSize="48px" caption="in the United States" />
                    </Col>
                    {this.state.BirthPlace?.country === "US" ? (
                        <Col lg="4">
                            <Row><Col lg="12">
                                <div className="mb-3 top-label">
                                    {(this.locked) ?
                                        <Form.Control type="text" name="birthplace.state" value={StateLabel(this.state.BirthPlace.state) || ""} readOnly={true} />
                                        :
                                        <StateSelect value={this.state.BirthPlace.state} onChange={this.onBirthPlaceStateChange} placeholder="" name="birthplace.state" />
                                    }
                                    <Form.Label>STATE OF BIRTH</Form.Label>
                                </div>
                            </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="birthDate" value={formatDate(this.state.birthDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.birthDate} onChange={this.onBirthDateChange} placeholder="" name="birthDate" />
                                        }
                                        <Form.Label>DATE OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                    <Col lg="3">
                        <OptionCard name="countryCode" value="OT" checked={this.state.BirthPlace?.country !== "US"} onChange={this.locked ? null : this.onBirthplaceChange} iconClassName="imaps-globe-earth" iconClassColor="black" iconFontSize="48px" caption="Somewhere Else" />
                    </Col>
                    {this.state.BirthPlace?.country !== "US" ? (
                        <Col lg="4">
                            <Row>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="birthplace.country" value={CountryLabel(this.state.BirthPlace?.country) || ""} readOnly={true} />
                                            :
                                            <CountrySelect value={this.state.BirthPlace?.country} onChange={this.onBirthplaceCountryChange} placeholder="" name="birthplace.country" />
                                        }
                                        <Form.Label>COUNTRY OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        {(this.locked) ?
                                            <Form.Control type="text" name="birthDate" value={formatDate(this.state.birthDate) || ""} readOnly={true} />
                                            :
                                            <DatePicker className="form-control" selected={this.state.birthDate} onChange={this.onBirthDateChange} placeholder="" name="birthDate" />
                                        }
                                        <Form.Label>DATE OF BIRTH</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) : ``}
                </Row>
            </>
        )
    }
}

Editor.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items,
    });
};

export default connect(mapStateToProps, { })(Editor);

