import React from "react";
import { Button, Modal } from 'react-bootstrap';

export const initial = {
    show: false,
    title: "",
    body: "",
    buttons: [
        {
            default: true,
            caption: "cancel",
            onClick: null,
            variant: "secondary"
        },
        {
            caption: "ok",
            onClick: null,
            variant: "outline-primary"
        }
    ]
}

class ExModal extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    get buttons() {
        return this.props.buttons.map((item, index) => {
            return <Button key={`modal_button_${index}`} variant={item.variant || "secondary"} onClick={item.onClick}>{ item.caption }</Button>
        });
    }

    onHideModal = () => {
        const __default_items = this.props.buttons.filter(item => item.default);
        __default_items.forEach((item) => {
            if (item.onClick) item.onClick();
        });
        this.setState({
            show: false
        });
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal} size={this.props.size}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body && this.props.body}
                    {this.props.children && this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    {this.buttons}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ExModal;