import { SET_PAGE_INFO } from '../actions/types';

const initialState = {
  title: null,
  caption: null,
  context: ""
};

function pageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE_INFO:
          return {
              ...state,
              ...payload
          };
    default:
      return state;
  }
}

export default pageReducer;
