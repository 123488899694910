import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";
import { wordsOrdinal } from "../../../utils/number";

class NumericSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    numericData = (minValue = 0, maxValue = 99, suffix = false) => {
        let __array =
            [

            ];
        
        for (var i = minValue; i <= maxValue; i++) {
            __array.push({
                value: i,
                label: suffix ? wordsOrdinal(i) : i
            })
        }
        return __array;
    }

    get numericOptions() {
        return this.numericData(Number(this.state.minValue || 0), Number(this.state.maxValue || 99), this.state.suffix)
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={this.numericOptions.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={this.numericOptions}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default NumericSelect;
