import React from "react";
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <CookiesProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </CookiesProvider>
  </HelmetProvider>
);

