import React from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";

import CsLineIcons from '../../cs-line-icons';
import { login } from "../../actions/auth";
import ConditionalButton from '../../layout/conditional-button';
import LayoutFullPage from '../../layout/FullPage';
import Header from '../../layout/header';
import { Toaster } from 'react-hot-toast';
import { isEmail } from '../../utils/regex';
import { development_mode } from '../../utils/api';
import { key as recaptchaKey } from '../../constants/recaptcha';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.email || "",
            password: "",
            valid: development_mode
        }
    }

    onChange = (e) =>
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    
    onCaptchaChange = (value) => {
        this.setState({
            ...this.state,
            valid: true
        })
    }
    
    onCaptchaErrored = (value) => {

    }

    onCaptchaExpired = (value) => {

    }

    onSubmit = async (e) => {
        e.preventDefault();
        await this.props.login(this.state.email, this.state.password, window.location.host, this.props.profile?.uuid);
    };

    componentDidUpdate(prevProps) {
        if (this.props.authenticated) {
            this.props.history.push("/dashboard");
        }
    }
    
    get email() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["e"] || "";
    }

    get leftSide() {
        return (
            <div className="min-h-100 d-flex align-items-center">
                <div className="w-100 w-lg-75 w-xxl-50">
                    <div>
                        <div className="mb-5">
                            <h1 className="display-3 text-white">{this.props.profile?.tagLine001}</h1>
                        </div>
                        <p className="h6 text-white lh-1-5 mb-5">
                            {this.props.profile?.tagLine002}
                        </p>
                        <div className="mb-5">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get rightSide() {
        return (
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                <div className="sw-lg-50 px-5">
                    <div className="sh-11">
                        <NavLink to="/">
                            <div className="logo-default" style={{ backgroundImage: `url(/img/l/logo/${this.props.profile?.smallLogo})` }} />
                        </NavLink>
                    </div>
                    <div className="mb-5">
                        <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
                        <h2 className="cta-1 text-primary">let's get started!</h2>
                    </div>
                    <div className="mb-5">
                        <p className="h6">Please use your credentials to login.</p>
                    </div>
                    <div>
                        <Row>
                            <Col sm="12">
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    <CsLineIcons icon="email" />
                                    <Form.Control
                                        className="form-control"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        placeholder='Email'
                                        required />
                                </div>
                            </Col><Col sm="12">
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    <CsLineIcons icon="lock-off" />
                                    <Form.Control
                                        className="form-control"
                                        type="password"
                                        placeholder=""
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        required />
                                    <NavLink className="text-small position-absolute t-3 e-3" to="/forgot">
                                        Forgot?
                                    </NavLink>
                                </div>
                            </Col><Col sm="6">
                                {development_mode ? `` :
                                    <ReCAPTCHA
                                        sitekey={recaptchaKey}
                                        onChange={this.onCaptchaChange}
                                        onExpired={this.onCaptchaExpired}
                                        onErrored={this.onCaptchaErrored}
                                        size="compact"
                                    />
                                }
                            </Col><Col sm="6">
                                <ConditionalButton enabledVariant="outline-primary" display={true} enabled={this.state.valid && isEmail(this.state.email)} onEnabledClick={this.onSubmit}>Login</ConditionalButton>
                            </Col></Row>
                    </div>
                </div>
            </div>
        );
    };


    render() {
        return (
            <>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title='Login' description='Login' />
                <LayoutFullPage left={this.leftSide} right={this.rightSide} background={this.props.profile?.backgroundImage} />
            </>
        );
    }
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { login })(Login);