import React from "react";
import { Row, Col } from "react-bootstrap";
import { isGuid } from "../../../../utils/string";
import { iif } from "../../../../utils/object";

class Costs extends React.Component
{
    constructor(props) {
        super(props);
        this.totals = {
            unpaid: 0,
            paid: 0,
            purchases: [],
            pending: ""
        }
    }

    onClickPayNow = (e) => {
        this.props.history.push(`/purchase/e/${this.totals.pending}`);
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h4>
                            Case Related Costs
                        </h4>
                    </Col>
                </Row>
                {
                    this.props.costs.map((element, index) => {
                        if (isGuid(element.Purchase?.uuid)) {
                            if (!this.totals.purchases.find(obj => { return obj === element.Purchase.uuid })) {
                                this.totals.purchases.push(element.Purchase.uuid);
                                if (Number(element.Purchase.paymentReceived) > 0) {
                                    this.totals.paid += Number(element.Purchase.paymentReceived);
                                } else {
                                    this.totals.unpaid += Number(element.amount);
                                    this.totals.pending = element.Purchase.uuid;
                                }
                            }
                        } else {
                            return <></>
                        }
                        
                        
                        return (
                            <Row key={`cost_item_idx${index}`} style={{ marginTop: "20px" }}>
                                <Col md="9"><h5>{element.description}</h5></Col>
                                <Col md="3" style={{ textAlign: "right" }}><h5>${element.amount}</h5></Col>
                            </Row>
                        )
                    })
                }
                <Row style={{ marginTop: "20px" }}>
                    <Col md="9" style={{ textAlign: "right" }}>
                        <h5>
                            {iif(this.totals.unpaid > 0, <a href="#" className="badge bg-primary" onClick={this.onClickPayNow}>Pay Now</a>, "")} Unpaid
                        </h5>
                    </Col>
                    <Col md="3" style={{ textAlign: "right" }}><h5>${this.totals.unpaid}</h5></Col>
                </Row>
                <Row>
                    <Col md="9" style={{ textAlign: "right" }}><h5>Paid</h5></Col>
                    <Col md="3" style={{ textAlign: "right" }}><h5>${this.totals.paid}</h5></Col>
                </Row>
            </>
        )
    }
}

export default Costs;