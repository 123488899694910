import React from "react";

class Speaker extends React.Component {
    render() {
        return (
            <p className="comment">{this.props.name} says:</p>
        )
    }
}

export default Speaker;