import { SET_PAGE_INFO } from './types';
import { v4 as uuidv4 } from 'uuid';

export const send = (settings) => dispatch => {
  const id = uuidv4();
  dispatch({
    type: SET_PAGE_INFO,
    payload: settings
  });
};
