import React from "react";

import CsLineIcons from "../../cs-line-icons";

class Label extends React.Component {
    render() {
        const __icon = this.props.icon;
        const __caption = this.props.caption;

        return (
            <>
                {__icon ? (<>
                    <i className={`card-img-top ${__icon}`} style={{ fontSize: "24px", color: this.props.iconColor || "var(--alternate)" }} />
                    </>
                ) : (
                    <></>
                )}
                <span className="label" style={{ fontSize: "14px", position: "relative", bottom: "6px", fontWeight: "bold" }} > {__caption}</span >
            </>
        )

    }
}

export default Label;