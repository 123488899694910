import React from "react";
import { deepEqual } from "../../../utils/object";
import Select from 'react-select';

export function exists(value) {
    return RaceData.filter(obj => { return obj.value === value });
}

export function label(value) {
    return RaceData.find(obj => { return obj.value === value })?.label;
}

export const RaceData = [
  { value: "AP", label: "Asian/Pacific Islander" },
  { value: "BL", label: "Black" },
  { value: "LA", label: "Latin(x)" },
  { value: "NA", label: "Native American" },
  { value: "WH", label: "White" },
  { value: "TM", label: "Two (or more) Races" },
  { value: "OT", label: "Other" },
];

class RaceSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={RaceData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.props.disabled ? null : this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={RaceData}
                disabled={this.props.disabled}
            >
            </Select>
        );
    }
};

export default RaceSelect;
