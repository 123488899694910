import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import queryString from "query-string";
import { Toaster } from 'react-hot-toast';

import { confirm as confirmPurchase } from "../../../actions/cost";
import Header from '../../../layout/header';
import WizardPage from '../../../layout/Wizard';

import '../Style.css';

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.mounting = true;
    }

    async componentDidMount() {
        setTimeout(async () => {
            if (!this.mounting) return;
            this.mounting = false;

            const queryParams = queryString.parse(window.location.search)
            const intent = queryParams["payment_intent"];
            const to_url = queryParams["return_url"];
            const from_email = queryParams["e"];

            if (intent) {
                const data = await this.props.confirmPurchase(this.props.match.params.purchase, intent, from_email);
                this.setState({
                    email: data.email,
                    intent,
                    complete: data.complete,
                    payment: data.payment
                });
                if (to_url) {
                    this.props.history.push(to_url);
                }
            }
        }, 30);
    }


    render() {
        return (
            <>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title={`DivorceX | Confirmation`} description={`Pay for your DivorceX costs here`} profile={this.props.profile} />
                <WizardPage caption="Payment Confirmed" background={`${this.props.profile?.backgroundImage}`} profile={this.props.profile}>
                    <div
                style={{
                    backgroundColor: "#f0f0f0",
                    width: "100%",
                    position: "relative",
                    paddingTop: "20px",
                    paddingLeft: "3rem",
                    display: "flex",
                    WebkitBoxAlign: "center",
                    minHeight: "10rem",
                }}
            >
                    <Row
                        className="justify-content-center"
                        style={{ width: "100%", padding: "1.5rem" }}
                    >
                        <Col md={6}>
                            <div
                                style={{
                                    padding: "32px",
                                    background: "#fff",
                                    margin: "0px",
                                    borderRadius: "8px",
                                    animation: "transition-in .25s ease-out forwards",
                                }}
                            >
                                {this.state.complete ?
                                        <span>Your payment of ${ this.state.payment } has been received.</span>
                                    :
                                    <span></span>
                                }
                            </div>
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                    </div>
                </WizardPage>
            </>
        )
    }
}

const mapStateToProps = (state) => {
  return ({
    profile: state.licensee.profile
  });
}

export default connect(mapStateToProps, { confirmPurchase })(Step);