import api from "../utils/api";
import { send as sendAlert } from "./alert";
import { isGuid } from "../utils/string";

import {
  GET_CONTRACT,  SET_CONTRACT, LOGOUT
} from "./types";

export const get = (exclude) => async (dispatch) => {
    try {
        const res = await api.get(`/contracts/me/x/${exclude}`);

        dispatch({
            type: GET_CONTRACT,
            payload: res.data[0],
        });

        return res.data[0];
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/contracts/me`, data);

        if (res?.data?.success) {
            dispatch({
                type: SET_CONTRACT,
                payload: res.data
            });

            dispatch(sendAlert("Contract updated.", "success"));
        };
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const suggest = ( index, provisionUuid, data) => async (dispatch) => {
    try {
        const res = await api.post(`/contracts/me/suggest/x/${index}/p/${provisionUuid}`, data);

        if (res?.data?.success) {
            dispatch({
                type: SET_CONTRACT,
                payload: res.data
            });

            dispatch(sendAlert("Contract updated.", "success"));
        };
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const select = (index, provisionUuid, data) => async (dispatch) => {
    try {
        const res = await api.post(`/contracts/me/select/x/${index}/p/${provisionUuid}`, data);

        if (res?.data?.success) {
            dispatch({
                type: SET_CONTRACT,
                payload: res.data
            });

            dispatch(sendAlert("Contract updated.", "success"));
        };
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const escalate = (index) => async (dispatch) => {
    try {
        const res = await api.post(`/contracts/me/escalate/x/${index}`);

        if (res?.data?.success) {
            dispatch({
                type: SET_CONTRACT,
                payload: res.data
            });

            dispatch(sendAlert("Contract updated.", "success"));
        };
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const updates = (contractUuid, since) => async (dispatch) => {
    try {
        const res = await api.get(`/contracts/updates/c/${contractUuid}/s/${since}`);
        return res.data;
    } catch (err) {
        dispatch(sendAlert("Error retrieving updates.", "danger"));
        return false;        
    }
}