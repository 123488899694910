
export const icon = (item, data, propertyName, icon) => {
  let __array = propertyName ? Array.isArray(propertyName) ? propertyName : [propertyName] : [item.propertyName];
  for (let i = 0; i < __array.length; i++) {
    if (Boolean(data[__array[i]])) return item.icons[icon];
  }
  return item.icons.default;
}

const data = [
  {
    id: "home",
    icon: "home",
    caption: "Home",
    to: "/dashboard",
    title: "Client Dashboard",
    description: " Client facing dashboard and control center",
    display: [],
    permissions: ["CAN_LOGIN"],
    contains: {
      indent: 0,
      items: [
        {
          id: "my_profile",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["defendantLockDate", "plaintiffLockDate"]
          },
          caption: "My Profile",
          to: "/dashboard/profile/me",
          title: "My Profile",
          description: "Biographical information about you",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
        },
        {
          id: "spouse_profile",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["defendantLockDate", "plaintiffLockDate"]
          },
          caption: "My Spouse",
          to: "/dashboard/profile/spouse",
          title: "My Spouse",
          description: "Biographical information about your spouse",
          display: ["Sidebar"],
          permissions: ["CAN_INVITE"],
        },
        {
          id: "marriage_info",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["lockDate"]
          },
          caption: "Our Marriage",
          to: "/dashboard/marriage",
          title: "Our Marriage",
          description: "Biographical information about your marriage and separation",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
        },
        {
          id: "children_info",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["childrenLockDate"]
          },
          caption: "Our Children",
          to: "/dashboard/children",
          title: "Our Children",
          description: "Biographical information about your biological and adopted children",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
          module: 2,
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_children",
                  icon: "content",
                  caption: "This Child",
                  to: "/dashboard/children/add",
                  title: "New child",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                },
                {
                  id: "edit_children",
                  icon: "content",
                  caption: "This Child",
                  to: "/dashboard/children/:id",
                  title: "Edit child",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                }
              ]
          }
        },
        {
          id: "property_info",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["propertyLockDate"]
          },
          caption: "Our Property",
          to: "/dashboard/property",
          title: "Our Property",
          description: "Information about your property",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
          module: 1,
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_property",
                  icon: "content",
                  caption: "This Property",
                  to: "/dashboard/property/add",
                  title: "New property",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                },
                {
                  id: "edit_property",
                  icon: "content",
                  caption: "This Property",
                  to: "/dashboard/property/:id",
                  title: "Edit property",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                }
              ]
          }
        },
        {
          id: "debt_info",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          fields: {
            lock: ["debtLockDate"]
          },
          caption: "Our Debts",
          to: "/dashboard/debt",
          title: "Our Debts",
          description: "Information about your debt",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
          module: 1,
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_debt",
                  icon: "content",
                  caption: "This Debt",
                  to: "/dashboard/debt/add",
                  title: "New debt",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                },
                {
                  id: "edit_debt",
                  icon: "content",
                  caption: "This Debt",
                  to: "/dashboard/debt/:id",
                  title: "Edit debt",
                  display: [],
                  permissions: ["CAN_LOGIN"],
                }
              ]
          }
        },
        {
          id: "agreement_info",
          icons: {
            default: "iapp-Clipboard-list",
            locked: "iapp-Clipboard-check"
          },
          caption: "Our Agreement",
          to: "/dashboard/agreement",
          title: "Our Agreement",
          description: "Collaborate on your agreement",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN"],
          editor: 1
        },
      ]
    }
  },
  
];
export default data;

