import api from "../utils/api";
import {
  GET_LOGGED_IN, GET_SPOUSE, LOGOUT
} from "./types";
import { send as sendAlert } from "./alert";

export const Roles = {
    Self: 0,
    Spouse: 1
};

export const lock = (role) => async (dispatch) => {
    try {
        const res = await api.post(`/people/${role===Roles.Self ? "me" : "spouse"}/lock`, { lockDate: new Date()});
        dispatch(sendAlert("Locked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const unlock = (role) => async (dispatch) => {
    try {
        const res = await api.post(`/people/${role===Roles.Self ? "me" : "spouse"}/unlock`);
        dispatch(sendAlert("Unlocked succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const save = (role, value) => async (dispatch) => {
    try {
        const res = await api.post(`/people/${role===Roles.Self ? "me" : "spouse"}`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = () => async (dispatch) => {
    try {
        const res = await api.get(`/people/me`);
        dispatch({
            type: GET_LOGGED_IN,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const spouse = () => async (dispatch) => {
    try {
        const res = await api.get(`/people/spouse`);
        dispatch({
            type: GET_SPOUSE,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

