import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

import ConditionalButton from "../../layout/conditional-button";
import Modal, { initial } from "../../layout/modal";
import { list as getDebt, lock, unlock } from "../../actions/debt";
import { pack as setAlerts } from "../../actions/alert";
import { deepEqual } from "../../utils/object";
import { send as sendPageSetting } from "../../actions/page";

import List from "./list";



const Buttons = {
    Submit: 1,
    Add: 2,
    Unlock: 3
}

class Debt extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            debts: [],
            modal: { ...initial }
        }
    }

    postDebtUpdate = () => {
        this.setState({
            ...this.state,
        });
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.debts, this.props.debts) && !deepEqual(this.props.debts, this.state.debts)) {
            this.setState({
                debts: this.props.debts.slice()
            });
        } else if (!deepEqual(oldProps.marriage, this.props.marriage)) {
            this.componentDidMount();
        }
    }

    loadData = async () => {
        if (this.props.marriage?.uuid) {
            await this.props.getDebt(this.props.marriage.uuid);
        }
        this.props.sendPageSetting({ context: `form-debts` });
    }

    componentDidMount = async () => {
        setTimeout(async (e) => {
            this.loadData();
        }, 30);
    }

    onChange = (value, errors) => {
        this.setState({
            ...this.state,
            debts: value?.slice(),
            errors: errors.slice()
        });
    }

    onAddClick = async (e) => {
        this.props.history.push("/dashboard/debt/add");
    }

    onSubmitClick = async (e) => {
        await this.props.lock();
        this.props.history.push(`/dashboard/debt`);
    }

    onOkUnlockModal = async (e) => {
        await this.props.unlock();
        this.props.history.push(`/dashboard/debt`);
    }

    onUnlockClick = async (e) => {
        const __buttons = this.state.modal.buttons;
        __buttons[0].onClick = this.onHideModal;
        __buttons[1].onClick = this.onOkUnlockModal;
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: true,
                title: "Confirm Unlock",
                body: `Confirm unlock of the debts section? If the attorney has already reviewed your information, this action may incur a cost equivalent to a drafting credit. Click ok to continue or cancel to return to the previous screen.`
            }
        });
    }

    onHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                show: false
            }
        })
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    get locked() {
        return Boolean(this.props.marriage?.debtLockDate);
    }

    display = (button) => {
        switch (button) {
            case Buttons.Add:
            case Buttons.Submit:
                return (!Boolean(this.props.marriage?.debtLockDate));
            case Buttons.Unlock:
                return Boolean(this.props.marriage?.debtLockDate);
            default:
                return false;

        }
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="5" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Add another debt" display={this.display(Buttons.Add)} enabled={true} onEnabledClick={this.onAddClick} icon="plus">Add</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" disabledVariant="muted" tooltip="Have the lawyer review" display={this.display(Buttons.Submit)} enabled={true} onEnabledClick={this.onSubmitClick} onDisabledClick={this.onShowErrors} icon="lock-on">Submit</ConditionalButton>
                    <ConditionalButton enabledVariant="secondary" tooltip="Unlock to make additional changes" display={this.display(Buttons.Unlock)} enabled={true} icon="lock-off" onEnabledClick={this.onUnlockClick}>Unlock</ConditionalButton>
                </Col>
                <Col md="12">
                    <Form name="frm" action="post" onSubmit={this.onSubmit} >
                        <Row>
                            <Col>
                                <Card body className="mb-5">
                                    <List items={this.state.debts} marriage={this.props.marriage} onOpen={ (e) => { this.props.history.push(`/dashboard/debt/${e}`) } } />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Modal show={this.state.modal.show} title={this.state.modal.title} body={this.state.modal.body} buttons={this.state.modal.buttons} />
            </>
        )
    }
}

Debt.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    marriage: PropTypes.object,
    debts: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        marriage: state.matter.marriage,
        debts: state.matter.debts,
    });
};

export default connect(mapStateToProps, {setAlerts, getDebt, lock, unlock, sendPageSetting})(Debt);