import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import queryString from "query-string";

import { confirm as confirmPurchase } from "../../actions/purchase";

import './Style.css';

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            email: ""
        };
        this.pending = false;
    }

    async componentDidMount() {
        if (!this.props.display) return;
        if (this.pending) return;
        this.pending = true;
        const queryParams = queryString.parse(window.location.search)
        const intent = queryParams["payment_intent"];
        //const secret = queryParams["payment_intent_client_secret"];
        const data = await this.props.confirmPurchase(this.props.uuid, intent);
        this.setState({
            email: data.email,
            intent,
            complete: data.complete
        });
    }


    render() {
        if (!this.props?.display) return <></>;

        return (
            <div
                style={{
                    backgroundColor: "#f0f0f0",
                    width: "100%",
                    position: "relative",
                    paddingTop: "20px",
                    paddingLeft: "3rem",
                    display: "flex",
                    WebkitBoxAlign: "center",
                    minHeight: "10rem",
                }}
            >
                <Row
                    className="justify-content-center"
                    style={{ width: "100%", padding: "1.5rem" }}
                >
                    <Col md={6}>
                        <div
                            style={{
                                padding: "32px",
                                background: "#fff",
                                margin: "0px",
                                borderRadius: "8px",
                                animation: "transition-in .25s ease-out forwards",
                            }}
                        >
                            {this.state.complete ?
                                <span>An invitation to begin has been sent to <a href={`mailto:${this.state.email}`}>{this.state.email}</a>.</span>
                                :
                                <span></span>
                            }
                        </div>
                    </Col>
                    <Col md={6}>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default connect(null, { confirmPurchase })(Step);