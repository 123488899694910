import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Modal from "../modal";
import Pricing from "./pricing";

class AddOnModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onHide = (e) => {
        if (this.props.onHide) this.props.onHide(e);
    }

    render() {
        return (
            <Modal show={this.props.show} title={this.props.title} buttons={[
                {
                    default: true,
                    caption: "cancel",
                    onClick: this.onHide,
                    variant: "primary"
                },
            ]}
                size={this.props.size || "xl"}
            >
                <Pricing addonType={ this.props.addonType } items={this.props.items ? this.props.items : this.props.addons } onSelect={this.props.onSelect}></Pricing>
            </Modal>
        )
    }
}

AddOnModal.propTypes = {
    auth: PropTypes.object.isRequired,
    addons: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        addons: state.purchase.addons,
        purchase: state.purchase.purchase
    });
};

export default connect(mapStateToProps)(AddOnModal);