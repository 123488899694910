import React, { Fragment } from 'react';
import spinner from './spinner.gif';
import { deepEqual } from '../../utils/object';

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: this.props.messages || ["Loading ..."],
      message: "Loading ...",
      index: 0
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = null;
  }

  componentDidMount = () => {
    this.waitToUpdate();
  }

  componentDidUpdate = (oldProps) => {
    if (!deepEqual(oldProps, this.props)) {
      this.setState({
        ...this.state,
        messages: this.props.messages || ["Loading ..."],
        message: this.props.messages?.length ? this.props.messages[0] : "Loading ...",
        index: 0
      }, this.waitToUpdate);
    }
  }

  waitToUpdate = () => {
    this.timeout = setTimeout(() => {
        this.updateMessage();
    }, this.props.delay);
  }

  updateMessage = () => {
    const __old_index = this.state.index;
    if (__old_index === -1) return;
    let __index = __old_index;
    let __message = this.state.message;
    
    if (this.props.onMessage) {
      __message = this.props.onMessage();
    } else {
      if (this.props.repeat || ((this.state.messages?.length || 0) > __old_index + 1)) {
        __index = ((this.state.messages?.length || 0) > __old_index + 1) ? __old_index + 1 : 0;
        __message = this.state.messages[__index];
      } else {
        __message += ".";
      }
    }

    this.setState({
      ...this.state,
      index: __index,
      message: __message
    }, this.waitToUpdate)
  }

  render() {
    return (
      <Fragment>
        <div style={
          {
            zIndex: '10000',
            height: '100%',
            width: 'calc(100%)',
            backgroundColor: 'white',
            display: 'inline-block'
          }}><div style={
            {
              textAlign: 'center',
              width: '200px',
              margin: 'auto',
              display: 'block',
              position: 'sticky',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }} >
            <img
              src={spinner}
              alt="Loading..."
            />
            <p style={{ position: "relative", top: "-50px", width: "100%", textAlign: "center", color: "gray" }}>{ this.state.message }</p>
          </div>
        </div>
      </Fragment>
    )
  };
}

export default Spinner;