import api from "../utils/api";
import {
  GET_PROFILE_DATA, LOGOUT
} from "./types";

export const getLicenseeProfile = (url) => async (dispatch) => {
    try {
        url = "client.divorcex99.com";
        const res = await api.get(`/settings/licensee?u=${url}`);


        dispatch({
            type: GET_PROFILE_DATA,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};
