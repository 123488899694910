function valid(value) {
  if (typeof value === "undefined" || value === null || isNaN(value)) {
    return undefined;
  }
  return value;
}

function toDate(value) {
    return typeof value === 'string' ? new Date(value) : value;
}

function shortMonthYear(date) {
  let the_date = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let formatted_date =
    months[the_date.getMonth()] + " " + the_date.getFullYear();
  return date === null || isNaN(the_date.getMonth()) ? "" : formatted_date;
}

function format(date) {
  let the_date = new Date(date);
  let formatted_date =
    the_date.getMonth() +
    1 +
    "." +
    the_date.getDate() +
    "." +
    the_date.getFullYear();
  return date === null || isNaN(the_date.getMonth()) ? "" : formatted_date;
}

function minutesBetween(date1, date2) {
  var diff = (date2.getTime() - date1.getTime()) / 1000 / 60;
  return Math.abs(Math.round(diff));
}

function daysBetween(date1, date2) {
  return Math.floor(minutesBetween(date1, date2) / 60 / 24);
}

function compare(date1, date2, reverse = false) {
  if (date1 < date2) {
    return reverse ? 1 : -1;
  } else if (date1 > date2) {
    return reverse ? -1 : 1;
  }
  return 0;
}

module.exports = {
    valid,
    format,
    shortMonthYear,
    minutesBetween,
    daysBetween,
    compare,
    toDate,
};
